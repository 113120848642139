import React, {useEffect, useState} from 'react';
import Spinner from './helpers/Spinner';
import moment from 'moment';
import { getFixedAssetsRequestList} from '../actions/fixedassetrequestActions';
import {obtenerDepartamentos, obtenerTerritories ,obtenerEmpleadosManager} from '../actions/manageActions';
//Redux
import { useDispatch, useSelector, useStore } from 'react-redux';
import FixedAssetRequests from './FixedAssetRequests';


const FixedAssetRequestPanel = ({history}) => {

    const dispatch = useDispatch();

     //acceder al state
     //const userInfo = useSelector(state=>state.manager.employees);
     const departments = useSelector(state=>state.manager.departments);
     const territories = useSelector(state => state.manager.territories);
     const fixedAssetRequests = useSelector(state => state.fixedAsset.fixedAssetRequests);

     const [department, saveDepartment] = useState('');
     const [territory, saveTerritory] = useState('');
     const [requests, setRequests] = useState([]);

     const loadFixedAssetRequest = (department, territory) => dispatch(getFixedAssetsRequestList(department, territory));

    const filterElementsByName  = (array, query) => {
        return array.filter(function(el) {
            let name = `${el.employee_name} ${el.employee_lastname}`;
           // console.log(name)
            return name.toLowerCase().indexOf(query.toLowerCase()) > -1;
        })
      }

    const handleSearch = async (department, territory) => {


       // console.log(department, territory);
        await loadFixedAssetRequest(department, territory);
    }

    useEffect(() => {
        const loadDepartments = ()=> dispatch(obtenerDepartamentos());
        loadDepartments();

        // let data = {
        //     reportType:1,
        //     manager: true,
        //     companyManager: 'Mexilink'
        // }
        // const loadEmployeesManager = ()=>dispatch(obtenerEmpleadosManager(data));
        // loadEmployeesManager();

        const loadTerritories = ()=> dispatch(obtenerTerritories());
        loadTerritories();

        

    }, [])

    useEffect(() => {

        setRequests(fixedAssetRequests);
      
    }, [fixedAssetRequests])
    
    
    return (
        <>
        <div className='container mt-5'>
            <h1 style={{textAlign:'center'}}>Fixed Asset Request Control Panel</h1>
            <hr/>

            <div className='row'>
                <div className='col-md-4'>
                    <div className='card'>
                        <div className='card-header'>
                            <h5 className="text-center">Filter By Department</h5>
                        </div>
                        <div className='card-body'>
                            <div className="form-group text-center">
                                    <label>Department:</label>
                                    <select className="custom-select"
                                    onChange = {e => saveDepartment(e.target.value)}
                                    required
                                    >
                                        <option value="">Select Department...</option>
                                        {departments.map((department, index) =>(
                                            <option key={index} value={department.department}>{department.department}</option>
                                        ))}
                                    </select>
                                </div>
                        </div>
                        <div className='card-footer'></div>
                    </div>
                </div>

                <div className='col-md-4'>
                    <div className='card'>
                        <div className='card-header'>
                            <h5 className="text-center">Filter By Territory</h5>
                        </div>
                        <div className='card-body'>
                            <div className="form-group text-center">
                                    <label>Territory:</label>
                                    <select className="custom-select"
                                            onChange = {e => saveTerritory(e.target.value)}
                                            required
                                            >
                                                <option value="">Select Territory...</option>
                                                {territories.map((territory, index) =>(
                                                   <option key={index} value={territory.territory}>{territory.territory}</option>
                                                ))}
                                            </select>
                                </div>
                        </div>
                        <div className='card-footer'></div>
                    </div>
                </div>

                <div className='col-md-4'>
                    <div className='card'>
                        <div className='card-header'>
                            <h5 className="text-center">Reports</h5>
                        </div>
                        <div className='card-body p-4'>
                            <button className='btn btn-primary btn-large'>Download Report</button>
                        </div>
                        <div className='card-footer'></div>
                    </div>
                </div>
                
            </div>

            <hr/>
        </div>


         <div className='container'>
            <div className="form-group text-center">
                <button 
                type="button"
                className="btn btn-primary font-weight-bold d-block w-100"
                onClick={e => handleSearch(department, territory)}
                >
                    Search
                </button>        
            </div>
        </div> 

        <div className='container'>
          <div className='row mt-5'>
          <table className="table table-striped table-to-card-employees">
                     <thead className="bg-primary table-dark">
                         <tr>
                             <th scope="col">Employee</th>
                             <th scope="col">Department</th>
                             <th scope="col">Equipment Requested</th>
                             <th scope="col">Date</th>
                             <th scope="col">Status</th>
                            
                             <th scope="col" className="col-actions">Actions</th>
                         </tr>   
                     </thead>
                     <tbody>
                         {
                         requests.length > 0
                         ?
                            requests.map(request =>(
                                <FixedAssetRequests
                                key={request.id}
                                request = {request}
                                />
                                
                            ))
                         :
                                
                            <div style={{'position': 'absolute'}} className='mt-3 alert alert-warning'>Please choose the search criteria to obtain the list of orders.</div>
                                    
                         }
                     </tbody>
                 </table>    
            </div>    
        </div>                                      
        </>
    );
};

export default FixedAssetRequestPanel;