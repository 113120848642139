import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import Employees from './Employees';

//Redux
import { useDispatch, useSelector, useStore } from 'react-redux';

import {obtenerDepartamentos, obtenerCompanies, obtenerTerritories, obtenerEmpleadosManager, obtenerEstadosActivos} from '../actions/manageActions';
//import {obtenerUsuarioBalanceHoras} from '../actions/authenticateActions';
import Spinner from './helpers/Spinner';

import ReactExport from "react-export-excel";
import moment from 'moment';
import axiosHR from '../config/axiosHR';
// import {
//     BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
//   } from 'recharts';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const ManageDepartment = () => {
     // Mandar llamar la accion principal para retornar los PTO

     const dispatch = useDispatch();

     const [saveDepartment, selectSaveDepartment] = useState('');
     const [saveCompany, selectSaveCompany] = useState('');
     const [saveTerritory, selectSaveTerritory] = useState('');
     const [terminated, saveTerminated] = useState('');
     const [employeeSearch, setemployeeSearch] = useState('');
     const [employeefilters, setemployeeFilters] = useState([]);
     const [ptos, setPTOS] = useState([]);
    // const [sickDays, saveSickDays] = useState([]);

     //acceder al state
     const userInfo = useSelector(state=>state.manager.employees);
     const departments = useSelector(state=>state.manager.departments);
     const territories = useSelector(state => state.manager.territories);
     const companyManager = useSelector(state=>state.userInfo.userInfo.company);
     const managerVerify = useSelector(state=>state.userInfo.userInfo.manager);
     //const companies = useSelector(state=>state.manager.companies);
    let companies;
    let PtoEventsArray = [];

     if(companyManager === 'CSI' && managerVerify === true) {
        companies = [{company: "CSI"}];
     }else {
        companies =  [{company:'Mexilink'}, {company:'Hit Sales & Marketing'}];
     }
    
     //const employeeManager = useSelector(state => state.manager.employees)

     //const employees = userInfo.userHour;
     let employees = userInfo;

     const handleCheckTerminated = (value) => {
        saveTerminated(!terminated);  
    }

    const sortByName = (array) => {
        return array.sort(function(a,b) {
            let x = a.employee_name.toLowerCase();
            let y = b.employee_name.toLowerCase();

            if(x>y) {return 1;}
            if(x<y) {return -1;}

            return 0;
        });
    }

    const filterElementsByName  = (array, query) => {
        return array.filter(function(el) {
            let name = `${el.employee_name} ${el.employee_lastname}`;
           // console.log(name)
            return name.toLowerCase().indexOf(query.toLowerCase()) > -1;
        })
      }

    const filterElementsByCompany  = (array, query) => {
    return array.filter(function(el) {
        // console.log(name)
        return el.company.toLowerCase().indexOf(query.toLowerCase()) > -1;
    })
    }

    const filterElementsByTerritory  = (array, query) => {
        return array.filter(function(el) {
            return el.territory.toLowerCase().indexOf(query.toLowerCase()) > -1;
        })
        }
    const filterElementsByDepartment  = (array, query) => {
        return array.filter(function(el) {
            return el.department.toLowerCase().indexOf(query.toLowerCase()) > -1;
        })
        }


    const filterbyMexilink = filterElementsByCompany(userInfo, 'Mexilink');

    const filterbyHitSales = filterElementsByCompany(userInfo, 'Hit Sales & Marketing');
    


    const handleSearchEmployee =  async () => {
       //console.log(employeeSearch.length)
        if(employeeSearch.length === 0){
            await setemployeeFilters(sortByName(userInfo));
        }

        let filterbyName = filterElementsByName(userInfo, employeeSearch);

        let filterbyCompany = filterElementsByCompany(filterbyName, saveCompany);

        


        let filterbyTerritory = filterElementsByTerritory(filterbyCompany, saveTerritory);

        let filterbyDepartment = filterElementsByDepartment(filterbyTerritory, saveDepartment);

        await setemployeeFilters(filterbyDepartment);

         //console.log(employeefilters)
    }

    useEffect(()=> {
        if(terminated) {
            console.log(terminated)
            let data = {
                reportType:9,
                manager: true,
                companyManager: companyManager
            }
            const loadEmployeesManager = ()=>dispatch(obtenerEmpleadosManager(data));
            loadEmployeesManager();
          //  console.log(data);
        }else {
            if(saveDepartment === '' && saveCompany === '' && saveTerritory === '') {
                let data = {
                    reportType:1,
                    manager: true,
                    companyManager: companyManager
                }
                const loadEmployeesManager = ()=>dispatch(obtenerEmpleadosManager(data));
                loadEmployeesManager();
            }
        }

        axiosHR.get('/pto-list-all-employees/')
            .then(respuesta => {
             //   console.log(respuesta.data.data[0].day_beginning)
                respuesta.data.data.map((pto, index) => 
                pto.status !== 'Denied' && pto.company !== 'CSI'
                ?
                    PtoEventsArray.push(pto)
                :
                    null
                
                )
                setPTOS(PtoEventsArray);
                console.log(ptos, PtoEventsArray);
            })
            .catch(error => {
                console.log(error);
            })

       // handleSearchEmployee();
    },[terminated])

 
     useEffect(()=> {
 
        const loadDepartments = ()=> dispatch(obtenerDepartamentos());
        loadDepartments();

        const loadCompanies = ()=> dispatch(obtenerCompanies());
        loadCompanies();

        const loadTerritories = ()=> dispatch(obtenerTerritories());
        loadTerritories();

        const loadStates = ()=> dispatch(obtenerEstadosActivos(companyManager));
        loadStates();


        // if(saveDepartment !== '' && saveCompany !== '' && saveTerritory !== '') {
        // //   const loadEmployees = () => dispatch(obtenerUsuarioBalanceHoras(saveDepartment));
        // //   loadEmployees(); 
        //     let data = {
        //         companyManager: companyManager,
        //         company: saveCompany,
        //         territory: saveTerritory,
        //         department: saveDepartment,
        //         reportType: 2
        //     }
        //     const loadEmployeesManager = ()=>dispatch(obtenerEmpleadosManager(data));
        //     loadEmployeesManager();
        // }
        // if(saveDepartment === '' && saveCompany === '' && saveTerritory === '') {
        //     let data = {
        //         reportType:1
        //     }
        //     const loadEmployeesManager = ()=>dispatch(obtenerEmpleadosManager(data));
        //     loadEmployeesManager();
        // }

        // if(saveDepartment !== '' && saveCompany === '' && saveTerritory === '') {
        //     let data = {
        //         companyManager: companyManager,
        //         company: saveCompany,
        //         territory: saveTerritory,
        //         department: saveDepartment,
        //         reportType:3
        //     }
        //     const loadEmployeesManager = ()=>dispatch(obtenerEmpleadosManager(data));
        //     loadEmployeesManager();
        //     console.log(data);
        // }

        // if(saveDepartment === '' && saveCompany !== '' && saveTerritory === '') {
        //     let data = {
        //         companyManager: companyManager,
        //         company: saveCompany,
        //         territory: saveTerritory,
        //         department: saveDepartment,
        //         reportType:4
        //     }
        //     const loadEmployeesManager = ()=>dispatch(obtenerEmpleadosManager(data));
        //     loadEmployeesManager();
        //     console.log(data);
        // }

        // if(saveDepartment === '' && saveCompany === '' && saveTerritory !== '') {
        //     let data = {
        //         companyManager: companyManager,
        //         company: saveCompany,
        //         territory: saveTerritory,
        //         department: saveDepartment,
        //         reportType:5
        //     }
        //     const loadEmployeesManager = ()=>dispatch(obtenerEmpleadosManager(data));
        //     loadEmployeesManager();
        //     console.log(data);
        // }

        // if(saveDepartment !== '' && saveCompany !== '' && saveTerritory === '') {
        //     let data = {
        //         companyManager: companyManager,
        //         company: saveCompany,
        //         territory: saveTerritory,
        //         department: saveDepartment,
        //         reportType:6
        //     }
        //     const loadEmployeesManager = ()=>dispatch(obtenerEmpleadosManager(data));
        //     loadEmployeesManager();
        //     console.log(data);
        // }

        // if(saveDepartment === '' && saveCompany !== '' && saveTerritory !== '') {
        //     let data = {
        //         companyManager: companyManager,
        //         company: saveCompany,
        //         territory: saveTerritory,
        //         department: saveDepartment,
        //         reportType:7
        //     }
        //     const loadEmployeesManager = ()=>dispatch(obtenerEmpleadosManager(data));
        //     loadEmployeesManager();
        //     console.log(data);
        // }

        // if(saveDepartment !== '' && saveCompany === '' && saveTerritory !== '') {
        //     let data = {
        //         companyManager: companyManager,
        //         company: saveCompany,
        //         territory: saveTerritory,
        //         department: saveDepartment,
        //         reportType:8
        //     }
        //     const loadEmployeesManager = ()=>dispatch(obtenerEmpleadosManager(data));
        //     loadEmployeesManager();
        //     console.log(data);
        // }

        
        handleSearchEmployee();
        
         
     },[dispatch]);
 
     return (
         
         employees !== undefined && employees !== ''
         ? 
             <React.Fragment>
                 
                 <div className="d-flex justify-content-center align-items-center manager-title">
                    <h2 className="text-center my-5 title-custom">H.R. MANAGER</h2>
                    <div className='card-deck w-100'>
                        <div className="card ">
                            <div className="card-body ">
                                <h5 className="card-title mt-3 text-center">Users</h5>
                                <hr className=''/>
                                <div className="card-text">
                                    <ul className='list-group mb-3'>
                                            <Link className="list-group-item list-group-item-action"
                                            to = {'/users/new'}
                                             >
                                                New User
                                            </Link>
                                            <Link
                                                to={'/pto/year-calendar'}
                                                className="list-group-item list-group-item-action calendar-view"
                                            >
                                                Calendar View
                                            </Link>
                                        
                                    </ul>
                                    

                                    
                                </div>
                            </div>
                            
                        </div>

                        <div className="card ">
                            <div className="card-body ">
                            <h5 className="card-title mt-3 text-center">Departments</h5>
                            <hr className=''/>
                            <div className="card-text">
                                <ul className='list-group mb-3'>
                                    <Link className="list-group-item list-group-item-action"
                                            to = {'/departments/new'}
                                        >
                                            New Department
                                    </Link>

                                    <Link className="list-group-item list-group-item-action"
                                            to = {'/departments'}
                                        >
                                            Departments Panel
                                    </Link>
                                </ul>
                                
                            </div>
                            </div>
                            
                        </div>

                        <div className="card ">
                            <div className="card-body ">
                                <h5 className="card-title mt-3 text-center">Reports</h5>
                                <hr className='' />
                                <div className="card-text">
                                    <ul className='list-group mb-3'>
                                        

                                    <ExcelFile element={<button className="list-group-item list-group-item-action">General Info</button>}>
                                                <ExcelSheet  data={employeefilters} name="Employees">
                                                    <ExcelColumn label="Lastname" value="employee_lastname"/>
                                                    <ExcelColumn label="Firstname" value="employee_name"/>
                                                    <ExcelColumn label="Department" value="department"/>
                                                    <ExcelColumn label="Company" value="company"/>
                                                    <ExcelColumn label="Email" value="email"/>
                                                    <ExcelColumn label="Territory" value="territory"/>
                                                    <ExcelColumn label="Supervisor Email" value="supervisor_mail"/>
                                                    <ExcelColumn label="Hire Date" value={(col) => moment(col.hire_date).format('YYYY-MM-DD')}/>
                                                    <ExcelColumn label="Available Hours" value="available_hours"/>
                                                    <ExcelColumn label="Carry Over" value="carry_over"/>
                                                    <ExcelColumn label="Sick Days" value="sick_days_limit"/>
                                                    <ExcelColumn label="Carry Over Sick Days" value="carry_over_sick_time"/>
                                                </ExcelSheet>
                                    </ExcelFile>

                                    <ExcelFile element={<button className="list-group-item list-group-item-action">Sick Days</button>}>
                                                <ExcelSheet  data={employeefilters} name="Employees">
                                                    <ExcelColumn label="LastName" value="employee_lastname"/>
                                                    <ExcelColumn label="FirstName" value="employee_name"/>
                                                    <ExcelColumn label="SocialSecurityNumber" value="0"/>
                                                    <ExcelColumn label="InsperityEmployeeId" value="0"/>
                                                    <ExcelColumn label="AccruedTimeOff" value="accrued_sick_days"/>
                                                    <ExcelColumn label="Taken" value={(col) => parseInt(col.accrued_sick_days) - parseInt(col.sick_days_limit)}/>
                                                    {/* <ExcelColumn label="Time Off Plan Name" value={(col) => col.travel_benefits === 0 ? 48 : 24}/> */}
                                                    {/* <ExcelColumn label="Accrued Time Off" value={(col) => col.travel_benefits === 0 ? 48 : 24}/>
                                                    <ExcelColumn label="Taken" value="sick_days_limit"/> */}
                                                    <ExcelColumn label="Balance" value="sick_days_limit"/>
                                                    <ExcelColumn label="Carry Over Sick Days" value="carry_over_sick_time"/>
                                                    <ExcelColumn label="InsperityClientId" value="0"/>
                                                    <ExcelColumn label="PayPeriodEndDate" value={(col) => moment().format('DD/MM/YYYY')}/>
                                                    
                                                </ExcelSheet>
                                    </ExcelFile>

                                

                                    <ExcelFile element={<button className="list-group-item list-group-item-action">Termination</button>}>
                                                <ExcelSheet  data={employeefilters} name="Employees">
                                                    <ExcelColumn label="Lastname" value="employee_lastname"/>
                                                    <ExcelColumn label="Firstname" value="employee_name"/>
                                                    <ExcelColumn label="Department" value="department"/>
                                                    <ExcelColumn label="Company" value="company"/>
                                                    <ExcelColumn label="Territory" value="territory"/>
                                                    <ExcelColumn label="Supervisor Email" value="supervisor_mail"/>
                                                    <ExcelColumn label="Hire Date" value={(col) => moment(col.hire_date).format('YYYY-MM-DD')}/>
                                                    <ExcelColumn label="Available Hours" value="available_hours"/>
                                                    <ExcelColumn label="Carry Over" value="carry_over"/>
                                                    <ExcelColumn label="Sick Days" value="sick_days_limit"/>
                                                    <ExcelColumn label="Carry Over Sick Days" value="carry_over_sick_time"/>
                                                    <ExcelColumn label="Termination Date" value="date_terminated"/>
                                                    <ExcelColumn label="Total PTO" value={(col) => parseInt(col.available_hours) + parseInt(col.carry_over) + parseInt(col.sick_days_limit)}/>
                                                </ExcelSheet>
                                    </ExcelFile>

                                    
                                    </ul>
                                
                                </div>
                            </div>
                            
                        </div>

                        { companyManager !== 'CSI'
                        ?
                        <div className="card ">
                            <div className="card-body ">
                                <h5 className="card-title mt-3 text-center">More</h5>
                                <hr className='' />
                                <div className="card-text">
                                    <ul className='list-group mb-3'>

                                    <ExcelFile filename='Sick-Time-Report-Mexilink' element={<button className="list-group-item list-group-item-action">Mexilink Sick Days</button>}>
                                                <ExcelSheet  data={filterbyMexilink} name="Employees">
                                                    <ExcelColumn label="LastName" value="employee_lastname"/>
                                                    <ExcelColumn label="FirstName" value="employee_name"/>
                                                    <ExcelColumn label="SocialSecurityNumber" value="0"/>
                                                    <ExcelColumn label="InsperityEmployeeId" value="0"/>
                                                    <ExcelColumn label="AccruedTimeOff" value="accrued_sick_days"/>
                                                    <ExcelColumn label="TimeOffPlanName" value={(col) => 'Sick'}/>
                                                    <ExcelColumn label="Taken" value={(col) => parseInt(col.accrued_sick_days) - parseInt(col.sick_days_limit)}/>
                                                    {/* <ExcelColumn label="Time Off Plan Name" value={(col) => col.travel_benefits === 0 ? 48 : 24}/> */}
                                                    {/* <ExcelColumn label="Accrued Time Off" value={(col) => col.travel_benefits === 0 ? 48 : 24}/>
                                                    <ExcelColumn label="Taken" value="sick_days_limit"/> */}
                                                    <ExcelColumn label="Balance" value="sick_days_limit"/>
                                                    <ExcelColumn label="Carry Over Sick Days" value="carry_over_sick_time"/>
                                                    <ExcelColumn label="InsperityClientId"  value={(col) => '2858100'}/>
                                                    <ExcelColumn label="PayPeriodEndDate" value={(col) => moment().format('DD/MM/YYYY')}/>
                                                    
                                                </ExcelSheet>
                                    </ExcelFile>

                                    <ExcelFile filename='Sick-Time-Report-Hit-Sales' element={<button className="list-group-item list-group-item-action">Hit Sales & Mkt Sick Days</button>}>
                                                <ExcelSheet  data={filterbyHitSales} name="Employees">
                                                    <ExcelColumn label="LastName" value="employee_lastname"/>
                                                    <ExcelColumn label="FirstName" value="employee_name"/>
                                                    <ExcelColumn label="SocialSecurityNumber" value="0"/>
                                                    <ExcelColumn label="InsperityEmployeeId" value="0"/>
                                                    <ExcelColumn label="AccruedTimeOff" value="accrued_sick_days"/>
                                                    <ExcelColumn label="TimeOffPlanName"  value={(col) => 'Sick'}/>
                                                    <ExcelColumn label="Taken" value={(col) => parseInt(col.accrued_sick_days) - parseInt(col.sick_days_limit)}/>
                                                    {/* <ExcelColumn label="Time Off Plan Name" value={(col) => col.travel_benefits === 0 ? 48 : 24}/> */}
                                                    {/* <ExcelColumn label="Accrued Time Off" value={(col) => col.travel_benefits === 0 ? 48 : 24}/>
                                                    <ExcelColumn label="Taken" value="sick_days_limit"/> */}
                                                    <ExcelColumn label="Balance" value="sick_days_limit"/>
                                                    <ExcelColumn label="Carry Over Sick Days" value="carry_over_sick_time"/>
                                                    <ExcelColumn label="InsperityClientId" value={(col) => '2858000'}/>
                                                    <ExcelColumn label="PayPeriodEndDate" value={(col) => moment().format('DD/MM/YYYY')}/>
                                                    
                                                </ExcelSheet>
                                    </ExcelFile>


                                    <ExcelFile element={<button className="list-group-item list-group-item-action">All PTO</button>}>
                                                <ExcelSheet  data={ptos} name="PTOs">
                                                    <ExcelColumn label="Employee Name" value="employee_name"/>
                                                    <ExcelColumn label="Department" value="department"/>
                                                    <ExcelColumn label="Job Title" value="job_title"/>
                                                    <ExcelColumn label="Company" value="company"/>
                                                    <ExcelColumn label="Created At" value={(col) => moment(col.date).format('YYYY-MM-DD')}/>
                                                    <ExcelColumn label="Day Beginning" value={(col) => moment(col.day_beginning).format('YYYY-MM-DD')}/>
                                                    <ExcelColumn label="Day End" value={(col) => moment(col.day_end).format('YYYY-MM-DD')}/>
                                                    <ExcelColumn label="Time Beginning" value="time_beginning"/>
                                                    <ExcelColumn label="Time End" value="time_end"/>
                                                    <ExcelColumn label="Total Hours" value="total_hours"/>
                                                    <ExcelColumn label="Status" value="status"/>    
                                                </ExcelSheet>
                                    </ExcelFile>
                                    </ul>
                                
                                </div>
                            </div>
                            
                        </div>
                        :
                        null
                        }
                    </div>
                    
                    

                    
                
                 </div>
                
 
                 {/* <div className="card mb-3 avatar" > */}
                    <div className="row no-gutters m-3">
                        {/* <div className="card-body"> */}
                            
                                {/* <div className="col-sm-12">
                                    <div className="form-group">
                                            <label>Employee:</label>
                                            <select className="custom-select"
                                            onChange = {e => selectSaveDepartment(e.target.value)} 
                                            required
                                            >
                                                <option value="All">Select All...</option>
                                                {employees.map((employee, index) => (
                                                    <option key = {index} value = {employee.uid}>{employee.employee_name}</option>
                                                ))}
                                                
                                            </select>
                                    </div>
                                </div> */}
                        {/* </div> */}
                        <div className="col-md-3">
                                    <div className="form-group text-center">
                                        <label>Company:</label>      
                                            <select className="custom-select"
                                            onChange = {e => selectSaveCompany(e.target.value)}
                                            required
                                            >
                                                <option value="">Select Company...</option>
                                                {companies.map((company, index) =>(
                                                    <option key={index} value={company.company}>{company.company}</option>
                                                ))}
                                            </select>       
                                    </div>
                            </div>
                                <div className="col-md-3">
                                    <div className="form-group text-center">
                                            <label>Department:</label>
                                            <select className="custom-select"
                                            onChange = {e => selectSaveDepartment(e.target.value)}
                                            required
                                            >
                                                <option value="">Select Department...</option>
                                                {departments.map((department, index) =>(
                                                   <option key={index} value={department.department}>{department.department}</option>
                                                ))}
                                            </select>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group text-center">
                                            <label>Territory:</label>
                                            <select className="custom-select"
                                            onChange = {e => selectSaveTerritory(e.target.value)}
                                            required
                                            >
                                                <option value="">Select Territory...</option>
                                                {territories.map((territory, index) =>(
                                                   <option key={index} value={territory.territory}>{territory.territory}</option>
                                                ))}
                                            </select>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group text-center">
                                            <label style={{display:'block'}}>Terminated:</label>
                                            {/* <select className="custom-select"
                                            onChange = {e => selectSaveTerritory(e.target.value)}
                                            required
                                            >
                                                <option value="">Select Territory...</option>
                                                {territories.map((territory, index) =>(
                                                   <option key={index} value={territory.territory}>{territory.territory}</option>
                                                ))}
                                            </select> */}
                                             <div className="custom-control custom-switch custom-control-inline">
                                                <input type="checkbox" className="custom-control-input" id="customSwitch1" onChange={e => handleCheckTerminated(e.target.value) } 
                                                checked= {terminated}
                                                />
                                                
                                                    <label className="custom-control-label" htmlFor="customSwitch1">No / Yes</label>
                                                
                                            </div>
                                    </div>
                                </div>
                    </div>
                    <div className='row no-gutters m-2'>
                        <div className="col-md-8 p-3">
                                    <div className="form-group text-center">
                                        {/* <label>Search Employee:</label>       */}
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            placeholder="Search Employee..."
                                            value={employeeSearch}
                                            onChange={e => setemployeeSearch(e.target.value)}
                                            required
                                        />   
                                         
                                    </div>
                        </div>

                        <div className="col-md-4 p-3">
                                    <div className="form-group text-center">
                                        <button 
                                        type="button"
                                        className="btn btn-primary font-weight-bold d-block w-100"
                                        onClick={e => handleSearchEmployee()}
                                        >
                                            Search
                                        </button>        
                                    </div>
                        </div>
                </div>
                 {/* </div> */}
                 <div className="charts m-5 justify-content-center align-items-center">
                    {/* <BarChart
                        width={800}
                        height={300}
                        data={employees}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="employee_name" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="available_hours" fill="#f0ad4e" />
                        <Bar dataKey="requested_hours" fill="#004185" />
                    </BarChart> */}
                 </div>
                 <table className="table table-striped table-to-card-employees">
                     <thead className="bg-primary table-dark">
                         <tr>
                             <th scope="col">Employee</th>
                             <th scope="col">Department</th>
                             <th scope="col">Hire Date</th>
                             <th scope="col">Last Hour Increment</th>
                             <th scope="col">Available Hours</th>
                             <th scope="col">Carry Over</th>
                             <th scope="col">Sick Days</th>
                             <th scope="col">Carry Over Sick Days</th>
                            
                             <th scope="col" className="col-actions">Actions</th>
                         </tr>   
                     </thead>
                     <tbody>
                         {
                         employeefilters.length > 0
                         ?
                            employeefilters.map(employee =>(
                                <Employees
                                key={employee.id}
                                employee = {employee}
                                />
                                
                            ))
                         :
                                
                            <div style={{'position': 'absolute'}} className='mt-3 alert alert-danger'>The employee you are looking for is not in the selected department, please change the territory, company or department.</div>
                                    
                         }
                     </tbody>
                 </table>
                 
                 
     
             </React.Fragment>
             :
             <Spinner />
     ); 
};

export default ManageDepartment;