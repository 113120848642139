import React, {useState, useEffect} from 'react';
// Redux
import { useDispatch } from 'react-redux';
// libraries
import { useSelector } from 'react-redux';
import moment from 'moment';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Swal from 'sweetalert2';
import {editarProfile} from '../actions/authenticateActions';


const ViewProfile = ({match, history}) => {

    // obtener el ID a editar
    const { uid } = match.params;

    const usersHour = useSelector(state=>state.manager.employees);

    // get user general data
    const employeeData = usersHour.filter(user => user.uid == uid);

    if(employeeData === undefined) {
        history.push('/home');
    }

    const [employeeName, saveEmployeeName] = useState('');
    const [phone, savePhone] = useState('');
    const [birthplace, saveBirthPlace] = useState('');
    const [birthdate, saveBirthDate] = useState('');

    useEffect(() => {
        saveEmployeeName(employeeData[0].employee_name);
        savePhone(employeeData[0].phone);
        saveBirthPlace(employeeData[0].birth_place);
        saveBirthDate(new Date((moment(employeeData[0].birth_date).utc().format('MM/DD/YYYY'))))
    }, [])

     // dispatch actions
     const dispatch = useDispatch();
     const editProfile = (user) => dispatch (editarProfile(user));
    
      // Add new PTO
    const submitEditUser = e => {
        e.preventDefault();
      //  validarFormulario();
        // Preguntar al usuario
        Swal.fire({
          title: 'Request Summary',
          text: "This is your summary request, are you sure to submit ?",
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Confirm',
          cancelButtonText: 'Cancel',
          html: '<div>'+
                      '<p> Name : ' + employeeName + '</p>' +
                   '</div>'
        }).then((result) => {
              if (result.value) {
              Swal.fire(
                  'Saved',
                  'Your request has been sent successfully',
                  'success'
              )
          
              const formatBirthDate = moment(birthdate).format('YYYY-MM-DD');
              const uidEmployee = uid;   
              editProfile({
                      employeeName,
                      formatBirthDate,
                      birthplace,
                      phone,
                      uidEmployee
                  });
  
                  
  
                  //redirect
                  history.push('/home');
              }
          })
        
        
      }

    return (
        <div className="row justify-content-center mt-5">
            <div className="col-md-8">
                <div className="card">
                    <div className="card-body">
                        <h2 className="text-center mb-4 font-weight-bold ">Your Profile</h2>
                        <form onSubmit={submitEditUser}>
                            <div className="form-group">
                                <div className="col-sm-12 col-md-4 card-avatar-container mb-3">
                                    {/* Change avatar dinamically */}
                                    <img src={employeeData[0].avatar} className="card-img" alt="avatar" />
                                </div>
                            </div>
                            <div className="form-group">
                                <label>Employee Name</label>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    placeholder="Employee Name"
                                    value={employeeData[0].employee_name}
                                   // onChange={handleChangeInput}
                                    required
                                    readOnly
                                />
                            </div>

                            <div className="form-group">
                                <label>User Name</label>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    placeholder="Employee Name"
                                    value={employeeData[0].username}
                                   // onChange={e=>saveUserName(e.target.value)}
                                    required
                                    readOnly
                                />
                            </div>

                            <div className="form-group">
                                <label>Company</label>
                                <select className="custom-select"
                                // onChange={e=>saveCompany(e.target.value)}
                                 required
                                 readOnly
                                >
                                <option value={employeeData[0].company}>{employeeData[0].company}</option>
                                    
                                    
                                </select>
                            </div>

                            <div className="form-group">
                                <label>Department</label>
                                <select className="custom-select"
                                // onChange={e=>handleDepartmentSelect(e.target.value)}
                                 required
                                 readOnly
                                >
                                <option value={employeeData[0].department}>{employeeData[0].department}</option>
                                </select>
                            </div>

                            <div className="form-group">
                                <label>Email</label>
                                <input 
                                    type="email" 
                                    className="form-control" 
                                    placeholder="Email"
                                   // onChange={ e => saveEmail(e.target.value) }
                                    required
                                    value={employeeData[0].email}
                                    readOnly
                                />
                            </div>

                            <div className="form-group ">
                                <label>Email Supervisor</label>
                                <input 
                                    type="email" 
                                    className="form-control" 
                                    placeholder="Email Superviosr"
                                  //  onChange={ e => saveEmailSupervisor(e.target.value) }
                                    value= {employeeData[0].supervisor_mail}
                                    required
                                    readOnly
                                />
                            </div>


                            <div className="row container justify-content-between">
                                
                                
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>Hire Date:</label>
                                        <input 
                                        type="text" 
                                        className="form-control"
                                       // onChange={e=>saveDayBeginning(e.target.value)}
                                        required
                                      //  ref = {dayBeginingRef}
                                        value = {moment(employeeData[0].hire_date).format('YYYY-MM-DD')}
                                        readOnly
                                        />
                                        {/* <DatePicker
                                        className="datePicker userdatepicker"
                                        todayButton="Today"
                                        selected={hireDate}
                                        onChange={date => saveHireDate(date)}
                                        filterDate={isWeekday}
                                        placeholderText="Select a weekday"
                                       // minDate={new Date()}
                                        excludeDates={excludedDatesArray} 
                                        /> */}

                                    </div>
                                </div>
                                
                            </div>

                            <div className="form-group">
                                <label>Territory</label>
                                <select className="custom-select"
                                // onChange={e=>saveTerritory(e.target.value)}
                                 required
                                 readOnly
                                >
                                <option value={employeeData[0].territory}>{employeeData[0].territory}</option>
                                </select>
                            </div>

                            <div className='form-group'>
                                <label>Phone Number:</label>
                                    <PhoneInput
                                    country="US"
                                    defaultCountry='US'
                                    value={phone}
                                    onChange={savePhone} />
                            </div>

                            <div className="form-group">
                                        <label>Birth Date:</label>
                                        {/* <input 
                                        type="date" 
                                        className="form-control"
                                        onChange={e=>saveDayBeginning(e.target.value)}
                                        required
                                        ref = {dayBeginingRef}
                                        /> */}
                                        <DatePicker
                                        className="datePicker userdatepicker"
                                        todayButton="Today"
                                        selected={birthdate}
                                        onChange={date => saveBirthDate(date)}
                                        placeholderText="Select birth date"
                                       // minDate={new Date()}
                                        />

                                    </div>

                            <div className="form-group">
                                <label>Birth Place</label>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    placeholder="Birth Place"
                                    value={birthplace}
                                    onChange={e=>saveBirthPlace(e.target.value)}
                                    required
                                />
                            </div>


                            <button type="submit" className="btn btn-primary font-weight-bold text-uppercase d-block mb-4">Save</button>
                        </form>
        
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ViewProfile;