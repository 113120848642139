import React, {useEffect} from 'react';
import PtoSupervisor from './PtoSupervisor';

//Redux
import { useDispatch, useSelector } from 'react-redux';

import {getPtoListDepartmentSupervisor} from '../actions/timeoffrequestActions';
import {obtenerUsuarioBalanceHoras} from '../actions/authenticateActions';
const Supervisor = ({history}) => {

        //acceder al state
        const userInfo = useSelector(state=>state.userInfo);

        if(userInfo.userInfo.name === undefined){
            history.push('/home');
        }
        
        // Mandar llamar la accion principal para retornar los PTO

        const dispatch = useDispatch();
        const ptoList = useSelector(state => state.ptoList.ptoSupervisor);

        useEffect(()=> {
            
            const loadPtoList = ()=> dispatch(getPtoListDepartmentSupervisor(userInfo.userInfo.department, userInfo.userInfo.uid, userInfo.userInfo.email));
            loadPtoList();
            
            const loadUsers = ()=>dispatch(obtenerUsuarioBalanceHoras(userInfo.userInfo.department));
            loadUsers();

        },[dispatch, userInfo.userInfo.department, ptoList.length, userInfo.userInfo.uid]);
        
        
        
    
        return (
            <React.Fragment>
                
                <h2 className="text-center my-5">TIME OFF REQUEST</h2>
    
                <div className="card mb-3 avatar" >
                    <div className="row no-gutters">
                        <div className="col-sm-12 col-md-12">
                            <div className="card-body">
                                <h4 className="card-title" style={{textAlign:"center"}}>Department: {userInfo.userInfo.department}</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <table className="table table-striped table-to-card">
                    <thead className="bg-primary table-dark">
                        <tr>
                            <th scope="col">Employee</th>
                            <th scope="col">Department</th>
                            <th scope="col">Status</th>
                            <th scope="col">Created At</th>
                            <th scope="col">Beginning</th>
                            <th scope="col">End</th>
                            <th scope="col">Reason</th>
                            <th scope="col">Hours</th>
                            <th scope="col">Actions</th>
                        </tr>   
                    </thead>
                    <tbody>
                        {
                            (ptoList !== undefined)
                            ?
                                ptoList.map(pto =>(
                                    <PtoSupervisor 
                                      key={pto.id}
                                      pto = {pto}
                                    />
                                ))
                            : null
                            
                            }
                        
                    </tbody>
                </table>
                
                
     
            </React.Fragment>
        );
    };

export default Supervisor;