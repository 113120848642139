import React, {useState} from 'react';
import Modal from 'react-modal';

import moment from 'moment';

const HistoryEmployee = ({historyEmployee}) => {
    console.log(historyEmployee)

    let observations = historyEmployee.observations.split(",");

    Modal.setAppElement('#root')

    const [modalIsOpen, setIsOpen]  = useState(false);

    var subtitle;

    const customStyles = {
        content : {
          top                   : '50%',
          left                  : '50%',
          right                 : 'auto',
          bottom                : 'auto',
          marginRight           : '-50%',
          transform             : 'translate(-50%, -50%)'
        }
      };

      function openModal() {
        setIsOpen(true);
      }
     
      function afterOpenModal() {
        // references are now sync'd and can be accessed.
       // subtitle.style.color = '#f00';
      }
     
      function closeModal(){
        setIsOpen(false);
      }


    return (
        <tr>
            <td>{historyEmployee.id}</td>
            <td>{moment(historyEmployee.date_mov).format('YYYY-MM-DD')}</td>
            <td>{historyEmployee.hours_before_mov}</td>
            <td>{historyEmployee.hours_after_mov}</td>
            <td>{historyEmployee.carry_over_before_mov}</td>
            <td>{historyEmployee.carry_over_after_mov}</td>
            <td>{historyEmployee.carry_over_sick_time_before}</td>
            <td>{historyEmployee.carry_over_sick_time_after}</td>
            {
              historyEmployee.hours_mov < 0
              ?
              <td>{parseInt(historyEmployee.hours_mov) *-1}</td>
              :
              <td>{historyEmployee.hours_mov}</td>
            }
            
            <td>{historyEmployee.type_mov}</td>
            {/* <td>{moment(historyEmployee.mov_increase_date).format('YYYY-MM-DD')}</td>
            <td>{moment(historyEmployee.increase_date_before_mov).format('YYYY-MM-DD')}</td>
            <td>{moment(historyEmployee.mov_yearly_increase_date).format('YYYY-MM-DD')}</td>
            <td>{moment(historyEmployee.yearly_increase_date_before_mov).format('YYYY-MM-DD')}</td> */}
            {
                historyEmployee.type_mov === 'Edit User'
                ?
                    <td>
                        <button onClick={ () => setIsOpen(true)} className="btn btn-primary"> Open Summary</button>

                        <Modal
                        isOpen={modalIsOpen}
                        onAfterOpen={afterOpenModal}
                        onRequestClose={closeModal}
                        style={customStyles}
                        contentLabel="Example Modal">
                          <h3># {historyEmployee.id} - {historyEmployee.type_mov}</h3>
                            <ul>
                                <li>
                                Available Hours: from {observations[0]} to {observations[1]}
                                </li>
                                <li>
                                Carry Over Hours: from {observations[2]} to {observations[3]}
                                </li>
                                <li>
                                Sick Hours: from {observations[4]} to {observations[5]}
                                </li>
                                <li>
                                Carry Over Sick Hours: from {observations[6]} to {observations[7]}
                                </li>
                            </ul>
                        </Modal>
                    </td>
                    
                :
                 <td>{historyEmployee.observations}</td>
            }
            
        </tr>
    );
};

export default HistoryEmployee;