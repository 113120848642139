import { combineReducers } from 'redux';
import validacionReducer from './validacionReducer';
import authenticateReducer from './authenticateReducer';
import timeoffrequestReducer from './timeoffrequestReducer';
import managerReducer from './managerReducer';
import fixedassetReducer from './fixedassetsrequestReducers';

export default combineReducers({
    userInfo: authenticateReducer,
    error: validacionReducer,
    ptoList: timeoffrequestReducer,
    manager: managerReducer,
    fixedAsset: fixedassetReducer,
});