import React, {useEffect} from 'react';

// Redux
import {useDispatch, useSelector} from 'react-redux';
import {getPtoDetail} from '../actions/timeoffrequestActions';

// library
import moment from 'moment';


const ViewPto = ({match}) => {

    // Dispatch para ejecutar la acción principal
    const dispatch = useDispatch();

    // obtener el ID a editar
    const { id } = match.params;

    const pto = useSelector(state => state.ptoList.pto);


    useEffect(()=> {

        const ptoDetail =  (pto) => dispatch(getPtoDetail(id));
        ptoDetail();

    }, [dispatch, id])

       

 
        let absenceText;
      if(pto.absence_code ==='V') {
        absenceText = 'Vacation'
      }
      if(pto.absence_code ==='PT') {
        absenceText = 'Personal Time'
      }
      if(pto.absence_code ==='LA') {
        absenceText = 'Leave of Abscence'
      }
      if(pto.absence_code ==='LTD') {
        absenceText = 'Long Term Disability'
      }
      if(pto.absence_code ==='STD') {
        absenceText = 'Short Term Disability'
      }
      if(pto.absence_code ==='S') {
        absenceText = 'Sick'
      }
      if(pto.absence_code ==='J') {
        absenceText = 'Jury Duty'
      }
      if(pto.absence_code ==='O') {
        absenceText = 'Other'
      }
      if(pto.absence_code ==='J') {
        absenceText = 'Jury Duty'
      }
      if(pto.absence_code ==='B1') {
        absenceText = 'Bereavement 1'
      }
      if(pto.absence_code ==='B2') {
        absenceText = 'Bereavement 2'
      }
      if(pto.absence_code ==='B3') {
        absenceText = 'Bereavement 3'
      }
      if(pto.absence_code ==='NB') {
        absenceText = 'New Baby Born'
      }
      if(pto.absence_code ==='NBCSI') {
        absenceText = 'New Baby Born'
      }
      if(pto.absence_code ==='M') {
        absenceText = 'Marriage'
      }
      if(pto.absence_code ==='MA') {
        absenceText = 'Maternity'
      }
      if(pto.absence_code ==='FFCRA') {
        absenceText = 'FFCRA (Emergency Sick Time)'
      }
      if(pto.absence_code ==='VT') {
        absenceText = 'Vote'
      }
      if(pto.absence_code ==='MD') {
        absenceText = 'Medical Disability'
      }

        let dayBeginnig = moment.utc(pto.day_beginning).format('MM-DD-YYYY');
        let dayEnd = moment.utc(pto.day_end).format('MM-DD-YYYY');
        let dateSignature = moment(pto.date_employee_signature).format('MM-DD-YYYY');

        return (
            <div className="row justify-content-center mt-5">
                <div className="col-md-8">
                    <div className="card">
                        <div className="card-body">
                            <h2 className="text-center mb-4 font-weight-bold ">Time Off Request</h2>
                            {Object.entries(pto).length !== 0
                            
                            ?
                                <form>
                                    {
                                        pto.reason_deny !== null
                                        ?
                                            <div className="form-group">
                                                <label>PTO Denial Reason:</label>
                                                <p>{pto.reason_deny}</p>
                                            </div>
                                        
                                        :
                                        null
                                    }
                                    
                                <div className="form-group">
                                    <label>Employee Name</label>
                                    <input 
                                        type="text" 
                                        className="form-control" 
                                        placeholder="Employee Name" 
                                        defaultValue= {pto.employee_name}
                                        disabled
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Department</label>
                                    <input 
                                        type="text" 
                                        className="form-control" 
                                        placeholder="Department"
                                        defaultValue= {pto.department}
                                        disabled
                                    />
                                </div>
                                
                                <div className="form-group">
                                    <label>Abscence Code</label>
                                    <select className="custom-select"
                                    disabled  
                                    >
                                        <option value={pto.absence_code}>{absenceText}</option>
                                    </select>
                                </div>
                                
    
                                <div className="row container justify-content-center">
                                    <label><span className="font-weight-bold form-info">Indicate below the number of regularly scheduled hours you will be absent and the appropriate absence code for each day of absence requested.</span></label>
                                </div>
    
                                <div className="row container justify-content-between">
                                    
                                    
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Day Beginning:</label>
                                            <input 
                                            type="text" 
                                            className="form-control"
                                            defaultValue = {dayBeginnig}
                                            disabled
                                            />
                                        </div>
                                    </div>
    
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Day End:</label>
                                            <input 
                                            type="text" 
                                            className="form-control"
                                            defaultValue = {dayEnd}
                                            disabled
                                            />
                                        </div>
                                    </div>
    
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Time Beginning:</label>
                                            <input 
                                            type="text" 
                                            className="form-control"
                                            defaultValue = {pto.time_beginning}
                                            disabled
                                            />
                                        </div>
                                    </div>
    
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Time End:</label>
                                            <input 
                                            type="text" 
                                            className="form-control"
                                            defaultValue = {pto.time_end}
                                            disabled
                                            />
                                        </div>
                                    </div>
                                    
                                </div>
    
                                <div className="form-group">
                                    <label>Total Number of Hours</label>
                                    <input 
                                        type="text" 
                                        className="form-control" 
                                        placeholder=""
                                        defaultValue = {pto.total_hours}
                                        disabled
                                    />
                                </div>

                                <div className="form-group">
                                    <label>Explain reason for request if any part of absence is to be unpaid:</label>
                                    <textarea 
                                        className="form-control"
                                        defaultValue = {pto.reason}
                                        disabled
                                        >
                                    </textarea>
                                </div>
    
                                <div className="row container justify-content-center">
                                    <label>
                                        <p className="form-info">I am requesting the time off as shown above. I understand that any PTO/Vacation taken before it has accrued is an advance of wages.</p> 
                                        <p className="form-info">I understand that any wages advanced to me in the form of PTO/Vacation which has not accrued at the time of my
                                        termination from Administaff and/or Client Company will be deducted from the gross wages on my final paycheck which may reduce
                                        my final rate of pay below minimum wage and I authorize such deduction by Administaff from my final paycheck.</p>
    
                                        <p className="form-info">
                                        <span className="font-weight-bold">For California Employees Only:</span> I am requesting the time off as shown above. I understand that any PTO taken before it has accrued is an advance of wages and that I may be required to sign a Compensation Reduction/Deduction Authorization Form authorizing deduction from my pay of any amounts advanced.    
                                        </p>
                                    </label>
                                </div>
    
                                <div className="row container justify-content-between">
                                    
                                    
                                    <div className="col-md-8 col-sm-12">
                                        <div className="form-group signatureContainer">
                                            <label>Employee Signature:</label>
                                            <img width={100} height={100} src={pto.employee_signature} alt="signature"/>
                                        </div>
                                    </div>
    
                                    <div className="col-md-4 col-sm-12">
                                        <div className="form-group">
                                            <label>Date:</label>
                                            <input 
                                            type="text" 
                                            className="form-control"
                                            defaultValue={dateSignature}
                                            disabled
                                            />
                                        </div>
                                    </div>
                                    
                                </div>
    
                                <div className="row container justify-content-center">
                                    <label className="form-info"><span className="font-weight-bold">Disclaimer: Client Company is solely obligated for paying all Client Company-initiated programs, policies and practices
                                    including, but not limited to, vacation, sick time and paid time off. </span></label>
                                </div>
                            </form>
                            :
                                null
                            }
                            
            
                        </div>
                    </div>
                </div>
            </div>
        );

    

    
};

export default ViewPto;