import React, {useState, useEffect, useRef} from 'react';

// Redux
import {useDispatch, useSelector} from 'react-redux';
import {getPtoDetail, editPtoAction} from '../actions/timeoffrequestActions';
import {ajustarBalanceHoras} from '../actions/authenticateActions';
import { validarFormularioAction, validacionExito, validacionError } from '../actions/validacionActions';
import {obtenerDepartamentos} from '../actions/manageActions';

// library
import SignatureCanvas from 'react-signature-canvas';
import moment from 'moment-business-days';
import Swal from 'sweetalert2';
import DatePicker from "react-datepicker";
import axiosHr from '../config/axiosHR';
 
import "react-datepicker/dist/react-datepicker.css";

const EditPto = ({match, history}) => {
     // Dispatch para ejecutar la acción principal
     const dispatch = useDispatch();

     // obtener el ID a editar
     const { id } = match.params;
 
     var holidaysArray = ['04-02-2021', '05-31-2021', '07-05-2021', '09-06-2021', '11-25-2021', '11-26-2021', '12-23-2021', '12-24-2020', '01-01-2021', '01-01-2022', '04-15-2022', '05-30-2022', '07-04-2022', '09-05-2022', '11-24-2022', '11-25-2022', '12-23-2022', '12-26-2022', '01-02-2023', '04-07-2023', '07-04-2023', '05-29-2023', '09-04-2023', '11-23-2023', '11-24-2023', '12-22-2023', '12-25-2023', '01-01-2024', '03-29-2024', '05-27-2024', '07-04-2024', '09-02-2024', '11-28-2024', '11-29-2024', '12-24-2024', '12-25-2024'];

     var excludedDatesArray = [new Date("2021-02-04T12:00:00-00:00"), new Date("2021-05-31T12:00:00-00:00"), new Date("2021-07-05T12:00:00-00:00"), new Date("2021-09-06T12:00:00-00:00"), new Date("2021-11-25T12:00:00-00:00"), new Date("2021-11-26T12:00:00-00:00"), new Date("2021-12-23T12:00:00-00:00"), new Date("2021-12-24T12:00:00-00:00"),new Date("2022-01-01T12:00:00-00:00"), new Date("2021-01-01T12:00:00-00:00"), new Date("2022-04-15T12:00:00-00:00"), new Date("2022-05-30T12:00:00-00:00"), new Date("2022-07-04T12:00:00-00:00"), new Date("2022-09-05T12:00:00-00:00"), new Date("2022-11-24T12:00:00-00:00"), new Date("2022-11-25T12:00:00-00:00"), new Date("2022-12-23T12:00:00-00:00"), new Date("2022-12-26T12:00:00-00:00"), new Date("2023-01-02T12:00:00-00:00"), new Date("2023-04-07T12:00:00-00:00"), new Date("2023-05-29T12:00:00-00:00"), new Date("2023-07-04T12:00:00-00:00"), new Date("2023-09-04T12:00:00-00:00"), new Date("2023-11-23T12:00:00-00:00"), new Date("2023-11-24T12:00:00-00:00"), new Date("2023-12-22T12:00:00-00:00"), new Date("2023-12-25T12:00:00-00:00"), new Date("2024-01-01T12:00:00-00:00"), new Date("2024-03-29T12:00:00-00:00"), new Date("2024-05-27T12:00:00-00:00"), new Date("2024-07-04T12:00:00-00:00"), new Date("2024-09-02T12:00:00-00:00"), new Date("2024-11-28T12:00:00-00:00"), new Date("2024-11-29T12:00:00-00:00"), new Date("2024-12-24T12:00:00-00:00"), new Date("2024-12-25T12:00:00-00:00"), new Date("2025-01-01T12:00:00-00:00")]
    
 
     // set working days
     moment.updateLocale('us', {
         workingWeekdays: [1, 2, 3, 4, 5],
         holidays: holidaysArray,
         holidayFormat: 'MM-DD-YYYY'
 
      });
 
 
     // get data from state
     const error = useSelector((state) => state.error.error);
     //acceder al state
     const userInfo = useSelector(state=>state.userInfo);
     const pto = useSelector(state => state.ptoList.pto);
     // agregar balance de horas
     const balanceHorasActual = userInfo.hours;

     // obtener departamentos
    const departments = useSelector(state=>state.manager.departments);

    const filterDepartment  = (array, query) => {
        return array.filter(function(el) {
            let departmentElement = el.department
            return departmentElement.toLowerCase().indexOf(query.toLowerCase()) > -1;
        })
      }
 
     const supervisor = userInfo.userInfo.supervisor;
 
     const email = userInfo.userInfo.email;
 
     // state
     const [employeeName, saveEmployeeName] = useState('');
     const [department, saveDepartment] = useState('');
     const [reason, saveReason] = useState('');
     const [dayBegining, saveDayBeginning] = useState('');
     const [dayEnd, saveDayEnd] = useState('');
     const [timeBeginning, saveTimeBeginning] = useState('');
     const [timeEnd, saveTimeEnd] = useState('');
     const [totalHours, saveTotalHours] = useState('');
     const [absenceCode, saveAbsenceCode] = useState('');
     const [signatureEmployee, saveSignatureEmployee] = useState('');
     const [dateSignEmployee, saveDateSignEmployee] = useState(new Date());
     const [uid, saveUid] = useState('');
     const [hourValidationError, saveHourValidationError] = useState('');
     const [dateValidationError, saveDateValidationError] = useState('');
     const [departmentColor, saveDepartmentColor] = useState('');
     const [carryOver, saveCarryOver] = useState('');
     const [availableHours, saveAvailableHours] = useState('');
     const [carryOverUsage, saveCarryOverUsage] = useState('');
     const [specialCodePlus, saveSpecialCodePlus] = useState(0);
     const [sickHours, saveSickHours] = useState('');
     const [sickHoursUsage, saveSickHoursUsage] = useState('');
     const [carryOverSickHours, saveCarryOverSickHours] = useState('');
     const [carryOverSickHoursUsage, saveCarryOverSickHoursUsage] = useState('');
     const [editMode, saveEditMode] = useState(false);
     //const [signatureSupervisor, saveSignatureSupervisor] = useState('');
 
   
 
     const isWeekday = date => {
         const day = moment(date).day();
         return day !== 0 && day !== 6;
       };
     
     // Time Refs
     const dayBeginingRef = useRef('');
     const dayEndRef = useRef('');
     const timeBeginningRef = useRef('');
     const timeEndRef = useRef('');
     const carryOverRef = useRef('');
     const abscenceTextRef = useRef('');
     const specialCodeRef = useRef('');
     const sickHoursRef = useRef('');
     const carryOverSickHoursRef = useRef('');

     let absenceText;
 
     // canvas
     
     let sigPadEmployee = {};
     const clearEmployee = (e) => {
         e.preventDefault();
         sigPadEmployee.clear();
       }
     const saveCanvasEmployee = (e)=> {
         e.preventDefault();
         let urlImage = sigPadEmployee.getTrimmedCanvas().toDataURL('image/png');
         saveSignatureEmployee(urlImage);
         
     }
 
     const handleChangeInput = (event) => {
         const {value} = event.target;
         let regex = new RegExp("^[a-zA-Z0-9áéíóúÁÉÍÓÚ,. ]+$");
 
         if(regex.test(value)) {
            // console.log(value);
             saveReason(value);
         } else {
             console.log("Es un caracter no válido")
         }
     }

     const handleChangeReasonCode = (value) => {
      //  dayBeginingRef.current.clear();
      //  dayEndRef.current.clear();´
        saveAbsenceCode(value);
        if(value === 'V') {
            saveSpecialCodePlus(0);
            if(totalHours < 0) {
                saveTotalHours(0);
            }
            timeBeginningRef.current.input.disabled = false;
            timeEndRef.current.input.disabled = false;
            specialCodeRef.current.value = '';
            absenceText = 'Vacation'
        }
        if(value === 'PT') {
            saveSpecialCodePlus(0);
            if(totalHours < 0) {
                saveTotalHours(0);
            }
            timeBeginningRef.current.input.disabled = false;
            timeEndRef.current.input.disabled = false;
            specialCodeRef.current.value = '';
            absenceText = 'Personal Time'
        }
        if(value === 'S') {
            saveSpecialCodePlus(0);
            if(totalHours < 0) {
                saveTotalHours(0);
            }
            timeBeginningRef.current.input.disabled = false;
            timeEndRef.current.input.disabled = false;
            specialCodeRef.current.value = '';
            absenceText = 'Sick'
        }
        if(value === 'O') {
            saveSpecialCodePlus(0);
            specialCodeRef.current.disabled = false;
            if(totalHours < 0) {
                saveTotalHours(0);
            }
            timeBeginningRef.current.input.disabled = true;
            timeEndRef.current.input.disabled = true; 
            timeBeginningRef.current.clear();
            timeEndRef.current.clear();
            absenceText = 'Other'
        }else {
            specialCodeRef.current.disabled = true;
        }
        if(value ==='J') {
            saveSpecialCodePlus(40);
          //  saveTotalHours(totalHours - 40);
            specialCodeRef.current.disabled = false;
            timeBeginningRef.current.clear();
            timeEndRef.current.clear();
            timeBeginningRef.current.input.disabled = true;
            timeEndRef.current.input.disabled = true;
            absenceText = 'Jury Duty'
        }
        if(value ==='B1') {
            saveSpecialCodePlus(40);
           // saveTotalHours(totalHours - 8)
            specialCodeRef.current.disabled = false;
            timeBeginningRef.current.clear();
            timeEndRef.current.clear();
            timeBeginningRef.current.input.disabled = false;
            timeEndRef.current.input.disabled = false;
            absenceText = 'Bereavement 1'
        }
        if(value ==='B2') {
            saveSpecialCodePlus(24);
          //  saveTotalHours(totalHours - 24)
            specialCodeRef.current.disabled = false;
            timeBeginningRef.current.clear();
            timeEndRef.current.clear();
            timeBeginningRef.current.input.disabled = true;
            timeEndRef.current.input.disabled = true;
            absenceText = 'Bereavement 2'
        }
        if(value ==='B3') {
            saveSpecialCodePlus(8)
         //   saveTotalHours(totalHours - 40)
            specialCodeRef.current.disabled = false;
            timeBeginningRef.current.clear();
            timeEndRef.current.clear();
            timeBeginningRef.current.input.disabled = true;
            timeEndRef.current.input.disabled = true;
            absenceText = 'Bereavement 3'
        }
        if(value ==='NB') {
            saveSpecialCodePlus(24)
          //  saveTotalHours(totalHours - 24)
            specialCodeRef.current.disabled = false;
            timeBeginningRef.current.clear();
            timeEndRef.current.clear();
            timeBeginningRef.current.input.disabled = true;
            timeEndRef.current.input.disabled = true;
            absenceText = 'New Baby Born'
        }
        if(value ==='NBCSI') {
            saveSpecialCodePlus(40)
          //  saveTotalHours(totalHours - 24)
            specialCodeRef.current.disabled = false;
            timeBeginningRef.current.clear();
            timeEndRef.current.clear();
            timeBeginningRef.current.input.disabled = true;
            timeEndRef.current.input.disabled = true;
            absenceText = 'New Baby Born'
        }
        if(value ==='VT') {
            saveSpecialCodePlus(2)
           // saveTotalHours(totalHours - 24)
            specialCodeRef.current.disabled = false;
            // timeBeginningRef.current.clear();
            // timeEndRef.current.clear();
            // timeBeginningRef.current.input.disabled = false;
            // timeEndRef.current.input.disabled = false;
            absenceText = 'Vote'
        }
        if(value ==='M') {
            saveSpecialCodePlus(24)
          //  saveTotalHours(totalHours - 24)
            specialCodeRef.current.disabled = false;
            timeBeginningRef.current.clear();
            timeEndRef.current.clear();
            timeBeginningRef.current.input.disabled = true;
            timeEndRef.current.input.disabled = true;
            absenceText = 'Marriage'
        }
        if(value ==='MA') {
            saveSpecialCodePlus(480)
          // saveTotalHours(totalHours - 480)
            specialCodeRef.current.disabled = false;
            timeBeginningRef.current.clear();
            timeEndRef.current.clear();
            timeBeginningRef.current.input.disabled = true;
            timeEndRef.current.input.disabled = true;
            absenceText = 'Maternity'
        }
        if(value ==='LA') {
            saveSpecialCodePlus(0)
           // saveTotalHours(totalHours - 480)
            specialCodeRef.current.disabled = false;
            timeBeginningRef.current.clear();
            timeEndRef.current.clear();
            timeBeginningRef.current.input.disabled = true;
            timeEndRef.current.input.disabled = true;
            absenceText = 'Leave of Absence'
        }
        if(value ==='LTD') {
            saveSpecialCodePlus(0)
           // saveTotalHours(totalHours - 480)
            specialCodeRef.current.disabled = false;
            timeBeginningRef.current.clear();
            timeEndRef.current.clear();
            timeBeginningRef.current.input.disabled = true;
            timeEndRef.current.input.disabled = true;
        }
        if(value ==='STD') {
            saveSpecialCodePlus(0)
           // saveTotalHours(totalHours - 480)
            specialCodeRef.current.disabled = false;
            timeBeginningRef.current.clear();
            timeEndRef.current.clear();
            timeBeginningRef.current.input.disabled = true;
            timeEndRef.current.input.disabled = true;
        }
        if(value ==='FFCRA') {
            saveSpecialCodePlus(0)
        //    saveTotalHours(totalHours - 80)
            specialCodeRef.current.disabled = false;
            timeBeginningRef.current.clear();
            timeEndRef.current.clear();
            timeBeginningRef.current.input.disabled = true;
            timeEndRef.current.input.disabled = true;
        }
        if(value ==='MD') {
            saveSpecialCodePlus(0)
        //    saveTotalHours(totalHours - 80)
            specialCodeRef.current.disabled = false;
            timeBeginningRef.current.clear();
            timeEndRef.current.clear();
            timeBeginningRef.current.input.disabled = true;
            timeEndRef.current.input.disabled = true;
            absenceText = 'Medical Disability'
        }
        
    }
 
     // let sigPadSupervisor = {};
     // const clearSupervisor = (e) => {
     //     e.preventDefault();
     //     sigPadSupervisor.clear();
     // }
 
     // const saveCanvasSupervisor = (e)=> {
     //     e.preventDefault();
     //     let urlImage = sigPadSupervisor.getTrimmedCanvas().toDataURL('image/png');
     //     saveSignatureSupervisor(urlImage);
     // }
 
     // dispatch actions
    //  const agregarPto = (pto) => dispatch (createNewPtoAction(pto));
    const editarPto = (ptoInfo) => dispatch (editPtoAction(ptoInfo));
     const balanceHoras = (horas) => dispatch(ajustarBalanceHoras(horas));
     const validarFormulario = () => dispatch( validarFormularioAction());
     const exitoValidacion = () => dispatch( validacionExito() ) ;
     const errorValidacion = () => dispatch( validacionError() ) ;

     
     if(pto.absence_code ==='V') {
        absenceText = 'Vacation'
    }
    if(pto.absence_code ==='PT') {
        absenceText = 'Personal Time'
    }
    if(pto.absence_code ==='S') {
        absenceText = 'Sick'
    }
    if(pto.absence_code ==='J') {
        absenceText = 'Jury Duty'
    }
    if(pto.absenceCode ==='B1') {
        absenceText = 'Bereavement 1'
    }
    if(pto.absenceCode ==='B2') {
        absenceText = 'Bereavement 2'
    }
    if(pto.absenceCode ==='B3') {
        absenceText = 'Bereavement 3'
    }
    if(pto.absenceCode ==='NB') {
        absenceText = 'New Baby Born'
    }
    if(pto.absenceCode ==='NBCSI') {
        absenceText = 'New Baby Born'
    }
    if(pto.absenceCode ==='M') {
        absenceText = 'Marriage'
    }
    if(pto.absenceCode ==='MA') {
        absenceText = 'Maternity'
    }
    if(pto.absence_code ==='O') {
        absenceText = 'Other'
    } 
    if(pto.absence_code ==='LA') {
        absenceText = 'Leave of Absence'
    } 
    if(pto.absence_code ==='VT') {
        absenceText = 'Vote'
    } 
    if(pto.absence_code ==='MD') {
        absenceText = 'Medical Disability'
    } 


    // const handleRenderDates = (hr) => {
    //     if(pto.hr_pto !== undefined && pto.hr_pto === 1) {
    //     console.log('es hecho por rh');
    //     console.log(pto.day_beginning, pto.day_end);
    //     saveDayBeginning(new Date((moment(pto.day_beginning).utc().format('MM/DD/YYYY'))));
    //     saveDayEnd(new Date((moment(pto.day_end).utc().format('MM/DD/YYYY'))));
    //     }
    // }

    useEffect(() => {
       // handleRenderDates(pto.hr_pto);
       if(pto.hr_pto === 1 && !editMode) {
            saveDayBeginning(new Date((moment(pto.day_beginning).utc().format('MM/DD/YYYY'))));
            saveDayEnd(new Date((moment(pto.day_end).utc().format('MM/DD/YYYY')))); 
            console.log()
            saveTimeBeginning(moment(pto.time_beginning, "HH:mm Z")._d);
            saveTimeEnd(moment(pto.time_end, "HH:mm Z")._d);
            handleChangeReasonCode(pto.absence_code);
            console.log(absenceText, pto.absence_code);
            saveEditMode(true);
       }
       if(pto.hr_pto === 0 && !editMode ) {
            saveDayBeginning(new Date((moment(pto.day_beginning).utc().format('MM/DD/YYYY'))));
            saveDayEnd(new Date((moment(pto.day_end).utc().format('MM/DD/YYYY')))); 
            console.log()
            saveTimeBeginning(moment(pto.time_beginning, "HH:mm Z")._d);
            saveTimeEnd(moment(pto.time_end, "HH:mm Z")._d);
            handleChangeReasonCode(pto.absence_code);
            console.log(absenceText);
            saveEditMode(true);
       }
       
    },[pto.day_beginning, pto.day_end, editMode, pto.time_beginning, pto.time_end, pto.hr_pto])
 
     useEffect( ()=>{

        const ptoDetail = (id) => dispatch(getPtoDetail(id));
        ptoDetail(id);

       

         if(userInfo.userInfo.name !== undefined){
             saveEmployeeName(userInfo.userInfo.name +' '+userInfo.userInfo.lastname);
             saveCarryOver(parseInt(balanceHorasActual.carry_over));
             saveCarryOverSickHours(parseInt(balanceHorasActual.carry_over_sick_time));
             saveAvailableHours(balanceHorasActual.available_hours);
             saveSickHours(balanceHorasActual.sick_days_limit);
         } else {
             history.push('/home');
         }
 
         if(userInfo.userInfo.department !== undefined) {
             saveDepartment(userInfo.userInfo.department);
             let departmentFilter = filterDepartment(departments, userInfo.userInfo.department);
            console.log(departmentFilter)
            saveDepartmentColor(departmentFilter[0].color);
         }
 
         //saveTotalHours('0');
         let rangeBeginning = moment(dayBegining);
         let rangeEnd = moment(dayEnd);
         let rangeHourBeginning = moment(timeBeginning, 'HH:mm');
         let rangeHourEnd = moment(timeEnd, 'HH:mm');
         let totalAmount, hourPerDay;
         let launchTimeBeginning =  moment('14:00:00', 'HH:mm');
         let launchTimeEnd = moment('15:00:00', 'HH:mm');
         let laborTimeBeginning =  moment('08:00:00', 'HH:mm');
         let laborTimeEnd = moment('23:00:00', 'HH:mm');

 
         if(userInfo.userInfo.uid !== undefined) {
             saveUid(userInfo.userInfo.uid);
         }
         if(rangeBeginning._locale._invalidDate === '' && rangeEnd._locale._invalidDate === '') {
             
             return
         }else {
             
             let subtractDays, subtractHours;
 
 
             if(rangeBeginning._locale._invalidDate === 'Invalid date' && rangeEnd._locale._invalidDate === 'Invalid date') {
                 subtractHours = 0;
             }
             if ((rangeHourBeginning.isBetween(laborTimeBeginning, launchTimeEnd) && rangeHourEnd.isBetween(launchTimeEnd, laborTimeEnd)) || (rangeHourBeginning.isBetween(launchTimeBeginning, launchTimeEnd) && rangeHourEnd.isBetween(launchTimeBeginning, launchTimeEnd))) {
                subtractHours = (moment.duration(rangeHourEnd - rangeHourBeginning).asHours()) - 1;
            }else {
                subtractHours = moment.duration(rangeHourEnd - rangeHourBeginning).asHours();
            }
                 //subtractHours = rangeHourEnd.diff(rangeHourBeginning);
                 //subtractDays = rangeEnd.diff(rangeBeginning, 'hours');
                
                 //subtractDays = moment.duration(rangeEnd - rangeBeginning).asDays();
                 if(rangeBeginning.format('YYYY-MM-DD') === rangeEnd.format('YYYY-MM-DD')) {
                    

                    // if(absenceCode === 'B1') {
                    //     subtractDays =  rangeEnd.businessDiff(rangeBeginning) + 1;
                    // }else {
                    //     subtractDays =  rangeEnd.businessDiff(rangeBeginning);
                    // }
                    subtractDays =  (rangeEnd.businessDiff(rangeBeginning));
                    
                }else {
                    subtractDays =  rangeEnd.businessDiff(rangeBeginning) + 1;
                }
                
                hourPerDay = (subtractDays * 8);
 
                // console.log(subtractDays);
                 
                 if(isNaN(subtractHours) && !isNaN(hourPerDay)) {
                   //  saveTotalHours(Math.round(hourPerDay - specialCodePlus));
                   saveTotalHours(Math.round(hourPerDay));
                     
                 }else if(isNaN(hourPerDay) && isNaN(subtractHours)) {
                     saveTotalHours(0);
                 }else {
                     totalAmount = parseInt(hourPerDay) + parseInt(Math.round(subtractHours));
                    // saveTotalHours(totalAmount - specialCodePlus);
                    saveTotalHours(totalAmount);
                 }

                 if(absenceCode === 'LA' || absenceCode === 'FFCRA' || absenceCode === 'LTD' || absenceCode === 'STD' || absenceCode === 'MD') {
                    // totalAmount = 0;
                    // saveTotalHours(0);
                     totalAmount = parseInt(hourPerDay);
                     saveTotalHours(totalAmount);
                     saveSpecialCodePlus(totalAmount);
                 }
 
                 // if(rangeBeginning.format('YYYY-MM-DD') < moment().format('YYYY-MM-DD') || rangeEnd.format('YYYY-MM-DD') < moment().format('YYYY-MM-DD') || rangeEnd.format('YYYY-MM-DD') < rangeBeginning.format('YYYY-MM-DD')) {
                 if(rangeEnd.format('YYYY-MM-DD') < rangeBeginning.format('YYYY-MM-DD')) {
                     saveTotalHours(0);
                     saveDateValidationError(true);
                 }else {
                     saveDateValidationError(false);
                 }

                 if (parseInt(totalHours) > parseInt(balanceHorasActual.sick_days_limit) + parseInt(balanceHorasActual.carry_over_sick_time)) {
                    saveHourValidationError(true);
                   //saveTotalHours(0);
                   dayBeginingRef.current.value = '';
                  dayEndRef.current.value = '';
                  
                   
                }else {
                    saveHourValidationError(false);
                }
             
         }
 
         let carryOverSum, sumAvailableHours,sickHoursSum, carryOverSickSum = 0;
         //console.log(availableHours);
         if(userInfo.userInfo.carryOver !== undefined || userInfo.userInfo.carryOver !== 0 ||userInfo.userInfo.carryOver !== '') {
              let carryoverInit = balanceHorasActual.carry_over;
              let sickHoursInit = balanceHorasActual.sick_days_limit;
              let carryoversickInit = balanceHorasActual.carry_over_sick_time;
              if (totalHours !== '' || totalHours !== undefined) {

                if(specialCodePlus !== 0) {
                    // carryOverSum = (parseInt(carryoverInit) - parseInt(totalHours) - parseInt(specialCodePlus));
                    carryOverSum = (parseInt(carryoverInit) - parseInt(totalHours) + specialCodePlus);
                }else {
                    carryOverSum = (parseInt(carryoverInit) - parseInt(totalHours));
                }
                if(absenceCode === 'S') {
                    carryOverSickSum = (parseInt(carryoversickInit) -parseInt(totalHours));

                    sickHoursSum = ((parseInt(sickHoursInit) + parseInt(carryoversickInit))  - parseInt(totalHours));

                   // sickHoursSum = (parseInt(sickHoursInit) - parseInt(totalHours));

                    saveCarryOver(Math.round(carryoverInit));
                    carryOverRef.current.value = carryoverInit;
                    saveCarryOverUsage(0);

                    if(!isNaN(carryOverSickSum)) {
                        if(carryOverSickSum >= 0) {
                            if(carryOverSickSum < 0) {
                                carryOverSickHoursRef.current.value = '0';
//                                saveCarryOverSickHoursUsage(carryoversickInit)
                                saveCarryOverSickHoursUsage(Math.round(carryoversickInit));
                                saveCarryOverSickHours(Math.round(carryOverSickSum));
                            }else {
                                saveCarryOverSickHours(Math.round(carryOverSickSum));
                                carryOverSickHoursRef.current.value = carryOverSickSum;
                                saveCarryOverSickHoursUsage(carryoversickInit - carryOverSickSum);
                            }

                        }else {

                            if(!isNaN(sickHoursSum)) {
                                if(sickHoursSum < 0) {
                                    carryOverSickHoursRef.current.value = '0';
                                    sickHoursRef.current.value = '0';
                                    saveSickHoursUsage(sickHoursInit)
                                    saveSickHoursUsage(Math.round(sickHoursSum));
                                }else {
                                    carryOverSickHoursRef.current.value = '0';
                                    saveSickHours(Math.round(sickHoursSum));
                                    sickHoursRef.current.value = sickHoursSum;
                                    saveSickHoursUsage(sickHoursInit - sickHoursSum);
                                }
                            }
                        }
                    }
                } else {
                    saveSickHours(sickHoursInit);
                    saveSickHoursUsage(0);
                    saveCarryOverSickHours(carryoversickInit);
                    saveCarryOverSickHoursUsage(0);
                    saveSickHoursUsage(0);
                    sickHoursRef.current.value = sickHoursInit;
                    // console.log(carryoverInit + " - " + totalHours + " = " + carryOverSum);
                    if(!isNaN(carryOverSum)) {
                        if(carryOverSum < 0 ) {
                            let availableHoursInit = balanceHorasActual.available_hours
                           // sumAvailableHours = availableHoursInit + carryOverSum + specialCodePlus
                           sumAvailableHours = availableHoursInit + carryOverSum 
                           //console.log(sumAvailableHours);
                            saveAvailableHours(sumAvailableHours);
                        }
                        if(carryOverSum < 0) {
                            carryOverRef.current.value = '0';
                            saveCarryOverUsage(carryoverInit);
                            saveCarryOver(Math.round(carryOverSum));
                        }else {
                            saveCarryOver(Math.round(carryOverSum));
                            carryOverRef.current.value = carryOverSum;
                            saveCarryOverUsage(carryoverInit - carryOverSum);
                            
                        }
                    }
                    //console.log("carry Over =", carryOverSum);
                }
             }
             
         }
 
          if (parseInt(totalHours) > parseInt(balanceHorasActual.available_hours) + parseInt(balanceHorasActual.carry_over) + parseInt(balanceHorasActual.sick_days_limit)+ parseInt(balanceHorasActual.carry_over_sick_time)) {
              saveHourValidationError(true);
             //saveTotalHours(0);
          }else {
              saveHourValidationError(false);
          }
              
 
     }, [id, dispatch, userInfo, dayBegining, dayEnd, timeEnd, timeBeginning, totalHours, balanceHorasActual.available_hours, history, carryOver, balanceHorasActual.carry_over, specialCodePlus])
     
     // Add new PTO
     const submitEditPto = e => {
       e.preventDefault();
      // saveReason(pto.reason)
       saveAbsenceCode(pto.absence_code);
       validarFormulario();
       if (parseInt(totalHours) > (parseInt(balanceHorasActual.sick_days_limit) + parseInt(balanceHorasActual.carry_over_sick_time)) && absenceCode === 'S') {
        saveHourValidationError(true);
        // errorValidacion();
        //saveTotalHours(0);
        dayBeginingRef.current.value = '';
        dayEndRef.current.value = '';
        return
        
        }else {
            saveHourValidationError(false);
        }
       //saveAbsenceCode(pto.absence_code);
       if(employeeName.trim() === ''|| department.trim() === '' || totalHours < 0 || absenceCode.trim() === '' || signatureEmployee.trim() === '') {
           errorValidacion();
           return
       }
 
       exitoValidacion();

       let absenceText;
       if(abscenceTextRef.current.value ==='V') {
          absenceText = 'Vacation'
        }
        if(abscenceTextRef.current.value ==='LA') {
            absenceText = 'Leave of Absence'
          }
        if(abscenceTextRef.current.value ==='PT') {
            absenceText = 'Personal Time'
            }
        if(abscenceTextRef.current.value ==='S') {
            absenceText = 'Sick'
        }
        if(abscenceTextRef.current.value ==='J') {
            absenceText = 'Jury Duty'
        }
        if(abscenceTextRef.current.value ==='B1') {
            absenceText = 'Bereavement 1'
        }
        if(abscenceTextRef.current.value ==='B2') {
            absenceText = 'Bereavement 2'
        }
        if(abscenceTextRef.current.value ==='B3') {
            absenceText = 'Bereavement 3'
        }
        if(abscenceTextRef.current.value ==='NB') {
            absenceText = 'New Baby Born'
        }
        if(abscenceTextRef.current.value ==='NBCSI') {
            absenceText = 'New Baby Born'
        }
        if(abscenceTextRef.current.value ==='M') {
            absenceText = 'Marriage'
        }
        if(abscenceTextRef.current.value ==='MA') {
            absenceText = 'Maternity'
        }
        if(abscenceTextRef.current.value ==='O') {
            absenceText = 'Other'
        }
        if(abscenceTextRef.current.value ==='FFCRA') {
            absenceText = 'FFCRA (Emergency Sick Time)'
        }
        if(abscenceTextRef.current.value ==='VT') {
            absenceText = 'Vote'
        }
        if(abscenceTextRef.current.value ==='MD') {
            absenceText = 'Medical Disability'
        }
      
       
 
       if (parseInt(totalHours) > parseInt(balanceHorasActual.available_hours) + parseInt(userInfo.userInfo.carryOver)) {
         saveHourValidationError(true);
        // saveTotalHours(0);
         dayBeginingRef.current.value = '';
         dayEndRef.current.value = '';
         // timeBeginningRef.current.value = '';
         // timeEndRef.current.value = '';
        // dayBeginingRef.current.focus();
         // saveDayBeginning('');
         // saveDayEnd('');
         // saveTimeBeginning('');
         // saveTimeEnd('');
         return
         
         }else {
         saveHourValidationError(false);
         
         }
 
       // Preguntar al usuario
       Swal.fire({
         title: 'Request Summary',
         text: "This is your summary request, are you sure to submit ?",
         type: 'warning',
         showCancelButton: true,
         confirmButtonColor: '#3085d6',
         cancelButtonColor: '#d33',
         confirmButtonText: 'Confirm',
         cancelButtonText: 'Cancel',
         html: '<div>'+
                     '<p> Name : ' + employeeName + '</p>' +
                     '<p> Department : ' + department + '</p>' +
                     '<p> Reason : ' + reason + ' </p>' +
                     '<p> Total Hours: ' + totalHours + '</p>' +
                     '<p> Absence Code: ' + absenceText + '</p>' +
                  '</div>'
       }).then((result) => {
         if (result.value) {
           Swal.fire(
             'Saved',
             'Your request has been sent successfully',
             'success'
           )
           let rangeBeginning = moment(dayBegining).format('YYYY-MM-DD');
           let rangeEnd = moment(dayEnd).format('YYYY-MM-DD');
           let rangeHourBeginning = moment(timeBeginning).format('HH:mm');
           let rangeHourEnd = moment(timeEnd).format('HH:mm');
           let dateSignature = moment(dateSignEmployee).format('YYYY-MM-DD');
 
 
           if(rangeHourBeginning === 'Invalid date' || rangeHourEnd === 'Invalid date') {
             rangeHourBeginning = moment("1200", "hmm").format("HH:mm");
             rangeHourEnd = moment("1200", "hmm").format("HH:mm");
           }

           const hrPTO = pto.hr_pto;

           
 
 
             editarPto({
                 id,
                 employeeName,
                 department,
                 reason,
                 rangeBeginning,
                 rangeEnd,
                 rangeHourBeginning,
                 rangeHourEnd,
                 totalHours,
                 absenceCode,
                 signatureEmployee,
                 dateSignature,
                 uid,
                 supervisor,
                 departmentColor,
                 email,
                 carryOverUsage,
                 sickHoursUsage,
                 carryOverSickHoursUsage,
                 hrPTO
             });
 
             let carryOverZero, sickHoursZero, carryOverSickZero;
 
             if(carryOver < 0) {
                 carryOverZero = 0;
             }else {
                 carryOverZero = carryOver;
             }

             if(sickHours < 0) {
                sickHoursZero = 0;
            }else {
                sickHoursZero = sickHours;
            }

            if(carryOverSickHours < 0) {
                carryOverSickZero = 0
            }else {
                carryOverSickZero = carryOverSickHours;
            }
 
 
            if(absenceCode === 'LA' || absenceCode === 'FFCRA') {
                balanceHoras({
                    available_hours: parseInt(availableHours),
                    requested_hours: parseInt(balanceHorasActual.requested_hours) + parseInt(totalHours),
                    total_requests: balanceHorasActual.total_requests + 1,
                    carry_over: carryOverZero,
                    sick_hours_limit: sickHoursZero,
                    carry_over_sick_time: carryOverSickZero,
                    increase_date: rangeEnd,
                    typeBalance: true,
                    uid : uid
                });
            }else {
                balanceHoras({
                    available_hours: parseInt(availableHours),
                    requested_hours: parseInt(balanceHorasActual.requested_hours) + parseInt(totalHours),
                    total_requests: balanceHorasActual.total_requests + 1,
                    carry_over: carryOverZero,
                    sick_hours_limit: sickHoursZero,
                    carry_over_sick_time: carryOverSickZero,
                    increase_date: moment(userInfo.hours.increase_date).format('YYYY-MM-DD'),
                    typeBalance: 'false',
                    uid : uid
                });
            }

            if(absenceCode === 'S') {
                let data = {
                    uid : userInfo.userInfo.uid,
                    hours_before_mov:parseInt(userInfo.hours.sick_days_limit),
                    hours_after_mov:parseInt(sickHoursZero),
                    hours_mov: parseInt(totalHours),
                    carry_over_before_mov:parseInt(userInfo.hours.carry_over),
                    carry_over_after_mov:carryOverZero,
                    carry_over_sick_time_before:parseInt(userInfo.hours.carry_over_sick_time),
                    carry_over_sick_time_after:parseInt(carryOverSickZero),
                    observations: 'New PTO ' + absenceText,
                    type_mov: 'New PTO',
                    date_mov: moment().format('YYYY-MM-DD'),
                    mov_increase_date: moment(userInfo.hours.increase_date).format('YYYY-MM-DD'),
                    mov_yearly_increase_date:moment(userInfo.hours.year_increase_date).format('YYYY-MM-DD'),
                    increase_date_before_mov:moment(userInfo.hours.increase_date).format('YYYY-MM-DD'),
                    yearly_increase_date_before_mov:moment(userInfo.hours.year_increase_date).format('YYYY-MM-DD')
                }
                axiosHr.post('/hour-movement-log', data)
                .then(respuesta => {
                    console.log(respuesta)
                })
                .catch(error => {
                console.log(error)
                });
            }else {

                if(absenceCode === 'LA' || absenceCode === 'FFCRA') {
                    let data = {
                        uid : userInfo.userInfo.uid,
                        hours_before_mov:parseInt(userInfo.hours.available_hours),
                        hours_after_mov:parseInt(availableHours),
                        hours_mov: parseInt(totalHours),
                        carry_over_before_mov:parseInt(userInfo.hours.carry_over),
                        carry_over_after_mov:carryOverZero,
                        carry_over_sick_time_before:parseInt(userInfo.hours.carry_over_sick_time),
                        carry_over_sick_time_after:parseInt(carryOverSickZero), 
                        observations: 'New PTO ' + absenceText,
                        type_mov: 'New PTO',
                        date_mov: moment().format('YYYY-MM-DD'),
                        mov_increase_date: moment(userInfo.hours.increase_date).format('YYYY-MM-DD'),
                        mov_yearly_increase_date:moment(userInfo.hours.year_increase_date).format('YYYY-MM-DD'),
                        increase_date_before_mov:rangeEnd,
                        yearly_increase_date_before_mov:moment(userInfo.hours.year_increase_date).format('YYYY-MM-DD')
                    }
                    axiosHr.post('/hour-movement-log', data)
                    .then(respuesta => {
                        console.log(respuesta)
                    })
                    .catch(error => {
                    console.log(error)
                    });
                }else {
                    let data = {
                        uid : userInfo.userInfo.uid,
                        hours_before_mov:parseInt(userInfo.hours.available_hours),
                        hours_after_mov:parseInt(availableHours),
                        hours_mov: parseInt(totalHours),
                        carry_over_before_mov:parseInt(userInfo.hours.carry_over),
                        carry_over_after_mov:carryOverZero,
                        carry_over_sick_time_before:parseInt(userInfo.hours.carry_over_sick_time),
                        carry_over_sick_time_after:parseInt(carryOverSickZero),
                        observations: 'New PTO ' + absenceText,
                        type_mov: 'New PTO',
                        date_mov: moment().format('YYYY-MM-DD'),
                        mov_increase_date: moment(userInfo.hours.increase_date).format('YYYY-MM-DD'),
                        mov_yearly_increase_date:moment(userInfo.hours.year_increase_date).format('YYYY-MM-DD'),
                        increase_date_before_mov:moment(userInfo.hours.increase_date).format('YYYY-MM-DD'),
                        yearly_increase_date_before_mov:moment(userInfo.hours.year_increase_date).format('YYYY-MM-DD')
                    }
                    axiosHr.post('/hour-movement-log', data)
                    .then(respuesta => {
                        console.log(respuesta)
                    })
                    .catch(error => {
                    console.log(error)
                    });
                }
                
            }
            
             //redirect
             history.push('/home');
         }
     })
    }

     // End New PTO code
 
    //    if(pto.time_beginning == null || pto.time_end == null) {
    //        pto.time_beginning = '';
    //        pto.time_end = '';
    //    }
 
 
     return (
         <div className="row justify-content-center mt-5">
             {userInfo.userInfo.company !== 'CSI'
             ?
                <div className="col-md-8">
                    <div className="card">
                        <div className="card-body">
                            <h2 className="text-center mb-4 font-weight-bold ">Time Off Request</h2>
                            <form onSubmit={submitEditPto}>
                                <div className="form-group">
                                    <label>Employee Name</label>
                                    <input 
                                        type="text" 
                                        className="form-control" 
                                        placeholder="Employee Name" 
                                        defaultValue= {pto.employee_name}
                                        disabled
                                        onChange={ e => saveEmployeeName(e.target.value) }
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Department</label>
                                    <input 
                                        type="text" 
                                        className="form-control" 
                                        placeholder="Department"
                                        defaultValue= {pto.department}
                                        disabled
                                        onChange={ e => saveDepartment(e.target.value) }
                                    />
                                </div>

                                <div className="form-group">
                                    <label>Abscence Code</label>
                                    <select className="custom-select"
                                    onChange={e=>handleChangeReasonCode(e.target.value)}
                                    required
                                    ref={abscenceTextRef}
                                    >
                                        <option value={pto.absence_code}>{absenceText}</option>
                                        <option value="V">Vacation</option>
                                        <option value="PT">Personal Time</option>
                                        <option value="S">Sick</option>
                                        <option value="O">Other</option>
                                    </select>
                                </div>

                                <div className="form-group">
                                    <label>*Excused Absence</label>
                                    <select className="custom-select"
                                    onChange={e=>handleChangeReasonCode(e.target.value)}
                                    disabled
                                    ref = {specialCodeRef}
                                    >
                                        <option value="">Select reason...</option>
                                        {/* <option value="FFCRA">FFCRA (Emergency Sick Time)</option>
                                        <option value="LA">Leave of Absence</option> */}
                                        <option value="J">Jury Duty</option>
                                        {/* <option value="B1">Bereavement 1</option>
                                        <option value="B2">Bereavement 2</option>
                                        <option value="B3">Bereavement 3</option> */}
                                        <option value="NB">New Baby Born</option>
                                        <option value="M">Marriage</option>
                                        <option value="MA">Maternity</option>
                                        <option value="VT">Vote</option>
                                    </select>
                                </div>

                                <div className="row container justify-content-center">
                                    <label><span className="font-weight-bold form-info">Indicate below the number of regularly scheduled hours you will be absent and the appropriate absence code for each day of absence requested.</span></label>
                                </div>

                                <div className="row container justify-content-between">
                                    
                                    
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Day Beginning:</label>
                                            {/* <input 
                                            type="text" 
                                            className="form-control"
                                            defaultValue = {dayBeginnig}
                                            disabled
                                            /> */}
                                            <DatePicker
                                            className="datePicker"
                                            todayButton="Today"
                                        // selected={new Date((moment(pto.day_beginning).utc().format('MM/DD/YYYY')))}
                                        selected = {dayBegining}
                                        defaultValue = {new Date((moment(pto.day_beginning).utc().format('MM/DD/YYYY')))}
                                            onChange={date => saveDayBeginning(date)}
                                            filterDate={isWeekday}
                                            placeholderText="Select a weekday"
                                        ref = {dayBeginingRef}
                                        // minDate={new Date()}
                                            excludeDates={excludedDatesArray}
                                            highlightDates = {excludedDatesArray}
                                            required
                                            >
                                                <div style={{color: "gray", margin :"5px"}}>Holidays are marked in red color <span style={
                                                    {
                                                        backgroundColor : "crimson", 
                                                        borderRadius: ".5rem", 
                                                        color:"white",
                                                        textAlign: "center",
                                                        padding: "2px"
                                                    }
                                                    }>0</span></div>
                                            </DatePicker>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Day End:</label>
                                            {/* <input 
                                            type="text" 
                                            className="form-control"
                                            defaultValue = {dayEnd}
                                            disabled
                                            /> */}
                                            <DatePicker
                                            className="datePicker"
                                            todayButton="Today"
                                        // selected={new Date((moment(pto.day_end).utc().format('MM/DD/YYYY')))}
                                        selected= {dayEnd}
                                            defaultValue = {dayEnd}
                                            onChange={date => saveDayEnd(date)}
                                            filterDate={isWeekday}
                                            placeholderText="Select a weekday"
                                            ref = {dayEndRef}
                                            //minDate={new Date()}
                                            excludeDates={excludedDatesArray}
                                            highlightDates = {excludedDatesArray}
                                            required
                                            >
                                                <div style={{color: "gray", margin :"5px"}}>Holidays are marked in red color <span style={
                                                    {
                                                        backgroundColor : "crimson", 
                                                        borderRadius: ".5rem", 
                                                        color:"white",
                                                        textAlign: "center",
                                                        padding: "2px"
                                                    }
                                                    }>0</span></div>
                                            </DatePicker>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Time Beginning:</label>
                                            {/* <input 
                                            type="text" 
                                            className="form-control"
                                            defaultValue = {pto.time_beginning}
                                            disabled
                                            /> */}       
                                            <DatePicker
                                            // selected={new Date().setHours(moment(pto.day_beginning).format('HH'),0,0,0)}
                                            // defaultValue = {new Date().setHours(moment(pto.day_beginning).hour(), moment(pto.day_beginning).minutes())}
                                            selected = {timeBeginning}
                                            onChange={date => saveTimeBeginning(date)}
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={60}
                                            timeCaption="Time"
                                            dateFormat="hh:mm aa"
                                            minTime= {new Date().setHours(9,0,0,0)}
                                            maxTime = {new Date().setHours(18,0,0,0)}
                                            ref={timeBeginningRef}
                                            />                        
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Time End:</label>
                                            {/* <input 
                                            type="text" 
                                            className="form-control"
                                            defaultValue = {pto.time_end}
                                            disabled
                                            /> */}
                                            <DatePicker
                                        // selected={moment(pto.day_end).toDate()}
                                        selected = {timeEnd}
                                            onChange={date => saveTimeEnd(date)}
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={60}
                                            timeCaption="Time"
                                            dateFormat="h:mm aa"
                                            minTime= {new Date().setHours(9,0,0,0)}
                                            maxTime = {new Date().setHours(18,0,0,0)}
                                            ref = {timeEndRef}
                                            />
                                        </div>
                                    </div>
                                    
                                </div>
                                { hourValidationError ? <div className="font-weight-bold alert alert-danger text-center mt-4">ERROR: Sorry there are no hours available. </div> : null }
                                { dateValidationError ? <div className="font-weight-bold alert alert-danger text-center mt-4">ERROR: Please select valid date range. </div> : null }

                                { absenceCode === 'J' || absenceCode === 'B1'|| absenceCode === 'B2' || absenceCode === 'B3' || absenceCode === 'NB' || absenceCode === 'M' || absenceCode === 'MA'
                                    ? 
                                        <div>
                                            <div className="font-weight-bold alert alert-warning text-center mt-4">
                                            Please, schedule your excused absence by using {specialCodePlus} hours.
                                            </div>
                                            <div className="font-weight-bold alert alert-primary text-center mt-4">
                                                Employees may use time from their PTO bank in hourly increments.
                                            </div>
                                        </div>
                                    : 
                                        null 
                                }
                                <div className="form-group">
                                    <label>Total Number of Hours</label>
                                    {/* <input 
                                        type="text" 
                                        className="form-control" 
                                        placeholder=""
                                        defaultValue = {pto.total_hours}
                                        disabled
                                    /> */}
                                    <input 
                                        type="text" 
                                        className="form-control" 
                                        placeholder=""
                                        defaultValue={totalHours}
                                        disabled
                                    />
                                </div>

                                <div className="form-group">
                                    <label>Carry Over Hours</label>
                                    <input 
                                        type="text" 
                                        className="form-control" 
                                        placeholder=""
                                        defaultValue={carryOver}
                                        disabled
                                        ref = {carryOverRef}
                                    />
                                </div>

                                <div className="form-group">
                                    <label>Available Sick Hours</label>
                                    <input 
                                        type="text" 
                                        className="form-control" 
                                        placeholder=""
                                        defaultValue={sickHours}
                                        disabled
                                        ref = {sickHoursRef}
                                    />
                                </div>
                                    {
                                       userInfo.userInfo.territory === 'California'
                                       ?
                                       
                                       <div style={{display:'block'}} className="form-group">
                                            <label>Carry Over Sick Hours</label>
                                            <input 
                                                type="text" 
                                                className="form-control" 
                                                placeholder=""
                                                defaultValue={carryOverSickHours}
                                                disabled
                                                ref = {carryOverSickHoursRef}
                                            />
                                        </div>
                                       : 
                                       <div style={{display:'none'}} className="form-group">
                                            <label>Carry Over Sick Hours</label>
                                            <input 
                                                type="text" 
                                                className="form-control" 
                                                placeholder=""
                                                defaultValue={carryOverSickHours}
                                                disabled
                                                ref = {carryOverSickHoursRef}
                                            />
                                        </div>
                                    }  

                                <div className="form-group">
                                    <label>Available Hours</label>
                                    <input 
                                        type="text" 
                                        className="form-control" 
                                        placeholder=""
                                        defaultValue={availableHours}
                                        disabled
                                    />
                                </div>

                                {
                                    availableHours < 0 || sickHours < 0
                                    ?
                                    <div className="form-group">
                                        <label>Explain reason for request if any part of absence is to be unpaid:</label>
                                        <textarea 
                                            className="form-control"
                                            defaultValue = {pto.reason}
                                            onChange = {handleChangeInput}
                                            >
                                        </textarea>
                                    </div>
                                    :
                                        null
                                }

                                <div className="row container justify-content-center">
                                    <label>
                                        <p className="form-info">I am requesting the time off as shown above. I understand that any PTO/Vacation taken before it has accrued is an advance of wages.</p> 
                                        <p className="form-info">I understand that any wages advanced to me in the form of PTO/Vacation which has not accrued at the time of my
                                        termination from Administaff and/or Client Company will be deducted from the gross wages on my final paycheck which may reduce
                                        my final rate of pay below minimum wage and I authorize such deduction by Administaff from my final paycheck.</p>

                                        <p className="form-info">
                                        <span className="font-weight-bold">For California Employees Only:</span> I am requesting the time off as shown above. I understand that any PTO taken before it has accrued is an advance of wages and that I may be required to sign a Compensation Reduction/Deduction Authorization Form authorizing deduction from my pay of any amounts advanced.    
                                        </p>
                                    </label>
                                </div>

                                <div className="row container justify-content-between">
                                    
                                    
                                <div className="col-md-8 col-sm-12">
                                        <label>Employee Signature:</label>
                                        <div className="form-group signatureContainer">
                                            
                                            <SignatureCanvas penColor = 'black' canvasProps={{
                                              //  width:300,
                                              //  height: 200,
                                                className: 'signatureCanvas'
                                            }} ref={ref => {sigPadEmployee = ref}} 
                                            onEnd = {saveCanvasEmployee}
                                            />
                                            {/* <button className="btn btn-secondary mt-2" onClick={saveCanvasEmployee}>Save Signature</button> */}
                                            
                                        </div>
                                        <button className="btn btn-secondary mt-2" onClick={clearEmployee}>Clear X</button>
                                    </div>

                                    <div className="col-md-4 col-sm-12">
                                        <div className="form-group">
                                            <label>Date:</label>
                                            {/* <input 
                                            type="text" 
                                            className="form-control"
                                            defaultValue={dateSignature}
                                            disabled
                                            /> */}
                                            <DatePicker
                                            className="datePicker"
                                            todayButton="Today"
                                            selected={dateSignEmployee}
                                            onChange={date => saveDateSignEmployee(date)}
                                            filterDate={isWeekday}
                                            placeholderText="Select a weekday"
                                            minDate={new Date()}
                                            excludeDates={excludedDatesArray}
                                            required
                                            disabled
                                            />
                                        </div>
                                    </div>
                                    
                                </div>

                                <div className="row container justify-content-center">
                                    <label className="form-info"><span className="font-weight-bold">Disclaimer: Client Company is solely obligated for paying all Client Company-initiated programs, policies and practices
                                    including, but not limited to, vacation, sick time and paid time off. </span></label>
                                </div>
                                <button type="submit" className="btn btn-primary font-weight-bold text-uppercase d-block w-100 mb-4">Update</button>
                            </form>

                            { error ? <div className="font-weight-bold alert alert-danger text-center mt-4">ERROR: Please fill out all the required fields. </div> : null }

                            { hourValidationError ? <div className="font-weight-bold alert alert-danger text-center mt-4">ERROR: Sorry there are no hours available. </div> : null }
            
                        </div>
                    </div>
                </div>
             :
             // PTO FORM
                <div className="col-md-8">
                    <div className="card">
                        <div className="card-body">
                            <h2 className="text-center mb-4 font-weight-bold ">Time Off Request</h2>
                            <form onSubmit={submitEditPto}>
                                <div className="form-group">
                                    <label>Employee Name</label>
                                    <input 
                                        type="text" 
                                        className="form-control" 
                                        placeholder="Employee Name" 
                                        defaultValue= {pto.employee_name}
                                        disabled
                                        onChange={ e => saveEmployeeName(e.target.value) }
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Department</label>
                                    <input 
                                        type="text" 
                                        className="form-control" 
                                        placeholder="Department"
                                        defaultValue= {pto.department}
                                        disabled
                                        onChange={ e => saveDepartment(e.target.value) }
                                    />
                                </div>

                                <div className="form-group">
                                    <label>Abscence Code</label>
                                    <select className="custom-select"
                                    onChange={e=>handleChangeReasonCode(e.target.value)}
                                    required
                                    ref={abscenceTextRef}
                                    >
                                        <option value={pto.absence_code}>{absenceText}</option>
                                        <option value="V">Vacation</option>
                                        {/* <option value="PT">Personal Time</option> */}
                                        {/* <option value="S">Sick</option> */}
                                        {/* <option value="O">Other</option> */}
                                    </select>
                                </div>

                                <div className="form-group">
                                    <label>*Excused Absence</label>
                                    <select className="custom-select"
                                    onChange={e=>handleChangeReasonCode(e.target.value)}
                                    disabled
                                    ref = {specialCodeRef}
                                    >
                                        <option value="">Select reason...</option>
                                        {/* <option value="LA">Leave of Absence</option> */}
                                        {/* <option value="J">Jury Duty</option> */}
                                        {/* <option value="B1">Bereavement 1</option>
                                        <option value="B2">Bereavement 2</option>
                                        <option value="B3">Bereavement 3</option> */}
                                        <option value="NBCSI">New Baby Born</option>
                                        <option value="M">Marriage</option>
                                        {/* <option value="MA">Maternity</option> */}
                                        {/* <option value="VT">Vote</option> */}
                                    </select>
                                </div>

                                <div className="row container justify-content-center">
                                    <label><span className="font-weight-bold form-info">Indicate below the number of regularly scheduled hours you will be absent and the appropriate absence code for each day of absence requested.</span></label>
                                </div>

                                <div className="row container justify-content-between">
                                    
                                    
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Day Beginning:</label>
                                            {/* <input 
                                            type="text" 
                                            className="form-control"
                                            defaultValue = {dayBeginnig}
                                            disabled
                                            /> */}
                                            <DatePicker
                                            className="datePicker"
                                            todayButton="Today"
                                        // selected={new Date((moment(pto.day_beginning).utc().format('MM/DD/YYYY')))}
                                        selected = {dayBegining}
                                        defaultValue = {new Date((moment(pto.day_beginning).utc().format('MM/DD/YYYY')))}
                                            onChange={date => saveDayBeginning(date)}
                                            filterDate={isWeekday}
                                            placeholderText="Select a weekday"
                                        ref = {dayBeginingRef}
                                        // minDate={new Date()}
                                            excludeDates={excludedDatesArray}
                                            highlightDates = {excludedDatesArray}
                                            required
                                            >
                                                <div style={{color: "gray", margin :"5px"}}>Holidays are marked in red color <span style={
                                                    {
                                                        backgroundColor : "crimson", 
                                                        borderRadius: ".5rem", 
                                                        color:"white",
                                                        textAlign: "center",
                                                        padding: "2px"
                                                    }
                                                    }>0</span></div>
                                            </DatePicker>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Day End:</label>
                                            {/* <input 
                                            type="text" 
                                            className="form-control"
                                            defaultValue = {dayEnd}
                                            disabled
                                            /> */}
                                            <DatePicker
                                            className="datePicker"
                                            todayButton="Today"
                                        // selected={new Date((moment(pto.day_end).utc().format('MM/DD/YYYY')))}
                                        selected= {dayEnd}
                                            defaultValue = {dayEnd}
                                            onChange={date => saveDayEnd(date)}
                                            filterDate={isWeekday}
                                            placeholderText="Select a weekday"
                                            ref = {dayEndRef}
                                            //minDate={new Date()}
                                            excludeDates={excludedDatesArray}
                                            highlightDates = {excludedDatesArray}
                                            required
                                            >
                                                <div style={{color: "gray", margin :"5px"}}>Holidays are marked in red color <span style={
                                                    {
                                                        backgroundColor : "crimson", 
                                                        borderRadius: ".5rem", 
                                                        color:"white",
                                                        textAlign: "center",
                                                        padding: "2px"
                                                    }
                                                    }>0</span></div>
                                            </DatePicker>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Time Beginning:</label>
                                            {/* <input 
                                            type="text" 
                                            className="form-control"
                                            defaultValue = {pto.time_beginning}
                                            disabled
                                            /> */}       
                                            <DatePicker
                                            // selected={new Date().setHours(moment(pto.day_beginning).format('HH'),0,0,0)}
                                            // defaultValue = {new Date().setHours(moment(pto.day_beginning).hour(), moment(pto.day_beginning).minutes())}
                                            selected = {timeBeginning}
                                            onChange={date => saveTimeBeginning(date)}
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={60}
                                            timeCaption="Time"
                                            dateFormat="hh:mm aa"
                                            minTime= {new Date().setHours(9,0,0,0)}
                                            maxTime = {new Date().setHours(18,0,0,0)}
                                            ref={timeBeginningRef}
                                            />                        
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Time End:</label>
                                            {/* <input 
                                            type="text" 
                                            className="form-control"
                                            defaultValue = {pto.time_end}
                                            disabled
                                            /> */}
                                            <DatePicker
                                        // selected={moment(pto.day_end).toDate()}
                                        selected = {timeEnd}
                                            onChange={date => saveTimeEnd(date)}
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={60}
                                            timeCaption="Time"
                                            dateFormat="h:mm aa"
                                            minTime= {new Date().setHours(9,0,0,0)}
                                            maxTime = {new Date().setHours(18,0,0,0)}
                                            ref = {timeEndRef}
                                            />
                                        </div>
                                    </div>
                                    
                                </div>
                                { hourValidationError ? <div className="font-weight-bold alert alert-danger text-center mt-4">ERROR: Sorry there are no hours available. </div> : null }
                                { dateValidationError ? <div className="font-weight-bold alert alert-danger text-center mt-4">ERROR: Please select valid date range. </div> : null }

                                { absenceCode === 'J' || absenceCode === 'B1'|| absenceCode === 'B2' || absenceCode === 'B3' || absenceCode === 'NB' || absenceCode === 'M' || absenceCode === 'MA' || absenceCode === 'VT'
                                    ? 
                                        <div>
                                            <div className="font-weight-bold alert alert-warning text-center mt-4">
                                            Please, schedule your excused absence by using {specialCodePlus} hours.
                                            </div>
                                            <div className="font-weight-bold alert alert-primary text-center mt-4">
                                                Employees may use time from their PTO bank in hourly increments.
                                            </div>
                                        </div>
                                    : 
                                        null 
                                }
                                <div className="form-group">
                                    <label>Total Number of Hours</label>
                                    {/* <input 
                                        type="text" 
                                        className="form-control" 
                                        placeholder=""
                                        defaultValue = {pto.total_hours}
                                        disabled
                                    /> */}
                                    <input 
                                        type="text" 
                                        className="form-control" 
                                        placeholder=""
                                        defaultValue={totalHours}
                                        disabled
                                    />
                                </div>

                                <div className="form-group">
                                    <label>Carry Over Hours</label>
                                    <input 
                                        type="text" 
                                        className="form-control" 
                                        placeholder=""
                                        defaultValue={carryOver}
                                        disabled
                                        ref = {carryOverRef}
                                    />
                                </div>

                                <div className="form-group">
                                    <label>Availabable Sick Hours</label>
                                    <input 
                                        type="text" 
                                        className="form-control" 
                                        placeholder=""
                                        defaultValue={sickHours}
                                        disabled
                                        ref = {sickHoursRef}
                                    />
                                </div>

                                <div className="form-group">
                                    <label>Available Hours</label>
                                    <input 
                                        type="text" 
                                        className="form-control" 
                                        placeholder=""
                                        defaultValue={availableHours}
                                        disabled
                                    />
                                </div>

                                {
                                    availableHours < 0 || sickHours < 0
                                    ?
                                    <div className="form-group">
                                        <label>Explain reason for request if any part of absence will be unpaid or taken in advance:</label>
                                        <textarea 
                                            className="form-control"
                                            defaultValue = {pto.reason}
                                            onChange = {handleChangeInput}
                                            >
                                        </textarea>
                                    </div>
                                    :
                                        null
                                }

                                <div className="row container justify-content-center">
                                    <label>
                                        <p className="form-info">I am requesting the time off as shown above. I understand that any PTO/Vacation taken before it has accrued is an advance of wages.</p> 
                                        <p className="form-info">I understand that any wages advanced to me in the form of PTO/Vacation which has not accrued at the time of my
                                        termination from Administaff and/or Client Company will be deducted from the gross wages on my final paycheck which may reduce
                                        my final rate of pay below minimum wage and I authorize such deduction by Administaff from my final paycheck.</p>

                                        {/* <p className="form-info">
                                        <span className="font-weight-bold">For California Employees Only:</span> I am requesting the time off as shown above. I understand that any PTO taken before it has accrued is an advance of wages and that I may be required to sign a Compensation Reduction/Deduction Authorization Form authorizing deduction from my pay of any amounts advanced.    
                                        </p> */}
                                    </label>
                                </div>

                                <div className="row container justify-content-between">
                                    
                                    
                                <div className="col-md-8 col-sm-12">
                                        <label>Employee Signature:</label>
                                        <div className="form-group signatureContainer">
                                           
                                            <SignatureCanvas penColor = 'black' canvasProps={{
                                             //   width:300,
                                             //   height: 200,
                                                className: 'signatureCanvas'
                                            }} ref={ref => {sigPadEmployee = ref}} 
                                            onEnd = {saveCanvasEmployee}
                                            />
                                            {/* <button className="btn btn-secondary mt-2" onClick={saveCanvasEmployee}>Save Signature</button> */}
                                            
                                        </div>
                                        <button className="btn btn-secondary mt-2" onClick={clearEmployee}>Clear X</button>
                                    </div>

                                    <div className="col-md-4 col-sm-12">
                                        <div className="form-group">
                                            <label>Date:</label>
                                            {/* <input 
                                            type="text" 
                                            className="form-control"
                                            defaultValue={dateSignature}
                                            disabled
                                            /> */}
                                            <DatePicker
                                            className="datePicker"
                                            todayButton="Today"
                                            selected={dateSignEmployee}
                                            onChange={date => saveDateSignEmployee(date)}
                                            filterDate={isWeekday}
                                            placeholderText="Select a weekday"
                                            minDate={new Date()}
                                            excludeDates={excludedDatesArray}
                                            required
                                            disabled
                                            />
                                        </div>
                                    </div>
                                    
                                </div>

                                {/* <div className="row container justify-content-center">
                                    <label className="form-info"><span className="font-weight-bold">Disclaimer: Client Company is solely obligated for paying all Client Company-initiated programs, policies and practices
                                    including, but not limited to, vacation, sick time and paid time off. </span></label>
                                </div> */}
                                <button type="submit" className="btn btn-primary font-weight-bold text-uppercase d-block w-100 mb-4">Update</button>
                            </form>

                            { error ? <div className="font-weight-bold alert alert-danger text-center mt-4">ERROR: Please fill out all the required fields. </div> : null }

                            { hourValidationError ? <div className="font-weight-bold alert alert-danger text-center mt-4">ERROR: Sorry there are no hours available. </div> : null }
            
                        </div>
                    </div>
                </div>
             }
             
         </div>
     );
 };

export default EditPto;