import React, {Fragment} from 'react';
import { Redirect} from 'react-router-dom';

const PrivateRoute = (props) => {
   const token = localStorage.getItem('token');
    return (
      <Fragment>
        {token !==null ? props.children : <Redirect to = {"/"}/>}
      </Fragment>
    );
  };

export default PrivateRoute;