import React, {useState, useEffect, useRef} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {newDepartmentAction} from '../actions/manageActions';
import Swal from 'sweetalert2';

const NewDepartment = ({match, history}) => {

     // obtener departamentos
     const departments = useSelector(state=>state.manager.departments);

    const filterElementsByDepartment  = (array, query) => {
        return array.filter(function(el) {
            
            return el.id === Number(query);
           
        })
        }

    // dispatch actions
    const dispatch = useDispatch();
    const createDepartment = (departmentToCreate) => dispatch (newDepartmentAction(departmentToCreate));
  
  
    const [departmentName, setDepartmentName] = useState('');
    const [directorMail, setDirectorMail] = useState('');
    const [reportTo, setReportTo] = useState('');
    const [active, setActive] = useState(true);
    const [responsible, setResponsible] = useState([]);
    const [checkedState, setCheckedState] = useState(departments);
    const [costCenter, setCostCenter] = useState('');
    
    const color = "#" + ((1<<24)*Math.random() | 0).toString(16);

    const handleCheckActive = (value) => {
        
        var newActive = !active;
         setActive(!active);
    }

    const handleCheckResponsible = (position, department) => {
        let updateCheckedState = checkedState;

        let found = updateCheckedState.find(departmentFound =>departmentFound.department === department.department);
        if(found){
            console.log('departamento encontrado')
            let responsibleArray = responsible.filter((item) => item.department !== department.department)
            setResponsible(responsibleArray);
        }else {
            console.log('departamento no encontrado')
            updateCheckedState.push(responsible[position])
            let responsibleArray = responsible.filter((item) => item.department !== department.department)
            console.log(responsibleArray);
            setResponsible(responsibleArray);
            setCheckedState(updateCheckedState);
        }
        
    }

    const handleCheckDepartment = (position, department) => {

        let updateCheckedState = [];
        let updateResponsible = responsible;

        let found = updateResponsible.find(departmentFound =>departmentFound.department === department.department);
        if(found) {
            console.log('articulo encontrado')
            updateCheckedState = checkedState.filter((item) => item.department !== department.department)
            // Preguntar al usuario
            Swal.fire({
                title: 'Warning',
                text: `${department.department} department is already assigned.`,
                type: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Accept',
            })
            setCheckedState(updateCheckedState);
        }else {
            console.log('articulo no encontrado')
            if(checkedState[position]) {
                console.log(checkedState[position])
                updateResponsible.push(checkedState[position])
                updateCheckedState = checkedState.filter((item) => item.department !== department.department)
                setResponsible(updateResponsible)
                setCheckedState(updateCheckedState)
                //console.log(responsible)
            }
        }


       
    }

    // useEffect(() => {
    //   //  departmentsToSelect();
        
    // }, [])

    const submitDepartment = (e) => {
        e.preventDefault();
        Swal.fire({
            title: 'Create Department',
            text: "Are you sure ?",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Confirm',
            cancelButtonText: 'Cancel'
          }).then((result) => {

            if(result.value) {

                Swal.fire(
                    'Saved',
                    'Department info has been created successfully.',
                    'success'
                )

                createDepartment({
                    departmentName,
                    directorMail,
                    reportTo,
                    active,
                    responsible,
                    color,
                    costCenter
                });

                 //redirect
                history.push('/home');


            }

            

           
          });
    }
    
    
        
        

    useEffect(() => {


    }, [responsible, checkedState])
    

    
    return (
        <div className="row mt-5">
            <div className='col-md-12 p-3'>
                <div className='card'>
                    <div className='card-body'>
                    <form 
                        onSubmit={submitDepartment}
                        >
                            <div className="form-group">
                                <label>Department Name</label>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    placeholder="Department Name" 
                                 onChange={ e => setDepartmentName(e.target.value) }
                                />
                            </div>

                            <div className="form-group">
                                <label>Director Mail</label>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    placeholder="Director Mail" 
                                    onChange={ e => setDirectorMail(e.target.value) }
                                />
                            </div>

                            <div className="form-group">
                                <label>Report To</label>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    placeholder="Report To" 
                                    onChange={ e => setReportTo(e.target.value) }
                                />
                            </div>

                            <div className="form-group">
                                <label>Cost Center</label>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    placeholder="Cost Center" 
                                    onChange={ e => setCostCenter(e.target.value) }
                                />
                            </div>

                            <div className="form-group">
                                <p><label>Active</label></p>
                                <div className="custom-control custom-switch custom-control-inline">
                                    <input 
                                    type="checkbox" 
                                    className="custom-control-input"
                                    id="customSwitch3" 
                                    onChange={e => handleCheckActive(e.target.value) } 
                                    checked= {active}
                                    />
                                    <label className="custom-control-label" htmlFor="customSwitch3">No / Yes</label>
                                </div>
                            </div>

                            <hr />

                            <div className="form-group">
                                <label>Responsible</label>
                                <div className='row justify-content-center container align-baseline'>
                                {
                                    
                                    responsible.map((department, index) => {
                                    return (
                                                    <React.Fragment key={department.id}>
                                                        {
                                                            
                                                        
                                                        <div key={department.id} className='col-md-4'>
                                                            {/* <input 
                                                            id="responsible-checkbox"
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            name={'department-director-of'}
                                                            key={department.id}
                                                            value= {department.department}
                                                            checked={checkedState[index]}
                                                            onChange={(e) => handleCheckResponsible(index)}
                                                            /> */}
                                                           
                                                            {/* <input 
                                                                type="checkbox" 
                                                                className="custom-control-input"
                                                                id={'responsible-'+department.id}
                                                                onChange={e => handleCheckResponsible(index, department) } 
                                                                checked={checkedState[index]}
                                                                /> */}
                                                            <button 
                                                            type='button'
                                                            className='btn btn-danger p-2 m-2 btn-lg'
                                                            id={'responsible-'+department.id}
                                                            onClick={e => handleCheckResponsible(index, department) }
                                                            >
                                                                {'-'}
                                                            </button>
                                                            <label htmlFor={'responsible-'+department.id} className="">{department.department}</label>
                                                        </div>

                                                       
                                                            
                                                        }
                                                    
                                                    </React.Fragment>
                                                    
                                                );
                                                    }
                                                )}
                                    </div>
                            </div>

                            <hr />

                            <div className="form-group">
                                <label>Departments</label>
                                <div className='row justify-content-center container align-baseline'>
                                {
                                    
                                    checkedState.map((department, index) => {
                                    return (
                                                    <React.Fragment key={department.id}>
                                                        {
                                                            
                                                        
                                                        <div key={department.id} className='col-md-4'>
                                                            {/* <input 
                                                            id="responsible-checkbox"
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            name={'department-director-of'}
                                                            key={department.id}
                                                            value= {department.department}
                                                            checked={checkedState[index]}
                                                            onChange={(e) => handleCheckResponsible(index)}
                                                            /> */}
                                                           
                                                            {/* <input 
                                                                type="checkbox" 
                                                                className="custom-control-input"
                                                                id={'department-'+department.id}
                                                                onChange={e => handleCheckDepartment(index, department) } 
                                                              
                                                                /> */}
                                                            <button type='button'
                                                                className='btn btn-success btn-lg p-2 m-2'
                                                                id={'department-'+department.id}
                                                                onClick={e => handleCheckDepartment(index, department) } 
                                                            >
                                                                {'+'}
                                                            </button>
                                                            <label htmlFor={'department-'+department.id} className="">{department.department}</label>
                                                        </div>

                                                       
                                                            
                                                        }
                                                    
                                                    </React.Fragment>
                                                    
                                                );
                                                    }
                                                )}
                                    </div>
                            </div>

                            

                            <button type="submit" className="btn btn-primary font-weight-bold text-uppercase d-block  mb-5">Save</button>
                    </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NewDepartment;