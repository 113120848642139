import React, {useEffect, useState, Fragment} from 'react';
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'

import {useSelector} from 'react-redux';
import {obtenerDepartamentos} from '../actions/manageActions';
import { useDispatch } from 'react-redux';

import axiosHR from '../config/axiosHR';

const PtoCalendar = ({match}) => {


    const dispatch = useDispatch();
    let {month} = match.params

    const[ptoEvents, savePtoEvents] = useState([]);
    let PtoEventsArray = [];

    const user = useSelector(state=>state.userInfo.userInfo.username);
    const manager = useSelector(state=>state.userInfo.userInfo.manager);
    const department = useSelector(state=>state.userInfo.userInfo.department);
    const userInfo = useSelector(state=> state.userInfo.userInfo)

    const departments = useSelector(state=>state.manager.departments);

    const filterElementsByDepartment  = (array, query) => {
        return array.filter(function(el) {
            return el.department.toLowerCase().indexOf(query.toLowerCase()) > -1;
        })
        }

    let departmentSupervisorFilter = filterElementsByDepartment(departments, department);
    let director_of = JSON.parse(departmentSupervisorFilter[0].director_of);

    let company = userInfo.company

    // const departments = {
    //     'Graphic Design & Digital Communication' : 'blue',
    //     'Human Resources' : 'green'
    // };

    // function saveEvents () {
        
    // }
  

    useEffect(()=> {
        if(user === 'salvador.escalona' || user === 'jose.mogollon') {
            axiosHR.get('/pto-list-all/')
            .then(respuesta => {
             //   console.log(respuesta.data.data[0].day_beginning)
                respuesta.data.data.map((pto, index) => 
                pto.status !== 'Denied'
                ?
                    PtoEventsArray.push({ title: pto.employee_name + ' ' + pto.status, start: pto.day_beginning, end: pto.day_end, url:`/employee/view/${pto.user_id}`, color:pto.color})
                :
                    null
                
                )
                savePtoEvents(PtoEventsArray);
            })
            .catch(error => {
                console.log(error);
            })
        } else if (manager === true || manager === 1){

            axiosHR.get(`/pto-list-all-manager/${company}`)
            .then(respuesta => {
                console.log(respuesta.data)
                respuesta.data.data.map((pto, index) => 
                pto.status !== 'Denied'
                ?
                    PtoEventsArray.push({ title: pto.employee_name + ' ' + pto.status, start: pto.day_beginning, end: pto.day_end, url:`/employee/view/${pto.user_id}`, color:pto.color})
                :
                    null
                
                )
                savePtoEvents(PtoEventsArray);
            })
            .catch(error => {
                console.log(error);
            })
        }else {
            
            director_of.forEach(element => {
                
            
                
                axiosHR.get(`/pto-list-all-department/${element.department}`)
                .then(respuesta => {
                    console.log(respuesta);
                    respuesta.data.data.map((pto, index) => 
                    pto.status !== 'Denied'
                    ?
                        PtoEventsArray.push({ title: pto.employee_name + ' ' + pto.status, start: pto.day_beginning, end: pto.day_end, url:`/employee/view/${pto.user_id}`, color:pto.color})
                    :
                        null
                    
                    )

                    savePtoEvents(ptoEvents.concat(PtoEventsArray));
                   
                })
                .catch(error => {
                    console.log(error);
                })
             });
        }

        const loadDepartments = ()=> dispatch(obtenerDepartamentos());
        loadDepartments();
    }, [])

    // let ejemplo = [
    //     {
    //         title: 'PTO Julio Castro', 
    //         date:'2019-10-22'
    //     },
    //     {
    //         title: 'PTO Vane Medina', 
    //         start: '2019-10-23', 
    //         end: '2019-10-23',
    //         extendedProps: {
    //             department: 'Graphic Design & Digital Communication'
    //         },
    //         description: 'PTO Request description'
    //     }
    //]    

    return (
        <Fragment>
            <div className="row mt-5 ml-5 mr-5">
                <div className="col-sm-12">
                    <div className ="card p-1">
                        <div className="card-title text-center">
                            <h3>{'Company Departments'}</h3>
                            
                        </div>
                        <div className="card-body card-pallete">
                            <div className="pallete-container">
                            {departments.map((department, index) =>(
                                    <div key={index} className="pallette-departments">
                                        <div className="color-indicator" style={{backgroundColor: department.color}}></div><span className="text-muted">{department.department}</span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12 m-5">
                {
                        // PtoEvents.map(pto =>(
                        //     console.log(pto)
                        // ))
                                
                }
                {window.innerWidth > 768 
                    ?
                    <FullCalendar defaultView="dayGridMonth" plugins={[ dayGridPlugin ]} 
                                events={ptoEvents}
                                defaultDate={month}
                                timeZone= {'America/Chicago'}
                                //height= {1}
                               // aspectRatio = {1}
                    />

                    :
                    <FullCalendar defaultView="dayGridMonth" plugins={[ dayGridPlugin ]} 
                                events={ptoEvents}
                                defaultDate={month}
                                timeZone= {'America/Chicago'}
                                height= {5}
                                aspectRatio = {16}
                    />
                }
                    
                </div>
            </div>
        </Fragment>
    );
};

export default PtoCalendar;