import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import Swal from 'sweetalert2';
import moment from 'moment';
import {ajustarBalanceHoras} from '../actions/authenticateActions';
import clienteAxiosHR from '../config/axiosHR';
import axiosHr from '../config/axiosHR';
import {approvePtoAction, denyPtoAction, getPtoListDepartmentSupervisor} from '../actions/timeoffrequestActions';

// Redux
import {useDispatch, useSelector} from 'react-redux';

const PtoSupervisor = ({pto}) => {
    // Dispatch para ejecutar
    const dispatch = useDispatch();
    
    const [reasonDeny, saveReasonDeny] = useState('');

     //acceder al state
     const userInfo = useSelector(state=>state.userInfo);
     const departmentUsers = useSelector(state=>state.manager.employees); 
     const loadPtoList = ()=> dispatch(getPtoListDepartmentSupervisor(userInfo.userInfo.department, userInfo.userInfo.uid, userInfo.userInfo.email));

     
     

    // agregar balance horas
    const balanceHoras = (horas) => dispatch(ajustarBalanceHoras(horas));

    let createdAt = moment.utc(pto.date).format('MM-DD-YYYY');
    let absenceText;
    if(pto.absence_code ==='V') {
      absenceText = 'Vacation'
    }
    if(pto.absence_code ==='PT') {
      absenceText = 'Personal Time'
    }
    if(pto.absence_code ==='LA') {
      absenceText = 'Leave of Absence'
    }
    if(pto.absenceCode ==='LTD') {
      absenceText = 'Long Term Disability'
    }
    if(pto.absenceCode ==='STD') {
      absenceText = 'Short Term Disability'
    }
    if(pto.absence_code ==='S') {
      absenceText = 'Sick'
    }
    if(pto.absence_code ==='J') {
      absenceText = 'Jury Duty'
    }
    if(pto.absence_code ==='O') {
      absenceText = 'Other'
    } 
    if(pto.absence_code ==='J') {
      absenceText = 'Jury Duty'
  }
  if(pto.absence_code ==='B1') {
      absenceText = 'Bereavement 1'
  }
  if(pto.absence_code ==='B2') {
      absenceText = 'Bereavement 2'
  }
  if(pto.absence_code ==='B3') {
      absenceText = 'Bereavement 3'
  }
  if(pto.absence_code ==='NB') {
      absenceText = 'New Baby Born'
  }
  if(pto.absence_code ==='NBCSI') {
    absenceText = 'New Baby Born'
}
  if(pto.absence_code ==='M') {
      absenceText = 'Marriage'
  }
  if(pto.absence_code ==='MA') {
      absenceText = 'Maternity'
  }
  if(pto.absence_code ==='FFCRA') {
    absenceText = 'FFCRA (Emergency Sick Time)'
  }
  if(pto.absence_code ==='VT') {
    absenceText = 'Vote'
  }
  if(pto.absence_code ==='MD') {
    absenceText = 'Medical Disability'
  }

    const approvePto = pto =>  {

      const balanceHorasActual = departmentUsers.find(uids => uids.uid === pto.user_id)

      let company = balanceHorasActual.company
       
        // ask user confirmation
        const ptoInfo = {
          pto,
          company
        }
        
        Swal.fire({
            title: '¿Are you sure?',
            text: "This action can't be undone",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Approve it!',
            cancelButtonText: 'Cancel'
          }).then((result) => {
            if (result.value) {
              
              dispatch(approvePtoAction(pto.id) );

              clienteAxiosHR.post('/pto-email-approve', ptoInfo)
                .then(respuesta => {
                    console.log(respuesta);
                    Swal.fire(
                      'Approved',
                      'The Time off Request has been approved successfully.',
                      'success'
                    )
                })
                .catch(error => {
                    console.log(error);
                    Swal.fire(
                        'Failed',
                        'An error was occurred by sending your request.',
                        'warning'
                      )
                })

              setTimeout(() => {
                loadPtoList();
              }, 4000);
              
            
              
            }
          })

         // history('/pto/supervisor');
    }

    const denyPto = pto =>  {
        // ask user confirmation
        
        Swal.fire({
            title: '¿Reason?',
            text: "This action can't be undone",
            type: 'warning',
            input: 'text',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Denied!',
            cancelButtonText: 'Cancel'
          }).then((result) => {
            if (result.value) {
              Swal.fire(
                'Denied',
                'The Time off Request has been denied successfully.',
                'success'
              ) 

              

              

              const balanceHorasActual = departmentUsers.find(uids => uids.uid === pto.user_id)

              //console.log('el uid del usuario a actualizar es', pto.user_id);
              //console.log('El balance de horas es', balanceHorasActual)

              pto.reasonDeny = result.value;

              if(pto.absence_code === 'J' || pto.absence_code === 'VT' || pto.absence_code === 'M') {
                let data
                switch (pto.absenceCode) {
                  case 'J':
                      balanceHoras({
                        available_hours: parseInt(balanceHorasActual.available_hours) + (parseInt(pto.total_hours) - parseInt(40)),
                        requested_hours: parseInt(balanceHorasActual.requested_hours) - parseInt(pto.total_hours),
                        total_requests: balanceHorasActual.total_requests - 1,
                        carry_over: parseInt(balanceHorasActual.carry_over),
                        sick_hours_limit: parseInt(balanceHorasActual.sick_days_limit),
                        carry_over_sick_time: parseInt(balanceHorasActual.carry_over_sick_time),
                        uid : pto.user_id,
                    })

                    data = {
                      uid : pto.user.uid,
                      hours_before_mov:parseInt(balanceHorasActual.available_hours),
                      hours_after_mov:parseInt(balanceHorasActual.available_hours) + (parseInt(pto.total_hours) - parseInt(40)),
                      hours_mov: parseInt(pto.total_hours),
                      carry_over_before_mov:parseInt(userInfo.hours.carry_over),
                      carry_over_after_mov:parseInt(balanceHorasActual.carry_over),
                      carry_over_sick_time_before:parseInt(balanceHorasActual.carry_over_sick_time),
                      carry_over_sick_time_after: parseInt(balanceHorasActual.carry_over_sick_time),
                      observations: 'Delete PTO '+ absenceText,
                      type_mov: 'Delete PTO',
                      date_mov: moment().format('YYYY-MM-DD'),
                      mov_increase_date: moment(balanceHorasActual.increase_date).format('YYYY-MM-DD'),
                      mov_yearly_increase_date:moment(balanceHorasActual.year_increase_date).format('YYYY-MM-DD'),
                      increase_date_before_mov:moment(balanceHorasActual.increase_date).format('YYYY-MM-DD'),
                      yearly_increase_date_before_mov:moment(balanceHorasActual.year_increase_date).format('YYYY-MM-DD')
                    }
                    axiosHr.post('/hour-movement-log', data)
                    .then(respuesta => {
                        console.log(respuesta)
                    })
                    .catch(error => {
                    console.log(error)
                    });
                    
                    break;

                    case 'VT':
                      balanceHoras({
                        available_hours: parseInt(balanceHorasActual.available_hours) + (parseInt(pto.total_hours) - parseInt(2)),
                        requested_hours: parseInt(balanceHorasActual.requested_hours) - parseInt(pto.total_hours),
                        total_requests: balanceHorasActual.total_requests - 1,
                        carry_over: parseInt(balanceHorasActual.carry_over),
                        sick_hours_limit: parseInt(balanceHorasActual.sick_days_limit),
                        carry_over_sick_time: parseInt(balanceHorasActual.carry_over_sick_time),
                        uid : pto.user_id,
                    })

                    data = {
                      uid : pto.user.uid,
                      hours_before_mov:parseInt(balanceHorasActual.available_hours),
                      hours_after_mov:parseInt(balanceHorasActual.available_hours) + (parseInt(pto.total_hours) - parseInt(2)),
                      hours_mov: parseInt(pto.total_hours),
                      carry_over_before_mov:parseInt(userInfo.hours.carry_over),
                      carry_over_after_mov:parseInt(balanceHorasActual.carry_over),
                      carry_over_sick_time_before:parseInt(balanceHorasActual.carry_over_sick_time),
                      carry_over_sick_time_after: parseInt(balanceHorasActual.carry_over_sick_time),
                      observations: 'Delete PTO '+ absenceText,
                      type_mov: 'Delete PTO',
                      date_mov: moment().format('YYYY-MM-DD'),
                      mov_increase_date: moment(balanceHorasActual.increase_date).format('YYYY-MM-DD'),
                      mov_yearly_increase_date:moment(balanceHorasActual.year_increase_date).format('YYYY-MM-DD'),
                      increase_date_before_mov:moment(balanceHorasActual.increase_date).format('YYYY-MM-DD'),
                      yearly_increase_date_before_mov:moment(balanceHorasActual.year_increase_date).format('YYYY-MM-DD')
                    }
                    axiosHr.post('/hour-movement-log', data)
                    .then(respuesta => {
                        console.log(respuesta)
                    })
                    .catch(error => {
                    console.log(error)
                    });
                    
                    break;

                    case 'M':
                      balanceHoras({
                        available_hours: parseInt(balanceHorasActual.available_hours) + (parseInt(pto.total_hours) - parseInt(24)),
                        requested_hours: parseInt(balanceHorasActual.requested_hours) - parseInt(pto.total_hours),
                        total_requests: balanceHorasActual.total_requests - 1,
                        carry_over: parseInt(balanceHorasActual.carry_over),
                        sick_hours_limit: parseInt(balanceHorasActual.sick_days_limit),
                        carry_over_sick_time: parseInt(balanceHorasActual.carry_over_sick_time),
                        uid : pto.user_id,
                    })

                    data = {
                      uid : pto.user.uid,
                      hours_before_mov:parseInt(balanceHorasActual.available_hours),
                      hours_after_mov:parseInt(balanceHorasActual.available_hours) + (parseInt(pto.total_hours) - parseInt(24)),
                      hours_mov: parseInt(pto.total_hours),
                      carry_over_before_mov:parseInt(userInfo.hours.carry_over),
                      carry_over_after_mov:parseInt(balanceHorasActual.carry_over),
                      carry_over_sick_time_before:parseInt(balanceHorasActual.carry_over_sick_time),
                      carry_over_sick_time_after: parseInt(balanceHorasActual.carry_over_sick_time),
                      observations: 'Delete PTO '+ absenceText,
                      type_mov: 'Delete PTO',
                      date_mov: moment().format('YYYY-MM-DD'),
                      mov_increase_date: moment(balanceHorasActual.increase_date).format('YYYY-MM-DD'),
                      mov_yearly_increase_date:moment(balanceHorasActual.year_increase_date).format('YYYY-MM-DD'),
                      increase_date_before_mov:moment(balanceHorasActual.increase_date).format('YYYY-MM-DD'),
                      yearly_increase_date_before_mov:moment(balanceHorasActual.year_increase_date).format('YYYY-MM-DD')
                    }
                    axiosHr.post('/hour-movement-log', data)
                    .then(respuesta => {
                        console.log(respuesta)
                    })
                    .catch(error => {
                    console.log(error)
                    });
                    
                    break;
                
                  default:
                    break;
                }
              }else {
                
  
                if(pto.absence_code === 'S') {
                    balanceHoras({
                      available_hours: parseInt(balanceHorasActual.available_hours),
                      requested_hours: parseInt(balanceHorasActual.requested_hours) - parseInt(pto.total_hours),
                      total_requests: balanceHorasActual.total_requests - 1,
                      carry_over: parseInt(balanceHorasActual.carry_over + parseInt(pto.carry_over_usage)),
                      sick_hours_limit: parseInt(balanceHorasActual.sick_days_limit) + parseInt(pto.sick_hours_usage),
                      carry_over_sick_time: parseInt(balanceHorasActual.carry_over_sick_time) + parseInt(pto.carry_over_sick_hours_usage),
                      uid : pto.user_id
                    });
                    let data = {
                      uid : pto.user_id,
                      hours_before_mov:parseInt(balanceHorasActual.sick_days_limit),
                      hours_after_mov: parseInt(balanceHorasActual.carry_over_sick_time),
                      hours_mov: parseInt(pto.totalHours),
                      carry_over_before_mov:parseInt(balanceHorasActual.carry_over),
                      carry_over_after_mov: parseInt(balanceHorasActual.carry_over + parseInt(pto.carry_over_usage)),
                      carry_over_sick_time_before:parseInt(balanceHorasActual.carry_over_sick_time),
                      carry_over_sick_time_after: parseInt(balanceHorasActual.carry_over_sick_time) + parseInt(pto.carry_over_sick_hours_usage),
                      observations: 'New PTO ' + absenceText,
                      type_mov: 'New PTO',
                      date_mov: moment().format('YYYY-MM-DD'),
                      mov_increase_date: moment(balanceHorasActual.increase_date).format('YYYY-MM-DD'),
                      mov_yearly_increase_date:moment(balanceHorasActual.year_increase_date).format('YYYY-MM-DD'),
                      increase_date_before_mov:moment(balanceHorasActual.increase_date).format('YYYY-MM-DD'),
                      yearly_increase_date_before_mov:moment(balanceHorasActual.year_increase_date).format('YYYY-MM-DD')
                  }
                  axiosHr.post('/hour-movement-log', data)
                  .then(respuesta => {
                      console.log(respuesta)
                  })
                  .catch(error => {
                  console.log(error)
                  });
                }else {
                  balanceHoras({
                    available_hours: parseInt(balanceHorasActual.available_hours) + (parseInt(pto.total_hours) - parseInt(pto.carry_over_usage) - parseInt(pto.sick_hours_usage) - parseInt(pto.carry_over_sick_hours_usage)),
                    requested_hours: parseInt(balanceHorasActual.requested_hours) - parseInt(pto.total_hours),
                    total_requests: balanceHorasActual.total_requests - 1,
                    carry_over: parseInt(balanceHorasActual.carry_over + parseInt(pto.carry_over_usage)),
                    sick_hours_limit: parseInt(balanceHorasActual.sick_days_limit) + parseInt(pto.sick_hours_usage),
                    carry_over_sick_time: parseInt(balanceHorasActual.carry_over_sick_time) + parseInt(pto.carry_over_sick_hours_usage),
                    uid : pto.user_id
                  });
                  let data = {
                    uid : pto.user_id,
                    hours_before_mov:parseInt(balanceHorasActual.available_hours),
                    hours_after_mov:parseInt(balanceHorasActual.available_hours) + (parseInt(pto.total_hours) - parseInt(pto.carry_over_usage) - parseInt(pto.sick_hours_usage)),
                    hours_mov: parseInt(pto.total_hours),
                    carry_over_before_mov:parseInt(balanceHorasActual.carry_over),
                    carry_over_after_mov: parseInt(balanceHorasActual.carry_over + parseInt(pto.carry_over_usage)),
                    carry_over_sick_time_before:parseInt(balanceHorasActual.carry_over_sick_time),
                    carry_over_sick_time_after: parseInt(balanceHorasActual.carry_over_sick_time) + parseInt(pto.carry_over_sick_hours_usage),
                    observations: 'Supervisor Denies PTO ' + absenceText,
                    type_mov: 'Denies PTO',
                    date_mov: moment().format('YYYY-MM-DD'),
                    mov_increase_date: moment(balanceHorasActual.increase_date).format('YYYY-MM-DD'),
                    mov_yearly_increase_date:moment(balanceHorasActual.year_increase_date).format('YYYY-MM-DD'),
                    increase_date_before_mov:moment(balanceHorasActual.increase_date).format('YYYY-MM-DD'),
                    yearly_increase_date_before_mov:moment(balanceHorasActual.year_increase_date).format('YYYY-MM-DD')
                  }
                  axiosHr.post('/hour-movement-log', data)
                  .then(respuesta => {
                      console.log(respuesta)
                  })
                  .catch(error => {
                  console.log(error)
                  });
                }

              }

             

              
              

            dispatch(denyPtoAction(pto) );

            let company = balanceHorasActual.company
       
            // ask user confirmation
            const ptoInfo = {
              pto,
              company
            }

            clienteAxiosHR.post('/pto-email-deny', ptoInfo)
                .then(respuesta => {
                    console.log(respuesta);
                    Swal.fire(
                      'Success',
                      'The Time off Request denied notification has been send successfully.',
                      'warning'
                    )
                })
                .catch(error => {
                    console.log(error);
                    Swal.fire(
                        'Failed',
                        'An error was occurred by sending your request.',
                        'warning'
                      )
                })

            setTimeout(() => {
              loadPtoList();
            }, 4000);
            }
          })
          
    }

    

    return (
        <tr>
            <td>{pto.employee_name}</td>
            <td>{pto.department}</td>
            <td>{pto.status}</td>
            <td>{
                createdAt
                }
            </td>
            <td>{ moment.utc(pto.day_beginning).format('MM-DD-YYYY')}</td>
            <td>{moment.utc(pto.day_end).format('MM-DD-YYYY')}</td>
            <td>{absenceText}</td>
            <td>{pto.total_hours}</td>
            <td className="acciones">
                <Link
                    to={`/pto/view/${pto.id}`}
                    className="btn btn-primary m-1"
                >
                    Details
                </Link>

                <button
                    className="btn btn-success m-1"
                    onClick = {() => approvePto(pto)}
                >
                    Approve
                </button>

                <button
                    className="btn btn-danger"
                    onClick = {() => denyPto(pto )}
                >
                    Deny
                </button>
            </td>
        </tr>
    );
};

export default PtoSupervisor;