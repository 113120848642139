import React, {useEffect, useState} from 'react';
import {obtenerDepartamentos} from '../actions/manageActions';
import { useDispatch, useSelector, useStore } from 'react-redux';

import Spinner from './helpers/Spinner';
import ListDepartments from './ListDepartments';

const Departments = () => {
    const dispatch = useDispatch();

    const departments = useSelector(state=>state.manager.departments);


    useEffect(() => {
        const loadDepartments = ()=> dispatch(obtenerDepartamentos());
        loadDepartments();
    }, [])
    
    return (
        departments !== undefined && departments !== ''
         ?
        <React.Fragment>
        
         
        <div className="d-flex justify-content-center align-items-center manager-title">
            <h2 className="text-center my-5 title-custom">Departments</h2>
            <table className="table table-striped table-to-card-employees">
                <thead className="bg-primary table-dark">
                        <tr>
                            <th scope="col">Name</th>
                            <th scope="col"> Director Mail</th>
                            <th scope="col"> Report to</th>
                            <th scope="col">Responsable</th>
                            <th scope="col">Color</th>
                            <th scope="col">Active</th>
                            <th scope="col" className="col-actions">Actions</th>
                        </tr>   
                </thead>
                <tbody>{
                departments.map(department =>(
                                <ListDepartments
                                key={department.id}
                                department = {department}
                                />
                                
                                
                            ))
                }
                </tbody>
            </table>
        </div>
    </React.Fragment>
    :
    <Spinner />
    
    );
};

export default Departments;