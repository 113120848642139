import React, {Fragment, useEffect} from 'react';
import HistoryEmployee from './HistoryEmployee';
import moment from 'moment';

import { useDispatch, useSelector } from 'react-redux';
import {Link} from 'react-router-dom';

import {obtenerHistorico} from '../actions/manageActions';
import ReactExport from "react-export-excel";


const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const HistoryUsers = ({match}) => {

    const { uid } = match.params;

    const dispatch = useDispatch();

    // obtener el ID a editar
    

    const usersHistory = useSelector(state=>state.manager.history);
    const usersHour = useSelector(state=>state.manager.employees);

    // get user general data
    const employeeData = usersHour.filter(user => user.uid == uid);

    let observations;

    useEffect(()=> {
         const loadHistory = ()=>dispatch(obtenerHistorico(uid));
         loadHistory();
    },[])
    return (
        <Fragment>
            <div className="card mb-3 avatar mt-5" >
                    <div className="row no-gutters">
                        <div className="col-sm-12 col-md-4 card-avatar-container">
                            {/* Change avatar dinamically */}
                            <img src={employeeData[0].avatar} className="card-img" alt="avatar" />
                        </div>
                        <div className="col-md-8">
                            <div className="card-body ">
    <h4 className="card-title">{employeeData[0].employee_name}{' '}{employeeData[0].employee_lastname}</h4>
                                <p className="home-paragraph">{employeeData[0].department}</p>
                                {     
                                    <p className="general-summary text-muted home-paragraph">
                                        <span className="font-weight-bold">Available PTO Hours:</span> {employeeData[0].available_hours} hours
                                    </p>
                                }
                                <p className="general-summary text-muted home-paragraph"><span className="font-weight-bold">Available Sick Hours:</span> {employeeData[0].sick_days_limit} hours</p>
                                <p className="general-summary text-muted home-paragraph"><span className="font-weight-bold">Available Carry Over Hours:</span> {employeeData[0].carry_over} hours</p>
                                {
                                employeeData[0].territory === 'California'
                                 ?
                                 <p className="general-summary text-muted home-paragraph"><span className="font-weight-bold">Available Carry Over Sick Hours:</span> {employeeData[0].carry_over_sick_time} hours</p>
                                 :
                                  null
                                }

<p className="general-summary text-muted home-paragraph"><span className="font-weight-bold">Total Annual Days:</span> {(employeeData[0].available_hours + employeeData[0].carry_over + employeeData[0].sick_days_limit + employeeData[0].carry_over_sick_time) / 8} days</p>
                               
                                {/* <p className="general-summary text-muted">Joined the company since: {moment().to(moment(userInfo.userInfo.hireDate))}</p> */}
                                <p className="general-summary text-muted home-paragraph since-paragraph">Joined the company since: {moment(employeeData[0].hire_date).format('MMM-YYYY')}</p>

                                {
                                (Math.floor((moment([employeeData[0].hire_date, 0]).diff(moment(), 'year') * -1)/(3)) * 8) >= 41
                                    ?
                                        <p className="general-summary text-muted home-paragraph ">(40 hours  for seniority in company already included)</p>
                                    :
                                    <p className="general-summary text-muted home-paragraph ">
                                    ({(Math.floor((moment([employeeData[0].hire_date, 0]).diff(moment(), 'year') * -1)/(3)) * 8)} hours  for seniority in company already included)</p>
                                }
                                
                                {employeeData[0].carry_over > 0
                                ?
                                // <div className="alert alert-success carryOverAlert" role="alert">
                                //     Your Carry Over expires on: 03-31-2020
                                // </div>
                                null
                                :
                                null
                                }

                                
                            </div>

                            <ExcelFile element={<button className="btn btn-success  ml-3">History Employee Report</button>}>
                    <ExcelSheet  data={usersHistory} name={employeeData[0].employee_name+' '+employeeData[0].employee_lastname}>
                                <ExcelColumn label="#" value="id"/>
                                <ExcelColumn label="Date" value={(col) => moment(col.date_mov).format('YYYY-MM-DD')}/>
                                <ExcelColumn label="Available Hours Before Request" value="hours_before_mov"/>
                                <ExcelColumn label="Avalialble Hours After Request" value="hours_after_mov"/>
                                <ExcelColumn label="Carry Over Before Request" value="carry_over_before_mov"/>
                                <ExcelColumn label="Carry Over After Request" value="carry_over_after_mov"/>
                                <ExcelColumn label="Hours" value={(col) => {
                                    if(col.hours_mov < 0) {
                                        return parseInt(col.hours_mov) * -1
                                    }else {
                                        return col.hours_mov
                                    }
                                }}/>
                                <ExcelColumn label="Type" value="type_mov"/>
                                <ExcelColumn label="Monthly Increment Date Before Request" value={(col) => moment(col.mov_increase_date).format('YYYY-MM-DD')}/>
                                <ExcelColumn label="Monthly Increment Date After Request" value={(col) => moment(col.increase_date_before_mov).format('YYYY-MM-DD')}/>
                                <ExcelColumn label="Seniority Increment Before Request" value={(col) => moment(col.mov_yearly_increase_date).format('YYYY-MM-DD')}/>
                                <ExcelColumn label="Seniority Increment After Request" value={(col) => moment(col.yearly_increase_date_before_mov).format('YYYY-MM-DD')}/>
                                {/* <ExcelColumn label="Observations" value="observations"/> */}
                                <ExcelColumn label="Observations" value={ function(col) { 
                                    if(col.type_mov === 'Edit User') {
                                        observations = col.observations.split(",")
                                    return 'Available Hours: from '+ observations[0] + ' to ' + observations[1] + ', Carry Over: from '+ observations[2] + ' to ' + observations[3] + ', Sick Hours: from '+ observations[4] +' to ' + observations[5] + 'Carry Over Sick Hours: from ' + observations[6] + ' to ' + observations[7];
                                    }else {
                                        return col.observations;
                                    }
                                    
                                }
                                    }/>
                             </ExcelSheet>
                </ExcelFile>
                        </div>
                        {/* End column */}
                    </div>
                </div>
                {/* End Card User */}
                
            <table className="table table-striped mt-5 table-to-card-historic">
                     <thead className="bg-primary table-dark">
                         <tr>
                            <th scope="col">#</th>
                             <th className="extended" scope="col">Date</th>
                             <th scope="col">Available Hours Before Request</th>
                             <th scope="col">Avalialble Hours After Request</th>
                             <th scope="col">Carry Over Before Request</th>
                             <th scope="col">Carry Over After Request</th>
                             <th scope="col">Carry Over Sick Time Before Request</th>
                             <th scope="col">Carry Over Sick Time After Request</th>
                             <th scope="col">Hours</th>
                             <th className="extended" scope="col">Type</th>
                             {/* <th className="extended" scope="col">Monthly Increment Date Before Request</th>
                             <th className="extended" scope="col">Monthly Increment Date After Request</th>
                             <th className="extended" scope="col">Seniority Increment Before Request</th>
                             <th className="extended" scope="col">Seniority Increment After Request</th> */}
                            
                             <th scope="col" className="col-actions">Observations</th>
                         </tr>   
                     </thead>
                     <tbody>
                         {
                         usersHistory.map(historyEmployee =>(
                             <HistoryEmployee
                             key={historyEmployee.id}
                             historyEmployee = {historyEmployee}
                             />
                            
                         ))
                         }
                     </tbody>
                 </table>
        </Fragment>
    );
};

export default HistoryUsers;