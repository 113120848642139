import {
    AGREGAR_TIME_OFF_REQUEST,
    AGREGAR_TIME_OFF_REQUEST_EXITO,
    AGREGAR_TIME_OFF_REQUEST_ERROR,
    COMENZAR_DESCARGA_PTOS,
    COMENZAR_DESCARGA_PTOS_EXITO,
    COMENZAR_DESCARGA_PTOS_ERROR,
    OBTENER_PTO_ELIMINAR,
    PTO_ELIMINADO_EXITO,
    PTO_ELIMINADO_ERROR,
    OBTENER_DETALLE_PTO,
    OBTENER_DETALLE_PTO_EXITO,
    OBTENER_DETALLE_PTO_ERROR,
    COMENZAR_DESCARGA_PTOS_DEPARTAMENTO,
    COMENZAR_DESCARGA_PTOS_DEPARTAMENTO_EXITO,
    COMENZAR_DESCARGA_PTOS_DEPARTAMENTO_ERROR,
    COMENZAR_APROBACION_PTO,
    COMENZAR_APROBACION_PTO_EXITO,
    COMENZAR_APROBACION_PTO_ERROR,
    COMENZAR_DENEGAR_PTO,
    COMENZAR_DENEGAR_PTO_EXITO,
    COMENZAR_DENEGAR_PTO_ERROR,
    COMENZAR_DESCARGA_PTOS_SUPERVISOR,
    COMENZAR_DESCARGA_PTOS_SUPERVISOR_EXITO,
    COMENZAR_DESCARGA_PTOS_SUPERVISOR_ERROR,
    COMENZAR_EDITAR_PTO,
    COMENZAR_EDITAR_PTO_EXITO,
    COMENZAR_EDITAR_PTO_ERROR,
    COMENZAR_DESCARGA_PTOS_SUPERVISOR_DIRECTOR,
    COMENZAR_DESCARGA_PTOS_SUPERVISOR_DIRECTOR_EXITO,
    COMENZAR_DESCARGA_PTOS_SUPERVISOR_DIRECTOR_ERROR,
} from '../types';

import clienteAxiosHR from '../config/axiosHR';


// Create new PTO
export function createNewPtoAction(pto) {
    return async (dispatch) => {
        dispatch( newPto());
        // Insertar en la API
        await clienteAxiosHR.post('/create-pto', pto)
        .then(respuesta => {
            //console.log(respuesta.data.data.insertId)
            pto["id"] = respuesta.data.data.insertId;
            //console.log(pto);
            //Si se inserta correctamente
            dispatch(newPtoSuccess(pto));

            dispatch(getPTOListAction());
        })
        .catch(error => {
            // console.log(error);

            // Si hay un error
            dispatch(newPtoError());
        })
    
    }
}


export const newPto = () => ({
    type: AGREGAR_TIME_OFF_REQUEST
});

export const newPtoSuccess = (pto) => ({
    type: AGREGAR_TIME_OFF_REQUEST_EXITO,
    payload: pto
});

export const newPtoError = () => ({
    type: AGREGAR_TIME_OFF_REQUEST_ERROR
});

// Create new PTO
export function editPtoAction(pto) {
    return async (dispatch) => {
        dispatch( editPto());
        // Insertar en la API
        await clienteAxiosHR.put('/update-pto', pto)
        .then(respuesta => {

            console.log(pto);
            //Si se actualiza correctamente
            dispatch(editPtoSuccess(pto));

            dispatch(getPTOListAction());
        })
        .catch(error => {
            // console.log(error);

            // Si hay un error
            dispatch(editPtoError());
        })
    
    }
}


export const editPto = () => ({
    type: COMENZAR_EDITAR_PTO
});

export const editPtoSuccess = (pto) => ({
    type: COMENZAR_EDITAR_PTO_EXITO,
    payload: pto
});

export const editPtoError = () => ({
    type: COMENZAR_EDITAR_PTO_ERROR
});

// Retrieve PTO List

export function getPTOListAction(uid) {
    return async (dispatch) => {
        dispatch(getPtoList());

        //Consultar la API
        await clienteAxiosHR.get(`/pto-list/${uid}`)
        .then(respuesta => {
            dispatch(getPtoListSuccess(respuesta.data.data));
        })
        .catch(error => {
            //console.log(error);
            dispatch(getPtoListError());
        })
    }
}

export const getPtoList = () => ({
    type: COMENZAR_DESCARGA_PTOS
});

export const getPtoListSuccess = (ptoList) => ({
    type: COMENZAR_DESCARGA_PTOS_EXITO,
    payload: ptoList
});

export const getPtoListError = () => ({
    type: COMENZAR_DESCARGA_PTOS_ERROR
});

// Retrieve PTO LIST For Department

export function getPtoListDepartment (department) {
    return async (dispatch) => {
        dispatch(startGetPtoDepartment());

        //Consultar la API
        await clienteAxiosHR.get(`/pto-list-department/${department}`)
        .then((respuesta) =>{
            const department = respuesta.data.data; 
            dispatch(getPtoListDepartmentSuccess(department));
        })
        .catch((error)=> {
            //console.log(error)
            dispatch(getPtoListDepartmentError());
        })
    }
}

export const startGetPtoDepartment = () => ({
    type: COMENZAR_DESCARGA_PTOS_DEPARTAMENTO
});

export const getPtoListDepartmentSuccess = (ptoListDepartment) => ({
    type: COMENZAR_DESCARGA_PTOS_DEPARTAMENTO_EXITO,
    payload: ptoListDepartment
});

export const getPtoListDepartmentError = () => ({
    type: COMENZAR_DESCARGA_PTOS_DEPARTAMENTO_ERROR
});

// Retrieve PTO LIST For Department sUPERVISORT

export function getPtoListDepartmentSupervisor (department, uid, email) {
    return async (dispatch) => {
        dispatch(startGetPtoSupervisorDepartment());

        //Consultar la API
        const data = {
            department: department,
            uid: uid,
            email:email
        } 

        await clienteAxiosHR.post(`/pto-list-supervisor`, data)
        .then((respuesta) =>{
            const department = respuesta.data.data; 
            dispatch(getPtoListDepartmentSupervisorSuccess(department));
        })
        .catch((error)=> {
            //console.log(error)
            dispatch(getPtoListDepartmentSupervisorError());
        })
    }
}

export const startGetPtoSupervisorDepartment = () => ({
    type: COMENZAR_DESCARGA_PTOS_SUPERVISOR
});

export const getPtoListDepartmentSupervisorSuccess = (ptoListDepartment) => ({
    type: COMENZAR_DESCARGA_PTOS_SUPERVISOR_EXITO,
    payload: ptoListDepartment
});

export const getPtoListDepartmentSupervisorError = () => ({
    type: COMENZAR_DESCARGA_PTOS_SUPERVISOR_ERROR
});

// Retrieve PTO LIST For Supervisr - Directors

export function getPtoListSupervisorDirector (uid) {
    return async (dispatch) => {
        dispatch(startGetPtoSupervisorDirector());

        //Consultar la API
        const data = {
            uid: uid
        } 

        await clienteAxiosHR.post(`/pto-list-supervisor-director`, data)
        .then((respuesta) =>{
            const listDirector = respuesta.data.data; 
            dispatch(getPtoListSupervisorDirectorSuccess(listDirector));
        })
        .catch((error)=> {
            //console.log(error)
            dispatch(getPtoListSupervisorDirectorError());
        })
    }
}

export const startGetPtoSupervisorDirector = () => ({
    type: COMENZAR_DESCARGA_PTOS_SUPERVISOR_DIRECTOR
});

export const getPtoListSupervisorDirectorSuccess = (ptoList) => ({
    type: COMENZAR_DESCARGA_PTOS_SUPERVISOR_DIRECTOR_EXITO,
    payload: ptoList
});

export const getPtoListSupervisorDirectorError = () => ({
    type: COMENZAR_DESCARGA_PTOS_SUPERVISOR_DIRECTOR_ERROR
});

// Delete a pto

export function deletePtoAction(id) {
    return (dispatch) => {
        dispatch(startPtoDelete())

        //Delete from databse
        clienteAxiosHR.delete('/delete-pto/', {data: {
                id : id
            }
        })
        .then(respuesta => {
           // console.log(respuesta);
            dispatch(deletePtoSuccess(id))
        })
        .catch(error => {
           // console.log(error);
            dispatch(deletePtoError())
        })
    }
}

export const startPtoDelete = () => ({
    type: OBTENER_PTO_ELIMINAR
});

export const deletePtoSuccess = (id) => ({
    type: PTO_ELIMINADO_EXITO,
    payload: id
});

export const deletePtoError = () => ({
    type: PTO_ELIMINADO_ERROR
})

// Mostrar detalle PTO

export function getPtoDetail (id) {
    return async (dispatch) => {
        dispatch(startGetPtoDetail());

        //Consultar la API
        await clienteAxiosHR.get(`/pto/view/${id}`)
        .then((respuesta) =>{
            const pto = respuesta.data.data; 
            dispatch(getPtoDetailSuccess(pto));
        })
        .catch((error)=> {
            //console.log(error)
            dispatch(getPtoDetailError());
        })
    }
}

export const startGetPtoDetail = () =>({
    type: OBTENER_DETALLE_PTO
})

export const getPtoDetailSuccess = (pto) =>({
    type: OBTENER_DETALLE_PTO_EXITO,
    payload: pto
})

export const getPtoDetailError = () =>({
    type: OBTENER_DETALLE_PTO_ERROR
})

// Approve a pto

export function approvePtoAction(id) {
    return (dispatch) => {
        dispatch(startPtoApproval())
        //console.log(id);

        //Update from databse
        clienteAxiosHR.put('/approve-pto', {data: {
                id : id
            }
        })
        .then(respuesta => {
           // console.log(respuesta);
            dispatch(startPtoApprovalSuccess(id))
        })
        .catch(error => {
           // console.log(error);
            dispatch(startPtoApprovalError())
        })
    }
}

export const startPtoApproval = () => ({
    type: COMENZAR_APROBACION_PTO
});

export const startPtoApprovalSuccess = (id) => ({
    type: COMENZAR_APROBACION_PTO_EXITO,
    payload: id
});

export const startPtoApprovalError = () => ({
    type: COMENZAR_APROBACION_PTO_ERROR
});

// dENEGAR a pto

export function denyPtoAction(pto) {
    return (dispatch) => {
        dispatch(startPtoDenegation())

        //Update from databse
        clienteAxiosHR.put('/deny-pto', {data: {
                id : pto.id,
                reasonDeny: pto.reasonDeny
            }
        })
        .then(respuesta => {
           // console.log(respuesta);
            dispatch(startPtoDenegationSuccess(pto.id))
        })
        .catch(error => {
           // console.log(error);
            dispatch(startPtoDenegationError())
        })
    }
}

export const startPtoDenegation = () => ({
    type: COMENZAR_DENEGAR_PTO
});

export const startPtoDenegationSuccess = (id) => ({
    type: COMENZAR_DENEGAR_PTO_EXITO,
    payload: id
});

export const startPtoDenegationError = () => ({
    type: COMENZAR_DENEGAR_PTO_ERROR
});