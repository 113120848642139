import React, {useEffect, useState, Fragment, useRef} from 'react';
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import moment from 'moment';

import axiosHR from '../config/axiosHR';

import { useDispatch } from 'react-redux';
import {useSelector} from 'react-redux';
import {obtenerDepartamentos} from '../actions/manageActions';

// import PtoCalendarExpanded from './PtoCalendarExpanded';
// import {Link} from 'react-router-dom';

const PtoYearCalendar = ({history}) => {

    const dispatch = useDispatch();

    const[ptoEvents, savePtoEvents] = useState([]);
    const [year, setYear] = useState(moment().format('YYYY'))
    let PtoEventsArray = [];

    const user = useSelector(state=>state.userInfo.userInfo.username);
    const manager = useSelector(state=>state.userInfo.userInfo.manager);
    const department = useSelector(state=>state.userInfo.userInfo.department);
    const userInfo = useSelector(state=> state.userInfo.userInfo)

    let company = userInfo.company

    let januaryRef = React.createRef()
    let februaryRef = React.createRef()
    let marchRef = React.createRef()
    let aprilRef = React.createRef()
    let mayRef = React.createRef()
    let juneRef = React.createRef()
    let julyRef = React.createRef()
    let augustRef = React.createRef()
    let septemberRef = React.createRef()
    let octoberRef = React.createRef()
    let novemberRef = React.createRef()
    let decemberRef = React.createRef()

    // const departments = {
    //     'Graphic Design & Digital Communication' : 'blue',
    //     'Human Resources' : 'green'
    // };

    // function saveEvents () {
        
    // }
    // obtener departamentos
    const departments = useSelector(state=>state.manager.departments);

    const filterElementsByDepartment  = (array, query) => {
        return array.filter(function(el) {
            return el.department.toLowerCase().indexOf(query.toLowerCase()) > -1;
        })
        }

    let departmentSupervisorFilter = filterElementsByDepartment(departments, department);
    let director_of = JSON.parse(departmentSupervisorFilter[0].director_of);
  
    const previousYear = () => {

        //console.log(moment(year+'-01-01').subtract(1, 'years'))
        console.log(year);
        setYear(moment(year+'-01-01').subtract(1, 'years').format('YYYY'));

        januaryRef.current.getApi().prevYear();
        februaryRef.current.getApi().prevYear();
        marchRef.current.getApi().prevYear();
        aprilRef.current.getApi().prevYear();
        mayRef.current.getApi().prevYear();
        juneRef.current.getApi().prevYear();
        julyRef.current.getApi().prevYear();
        augustRef.current.getApi().prevYear();
        septemberRef.current.getApi().prevYear();
        octoberRef.current.getApi().prevYear();
        novemberRef.current.getApi().prevYear();
        decemberRef.current.getApi().prevYear();
    }

    const nextYear = () => {

        //console.log(moment(year+'-01-01').subtract(1, 'years'))
        console.log(year);
        setYear(moment(year+'-01-01').add(1, 'years').format('YYYY'));

        januaryRef.current.getApi().nextYear();
        februaryRef.current.getApi().nextYear();
        marchRef.current.getApi().nextYear();
        aprilRef.current.getApi().nextYear();
        mayRef.current.getApi().nextYear();
        juneRef.current.getApi().nextYear();
        julyRef.current.getApi().nextYear();
        augustRef.current.getApi().nextYear();
        septemberRef.current.getApi().nextYear();
        octoberRef.current.getApi().nextYear();
        novemberRef.current.getApi().nextYear();
        decemberRef.current.getApi().nextYear();
    }
    
    

    useEffect(()=> {
        if(user === 'salvador.escalona' || user === 'jose.mogollon') {
            axiosHR.get('/pto-list-all/')
            .then(respuesta => {
             //   console.log(respuesta.data.data[0].day_beginning)
                respuesta.data.data.map((pto, index) => 
                pto.status !== 'Denied'
                ?
                    PtoEventsArray.push({ title: pto.employee_name + ' ' + pto.status, start: pto.day_beginning, end: pto.day_end, url:`/employee/view/${pto.user_id}`, color:pto.color})
                :
                    null
                
                )
                savePtoEvents(PtoEventsArray);
            })
            .catch(error => {
                console.log(error);
            })
        } else if (manager === true || manager === 1){

            axiosHR.get(`/pto-list-all-manager/${company}`)
            .then(respuesta => {
                console.log(respuesta.data)
                respuesta.data.data.map((pto, index) => 
                pto.status !== 'Denied'
                ?
                    PtoEventsArray.push({ title: pto.employee_name + ' ' + pto.status, start: pto.day_beginning, end: pto.day_end, url:`/employee/view/${pto.user_id}`, color:pto.color})
                :
                    null
                
                )
                savePtoEvents(PtoEventsArray);
            })
            .catch(error => {
                console.log(error);
            })
        }else {

            director_of.forEach(element => {
                
            
                
                axiosHR.get(`/pto-list-all-department/${element.department}`)
                .then(respuesta => {
                    console.log(respuesta);
                    respuesta.data.data.map((pto, index) => 
                    pto.status !== 'Denied'
                    ?
                        PtoEventsArray.push({ title: pto.employee_name + ' ' + pto.status, start: pto.day_beginning, end: pto.day_end, url:`/employee/view/${pto.user_id}`, color:pto.color})
                    :
                        null
                    
                    )

                    savePtoEvents(ptoEvents.concat(PtoEventsArray));
                   
                })
                .catch(error => {
                    console.log(error);
                })

               
             });
             
             
        }

        const loadDepartments = ()=> dispatch(obtenerDepartamentos());
        loadDepartments();

        console.log(ptoEvents)
    }, [])

    // let ejemplo = [
    //     {
    //         title: 'PTO Julio Castro', 
    //         date:'2019-10-22'
    //     },
    //     {
    //         title: 'PTO Vane Medina', 
    //         start: '2019-10-23', 
    //         end: '2019-10-23',
    //         extendedProps: {
    //             department: 'Graphic Design & Digital Communication'
    //         },
    //         description: 'PTO Request description'
    //     }
    //]    

    return (
        <Fragment>
            <div className="row mt-5 ml-5 mr-5">
                <div className="col-sm-12">
                    <div className ="card p-1">
                        <div className="card-title text-center">
                            <h3>{'Company Departments'}</h3>
                            
                        </div>
                        <div className="card-body card-pallete">
                            <div className="pallete-container">
                                
                                {departments.map((department, index) =>(
                                    <div key={index} className="pallette-departments">
                                        <div className="color-indicator" style={{backgroundColor: department.color}}></div><span className="text-muted">{department.department}</span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row m-5">
                <h2>Current Year: {`${year}`}</h2>
                <div className='col-md-12 d-flex justify-content-between mt-3 mb-3'>
                   
                    <button className='btn btn-primary' type='button' onClick={()=> previousYear()}>Previous Year</button>
                    <button className='btn btn-primary' type='button' onClick={()=> nextYear()}>Next Year</button>
                </div>
                <div className="col-sm-1 col-md-4">
               
                <FullCalendar defaultView="dayGridMonth" plugins={[ dayGridPlugin ]} 
                            events={ptoEvents}
                            defaultDate={year+'-01-01'}
                            ref= {januaryRef}
                            customButtons= {
                                {
                                    openMonth: {
                                        text: 'Expand',
                                        click: () => {
                                         var   month = '2022-01-01'
                                            return(
                                                history.push(`/pto/calendar/${month}`)
                                            )
                                        }
                                    }
                                        
                                    }
                                }
                            
                            header={{left: 'title',
                                     center: '',
                                     right: 'openMonth'  
                                    }}
                            height= {.5}
                            aspectRatio = {1}
                            timeZone= {'America/Chicago'}  
                />
                
                    
                </div>
                <div className="col-sm-1 col-md-4">
                
                <FullCalendar defaultView="dayGridMonth" plugins={[ dayGridPlugin ]} 
                            events={ptoEvents}
                            defaultDate={year+'-02-01'}
                            ref= {februaryRef}
                            customButtons= {
                                {
                                    openMonth: {
                                        text: 'Expand',
                                        click: () => {
                                         var   month = year+'-02-01'
                                            return(
                                                history.push(`/pto/calendar/${month}`)
                                            )
                                        }
                                    }
                                }
                            }
                            header={{left: 'title',
                                     center: '',
                                     right: 'openMonth'  
                                    }}
                            height= {.5}
                            aspectRatio = {1}
                            timeZone= {'America/Chicago'}
                />
                
                    
                </div>
                <div className="col-sm-1 col-md-4">
                
                <FullCalendar defaultView="dayGridMonth" plugins={[ dayGridPlugin ]} 
                            events={ptoEvents}
                            defaultDate={year+'-03-01'}
                            ref= {marchRef}
                            customButtons= {
                                {
                                    openMonth: {
                                        text: 'Expand',
                                        click: () => {
                                         var   month = year+'-03-01'
                                            return(
                                                history.push(`/pto/calendar/${month}`)
                                            )
                                        }
                                    }
                                }
                            }
                            header={{left: 'title',
                                     center: '',
                                     right: 'openMonth'  
                                    }}
                            height= {.5}
                            aspectRatio = {1}
                            timeZone= {'America/Chicago'}
                />
                
                    
                </div>
                
                
            </div>
            
            <div className="row m-5">
                <div className="col-sm-1 col-md-4">
                
                <FullCalendar defaultView="dayGridMonth" plugins={[ dayGridPlugin ]} 
                            events={ptoEvents}
                            defaultDate={year+'-04-01'}
                            ref= {aprilRef}
                            customButtons= {
                                {
                                    openMonth: {
                                        text: 'Expand',
                                        click: () => {
                                         var   month = year+'-04-01'
                                            return(
                                                history.push(`/pto/calendar/${month}`)
                                            )
                                        }
                                    }
                                }
                            }
                            header={{left: 'title',
                                     center: '',
                                     right: 'openMonth'  
                                    }}
                            height= {.5}
                            aspectRatio = {1}
                            timeZone= {'America/Chicago'}
                />
                
                    
                </div>
                <div className="col-sm-1 col-md-4">
                
                <FullCalendar defaultView="dayGridMonth" plugins={[ dayGridPlugin ]} 
                            events={ptoEvents}
                            defaultDate={year+'-05-01'}
                            ref= {mayRef}
                            customButtons= {
                                {
                                    openMonth: {
                                        text: 'Expand',
                                        click: () => {
                                         var   month = year+'-05-01'
                                            return(
                                                history.push(`/pto/calendar/${month}`)
                                            )
                                        }
                                    }
                                }
                            }
                            header={{left: 'title',
                                     center: '',
                                     right: 'openMonth'  
                                    }}
                            height= {.5}
                            aspectRatio = {1}
                            timeZone= {'America/Chicago'}
                />
                
                    
                </div>
                <div className="col-sm-1 col-md-4">
                
                <FullCalendar defaultView="dayGridMonth" plugins={[ dayGridPlugin ]} 
                            events={ptoEvents}
                            defaultDate={year+'-06-01'}
                            ref= {juneRef}
                            customButtons= {
                                {
                                    openMonth: {
                                        text: 'Expand',
                                        click: () => {
                                         var   month = year+'-06-01'
                                            return(
                                                history.push(`/pto/calendar/${month}`)
                                            )
                                        }
                                    }
                                }
                            }
                            header={{left: 'title',
                                     center: '',
                                     right: 'openMonth'  
                                    }}
                            height= {.5}
                            aspectRatio = {1}
                            timeZone= {'America/Chicago'}
                />
                
                    
                </div>
                
                
            </div>
            
            <div className="row m-5">
                <div className="col-sm-1 col-md-4">
                
                <FullCalendar defaultView="dayGridMonth" plugins={[ dayGridPlugin ]} 
                            events={ptoEvents}
                            defaultDate={year+'-07-01'}
                            ref= {julyRef}
                            customButtons= {
                                {
                                    openMonth: {
                                        text: 'Expand',
                                        click: () => {
                                         var   month = year+'-07-01'
                                            return(
                                                history.push(`/pto/calendar/${month}`)
                                            )
                                        }
                                    }
                                }
                            }
                            header={{left: 'title',
                                     center: '',
                                     right: 'openMonth'  
                                    }}
                            height= {.5}
                            aspectRatio = {1}
                            timeZone= {'America/Chicago'}
                />
                
                    
                </div>
                <div className="col-sm-1 col-md-4">
                
                <FullCalendar defaultView="dayGridMonth" plugins={[ dayGridPlugin ]} 
                            events={ptoEvents}
                            defaultDate={year+'-08-01'}
                            ref= {augustRef}
                            customButtons= {
                                {
                                    openMonth: {
                                        text: 'Expand',
                                        click: () => {
                                         var   month = year+'-08-01'
                                            return(
                                                history.push(`/pto/calendar/${month}`)
                                            )
                                        }
                                    }
                                }
                            }
                            header={{left: 'title',
                                     center: '',
                                     right: 'openMonth'  
                                    }}
                            height= {.5}
                            aspectRatio = {1}
                            timeZone= {'America/Chicago'}
                />
                
                    
                </div>
                <div className="col-sm-1 col-md-4">
                
                <FullCalendar defaultView="dayGridMonth" plugins={[ dayGridPlugin ]} 
                            events={ptoEvents}
                            defaultDate={year+'-09-01'}
                            ref= {septemberRef}
                            customButtons= {
                                {
                                    openMonth: {
                                        text: 'Expand',
                                        click: () => {
                                         var   month = year+'-09-01'
                                            return(
                                                history.push(`/pto/calendar/${month}`)
                                            )
                                        }
                                    }
                                }
                            }
                            header={{left: 'title',
                                     center: '',
                                     right: 'openMonth'  
                                    }}
                            height= {.5}
                            aspectRatio = {1}
                            timeZone= {'America/Chicago'}
                />
                
                    
                </div>

            </div>

            <div className="row m-5">
                <div className="col-sm-1 col-md-4">
                
                <FullCalendar defaultView="dayGridMonth" plugins={[ dayGridPlugin ]} 
                            events={ptoEvents}
                            defaultDate={year+'-10-01'}
                            ref= {octoberRef}
                            customButtons= {
                                {
                                    openMonth: {
                                        text: 'Expand',
                                        click: () => {
                                         var   month = year+'-10-01'
                                            return(
                                                history.push(`/pto/calendar/${month}`)
                                            )
                                        }
                                    }
                                }
                            }
                            header={{left: 'title',
                                     center: '',
                                     right: 'openMonth'  
                                    }}
                            height= {.5}
                            aspectRatio = {1}
                            timeZone= {'America/Chicago'}
                />
                
                    
                </div>
                <div className="col-sm-1 col-md-4">
                
                <FullCalendar defaultView="dayGridMonth" plugins={[ dayGridPlugin ]} 
                            events={ptoEvents}
                            defaultDate={year+'-11-01'}
                            ref= {novemberRef}
                            customButtons= {
                                {
                                    openMonth: {
                                        text: 'Expand',
                                        click: () => {
                                         var   month = year+'-11-01'
                                            return(
                                                history.push(`/pto/calendar/${month}`)
                                            )
                                        }
                                    }
                                }
                            }
                            header={{left: 'title',
                                     center: '',
                                     right: 'openMonth'  
                                    }}
                            height= {.5}
                            aspectRatio = {1}
                            timeZone= {'America/Chicago'}
                />
                
                    
                </div>
                <div className="col-sm-1 col-md-4">
                
                <FullCalendar defaultView="dayGridMonth" plugins={[ dayGridPlugin ]} 
                            events={ptoEvents}
                            defaultDate={year+'-12-01'}
                            ref= {decemberRef}
                            customButtons= {
                                {
                                    openMonth: {
                                        text: 'Expand',
                                        click: () => {
                                         var   month = year+'-12-01'
                                            return(
                                                history.push(`/pto/calendar/${month}`)
                                            )
                                        }
                                    }
                                }
                            }
                            header={{left: 'title',
                                     center: '',
                                     right: 'openMonth'  
                                    }}
                            height= {.5}
                            aspectRatio = {1}
                            timeZone= {'America/Chicago'}
                />
                
                    
                </div>

            </div>
        </Fragment>
        
    );
};

export default PtoYearCalendar;