import React, {useEffect} from 'react';
import Pto from './Pto';

//Redux
import { useDispatch, useSelector } from 'react-redux';

import {getPTOListAction} from '../actions/timeoffrequestActions';
import {obtenerInformacionUsuario, obtenerBalanceHoras} from '../actions/authenticateActions';
import Spinner from './helpers/Spinner';


const EmployeePtos = ({match}) => {

    // obtener el ID a editar
    const { uid } = match.params;

    // console.log(uid);


    // Mandar llamar la accion principal para retornar los PTO

    const dispatch = useDispatch();

    //acceder al state
    const userInfo = useSelector(state=>state.userInfo);
    const ptoList = useSelector(state => state.ptoList.ptoRequests);

    useEffect(()=> {

        const loadPtoList = ()=> dispatch(getPTOListAction(uid));
        loadPtoList();
        
        const loadUser = ()=> dispatch(obtenerInformacionUsuario(null, null));
        loadUser();
        
        const loadHours = ()=> dispatch(obtenerBalanceHoras());
        loadHours();
        

    },[dispatch, uid, ptoList.length]);

    return (
        
        userInfo.userInfo.uid !== undefined
        ? 
            <React.Fragment>
                
                <h2 className="text-center my-5">TIME OFF REQUEST</h2>

                <div className="card mb-3 avatar" >
                    <div className="row no-gutters">
                        
                    </div>
                </div>
                <table className="table table-striped table-to-card">
                    <thead className="bg-primary table-dark">
                        <tr>
                            <th scope="col">Employee</th>
                            <th scope="col">Department</th>
                            <th scope="col">Status</th>
                            <th scope="col">Created At</th>
                            <th scope="col">Beginning</th>
                            <th scope="col">End</th>
                            <th scope="col">Delivery Status</th>
                            <th scope="col">Abscence</th>
                            <th scope="col">Hours</th>
                            <th scope="col">Actions</th>
                        </tr>   
                    </thead>
                    <tbody>
                        {ptoList.map(pto =>(
                            <Pto 
                            key={pto.id}
                            pto = {pto}
                            managerView = {true}
                            />
                        ))}
                        
                    </tbody>
                </table>
                
                
    
            </React.Fragment>
            :
            <Spinner />
    );
};

export default EmployeePtos;