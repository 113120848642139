import React, {useState, useEffect, useRef} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {editDepartmentAction} from '../actions/manageActions';
import Swal from 'sweetalert2';

const EditDepartment = ({match, history}) => {

     // obtener departamentos
     const departments = useSelector(state=>state.manager.departments);

    const {id} = match.params;

    const filterElementsByDepartment  = (array, query) => {
        return array.filter(function(el) {
            
            return el.id === Number(query);
           
        })
        }

    // dispatch actions
    const dispatch = useDispatch();
    const editDepartment = (departmentToEdit) => dispatch (editDepartmentAction(departmentToEdit));

   
    let responsibleDepartment

    const department = filterElementsByDepartment(departments,id);
    if(department[0].director_of !== undefined ) {
        responsibleDepartment = JSON.parse(department[0].director_of);
    }else {
        responsibleDepartment = [department[0]];
    }
    



    const merge = responsibleDepartment.map((item, index) => 
    //console.log(item.department, index)
    // item.department === departments[index].department ? false : item
   
    
         departments.find(element => item.id === element.id)
    //    console.log(item.department)
    //    let elements = departments.filter((el, indice) => el.department !== item.department);
    //     if(departments.indexOf(item.department) === -1) {
    //         console.log('no encontrado')
    //     }
    
  )

  console.log(merge)

  
  

    const [idDepartment, setIdDepartment] = useState(department[0].id)
    const [departmentName, setDepartmentName] = useState('');
    const [directorMail, setDirectorMail] = useState('');
    const [reportTo, setReportTo] = useState('');
    const [active, setActive] = useState(department[0].active);
    const [responsible, setResponsible] = useState(merge);
    const [checkedState, setCheckedState] = useState(departments);
    const [costCenter, setCostCenter] = useState('');

    //new Array(departments.length).fill(false)

    // let departmentsToSelect = () => {
    //     let responsibles = merge;
    //     let updateCheckedState = checkedState;
    //     let update;
    //         update = updateCheckedState.filter((departmentFound, index) => {
    //             if(responsibles[index] !== undefined) {
    //                 console.log(departmentFound.department, responsibles[index].department)
    //                return departmentFound.department !== responsibles[index].department ? departmentFound : ''
    //             }
    //         }
            
    //             );
    //             setCheckedState(update)
        
     
    // }

    const handleCheckActive = (value) => {
        
        var newActive = !active;
         setActive(!active);
    }

    const handleCheckResponsible = (position, department) => {
        let updateCheckedState = checkedState;

        let found = updateCheckedState.find(departmentFound =>departmentFound.department === department.department);
        if(found){
            console.log('departamento encontrado')
            let responsibleArray = responsible.filter((item) => item.department !== department.department)
            setResponsible(responsibleArray);
        }else {
            console.log('departamento no encontrado')
            updateCheckedState.push(responsible[position])
            let responsibleArray = responsible.filter((item) => item.department !== department.department)
            console.log(responsibleArray);
            setResponsible(responsibleArray);
            setCheckedState(updateCheckedState);
        }
        
    }

    const handleCheckDepartment = (position, department) => {

        let updateCheckedState = [];
        let updateResponsible = responsible;

        let found = updateResponsible.find(departmentFound =>departmentFound.department === department.department);
        if(found) {
            console.log('articulo encontrado')
            updateCheckedState = checkedState.filter((item) => item.department !== department.department)
            // Preguntar al usuario
            Swal.fire({
                title: 'Warning',
                text: `${department.department} department is already assigned.`,
                type: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Accept',
            })
            setCheckedState(updateCheckedState);
        }else {
            console.log('articulo no encontrado')
            if(checkedState[position]) {
                console.log(checkedState[position])
                delete checkedState[position].director_of
                console.log(checkedState[position])
                updateResponsible.push(checkedState[position])
                updateCheckedState = checkedState.filter((item) => item.department !== department.department)
                console.log(updateResponsible)
                setResponsible(updateResponsible)
                setCheckedState(updateCheckedState)
                //console.log(responsible)
            }
        }


       
    }

    // useEffect(() => {
    //   //  departmentsToSelect();
        
    // }, [])

    const submitDepartment = (e) => {
        e.preventDefault();
        Swal.fire({
            title: 'Edit Department',
            text: "Are you sure ?",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Confirm',
            cancelButtonText: 'Cancel'
          }).then((result) => {

            if(result.value) {

                Swal.fire(
                    'Saved',
                    'Department info has been updated successfully.',
                    'success'
                )

                editDepartment({
                    idDepartment,
                    departmentName,
                    directorMail,
                    reportTo,
                    active,
                    responsible,
                    costCenter
                });

                 //redirect
                history.push('/home');


            }

            

           
          });
    }
    
    
        
        

    useEffect(() => {

        setDepartmentName(department[0].department)
        setDirectorMail(department[0].email_director)
        setReportTo(department[0].report_to)
        
        console.log(responsible)
        console.log(checkedState)
    }, [responsible, checkedState])
    

    
    return (
        <div className="row mt-5">
            <div className='col-md-12 p-3'>
                <div className='card'>
                    <div className='card-body'>
                    <form 
                        onSubmit={submitDepartment}
                        >
                            <div className="form-group">
                                <label>Department Name</label>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    placeholder="Department Name" 
                                    defaultValue= {department[0].department}
                                 onChange={ e => setDepartmentName(e.target.value) }
                                />
                            </div>

                            <div className="form-group">
                                <label>Director Mail</label>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    placeholder="Director Mail" 
                                    defaultValue= {department[0].email_director}
                                    onChange={ e => setDirectorMail(e.target.value) }
                                />
                            </div>

                            <div className="form-group">
                                <label>Report To</label>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    placeholder="Report To" 
                                    defaultValue= {department[0].report_to}
                                    onChange={ e => setReportTo(e.target.value) }
                                />
                            </div>

                            <div className="form-group">
                                <label>Cost Center</label>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    placeholder="Cost Center" 
                                    defaultValue= {department[0].cost_center}
                                    onChange={ e => setCostCenter(e.target.value) }
                                />
                            </div>

                            <div className="form-group">
                                <p><label>Active</label></p>
                                <div className="custom-control custom-switch custom-control-inline">
                                    <input 
                                    type="checkbox" 
                                    className="custom-control-input"
                                    id="customSwitch3" 
                                    onChange={e => handleCheckActive(e.target.value) } 
                                    checked= {active}
                                    />
                                    <label className="custom-control-label" htmlFor="customSwitch3">No / Yes</label>
                                </div>
                            </div>

                            <hr />

                            <div className="form-group">
                                <label>Responsible</label>
                                <div className='row justify-content-center container align-baseline'>
                                {
                                    
                                    responsible.map((department, index) => {
                                    return (
                                                    <React.Fragment key={department.id}>
                                                        {
                                                            
                                                        
                                                        <div key={department.id} className='col-md-4'>
                                                            {/* <input 
                                                            id="responsible-checkbox"
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            name={'department-director-of'}
                                                            key={department.id}
                                                            value= {department.department}
                                                            checked={checkedState[index]}
                                                            onChange={(e) => handleCheckResponsible(index)}
                                                            /> */}
                                                           
                                                            {/* <input 
                                                                type="checkbox" 
                                                                className="custom-control-input"
                                                                id={'responsible-'+department.id}
                                                                onChange={e => handleCheckResponsible(index, department) } 
                                                                checked={checkedState[index]}
                                                                /> */}
                                                            <button 
                                                            type='button'
                                                            className='btn btn-danger p-2 m-2 btn-lg'
                                                            id={'responsible-'+department.id}
                                                            onClick={e => handleCheckResponsible(index, department) }
                                                            >
                                                                {'-'}
                                                            </button>
                                                            <label htmlFor={'responsible-'+department.id} className="">{department.department}</label>
                                                        </div>

                                                       
                                                            
                                                        }
                                                    
                                                    </React.Fragment>
                                                    
                                                );
                                                    }
                                                )}
                                    </div>
                            </div>

                            <hr />

                            <div className="form-group">
                                <label>Departments</label>
                                <div className='row justify-content-center container align-baseline'>
                                {
                                    
                                    checkedState.map((department, index) => {
                                    return (
                                                    <React.Fragment key={department.id}>
                                                        {
                                                            
                                                        
                                                        <div key={department.id} className='col-md-4'>
                                                            {/* <input 
                                                            id="responsible-checkbox"
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            name={'department-director-of'}
                                                            key={department.id}
                                                            value= {department.department}
                                                            checked={checkedState[index]}
                                                            onChange={(e) => handleCheckResponsible(index)}
                                                            /> */}
                                                           
                                                            {/* <input 
                                                                type="checkbox" 
                                                                className="custom-control-input"
                                                                id={'department-'+department.id}
                                                                onChange={e => handleCheckDepartment(index, department) } 
                                                              
                                                                /> */}
                                                            <button type='button'
                                                                className='btn btn-success btn-lg p-2 m-2'
                                                                id={'department-'+department.id}
                                                                onClick={e => handleCheckDepartment(index, department) } 
                                                            >
                                                                {'+'}
                                                            </button>
                                                            <label htmlFor={'department-'+department.id} className="">{department.department}</label>
                                                        </div>

                                                       
                                                            
                                                        }
                                                    
                                                    </React.Fragment>
                                                    
                                                );
                                                    }
                                                )}
                                    </div>
                            </div>

                            

                            <button type="submit" className="btn btn-primary font-weight-bold text-uppercase d-block  mb-5">Save</button>
                    </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditDepartment;