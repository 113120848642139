import {
    AGREGAR_FIXED_ASSET_REQUEST,
    AGREGAR_FIXED_ASSET_REQUEST_ERROR,
    AGREGAR_FIXED_ASSET_REQUEST_EXITO,
    COMENZAR_DESCARGA_FIXED_ASSET_REQUEST,
    COMENZAR_DESCARGA_FIXED_ASSET_REQUEST_EXITO,
    COMENZAR_DESCARGA_FIXED_ASSET_REQUEST_ERROR,
    OBTENER_DETALLE_FIXED_ASSET_REQUEST,
    OBTENER_DETALLE_FIXED_ASSET_REQUEST_EXITO,
    OBTENER_DETALLE_FIXED_ASSET_REQUEST_ERROR,
    COMENZAR_EDITAR_FIXED_REQUEST,
    COMENZAR_EDITAR_FIXED_REQUEST_EXITO,
    COMENZAR_EDITAR_FIXED_REQUEST_ERROR,
    COMENZAR_DESCARGA_TOTAL_FIXED_ASSET_REQUEST,
    COMENZAR_DESCARGA_TOTAL_FIXED_ASSET_REQUEST_EXITO,
    COMENZAR_DESCARGA_TOTAL_FIXED_ASSET_REQUEST_ERROR
}
from '../types';

const intialState = {
    fixedAssetRequests: [],
    fixedAssetSupervisor: [],
    error:false,
    loading: false,
    fixedAsset: {}
}

export default function(state=intialState, action) {
    switch(action.type) {
        case AGREGAR_FIXED_ASSET_REQUEST:
            return {
                ...state,
                error: false
            }
        case AGREGAR_FIXED_ASSET_REQUEST_EXITO:
            return {
                ...state,
                fixedAssetRequests: [...state.fixedAssetRequests, action.payload],
                error: false
            }
        case AGREGAR_FIXED_ASSET_REQUEST_ERROR:
            return {
                ...state,
                error: true
            }
        case COMENZAR_DESCARGA_FIXED_ASSET_REQUEST:
            return {
                ...state,
                error:false,
                loading: true,
                fixedAsset: {}
            }
        case COMENZAR_DESCARGA_FIXED_ASSET_REQUEST_EXITO:
            return {
                ...state,
                error:false,
                fixedAssetRequests: action.payload,
                loading:false,
                fixedAsset: {}
            }
        case COMENZAR_DESCARGA_FIXED_ASSET_REQUEST_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            }
        case COMENZAR_DESCARGA_TOTAL_FIXED_ASSET_REQUEST:
            return {
                ...state,
                error:false,
                loading: true,
                fixedAsset: {}
            }
        case COMENZAR_DESCARGA_TOTAL_FIXED_ASSET_REQUEST_EXITO:
            return {
                ...state,
                error:false,
                fixedAssetRequests: action.payload,
                loading:false,
                fixedAsset: {}
            }
        case COMENZAR_DESCARGA_TOTAL_FIXED_ASSET_REQUEST_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            }
        case OBTENER_DETALLE_FIXED_ASSET_REQUEST:
            return {
                ...state,
                error: false,
                fixedAsset: {}
            }
        case OBTENER_DETALLE_FIXED_ASSET_REQUEST_EXITO:
            return {
                ...state,
                error: false,
                fixedAsset: action.payload
            }
        case OBTENER_DETALLE_FIXED_ASSET_REQUEST_ERROR:
            return {
                ...state,
                error: true
            }
        case COMENZAR_EDITAR_FIXED_REQUEST:
            return {
                ...state,
                error: false,
                pto:{}
            }
        case COMENZAR_EDITAR_FIXED_REQUEST_EXITO:
            return {
                ...state,
                error: false,
                fixedAssetRequests: state.fixedAssetRequests.map(request => request.id === action.payload.id ? request = action.payload : request),
                pto:{}
            }
        case COMENZAR_EDITAR_FIXED_REQUEST_ERROR:
            return {
                ...state,
                error: true
            }
        default:
            return state;
    }
}