import React from 'react';
import {Link} from 'react-router-dom';
import moment from 'moment';

import {useSelector } from 'react-redux';


const EmployeesSupervisorManager = ({employee, history}) => {

  //  acceder al state
   const userInfo = useSelector(state=>state.userInfo);

    if(userInfo.userInfo.name === undefined){
       // history.push('/home');
    }
    

    return (
        <tr style={employee.terminate_contract === 1 ? {backgroundColor: '#fdd5d5'} : null}>
            <td>{employee.employee_name} {' '} {employee.employee_lastname}</td>
            <td>{employee.department}</td>
            <td>{moment.utc(employee.hire_date).format('YYYY-MM-DD')}</td>
            <td>{moment.utc(employee.increase_date).format('YYYY-MM-DD')}</td>
            <td>{employee.available_hours}</td>
            <td>{employee.carry_over}</td>
            <td>{employee.sick_days_limit}</td>
            
            <td className="acciones">
                <Link
                    to={`/employee/view/${employee.uid}`}
                    className="btn btn-primary m-1 btn-sm"
                >
                    PTO List Details
                </Link>
                <Link
                    to={`/users/view/${employee.uid}`}
                    className="btn btn-dark m-1 btn-sm"
                >
                    Profile
                </Link>
            </td>
        </tr>
    );
};

export default EmployeesSupervisorManager;