import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';
import Swal from 'sweetalert2';
import moment from 'moment';
import clienteAxiosHR from '../config/axiosHR';
import {ajustarBalanceHoras, obtenerInformacionUsuario} from '../actions/authenticateActions';

import axiosHr from '../config/axiosHR';

// Redux
import {useDispatch, useSelector} from 'react-redux';
import {deletePtoAction} from '../actions/timeoffrequestActions';

const Pto = ({pto, managerView}) => {

    // Dispatch para ejecutar
    const dispatch = useDispatch();

    // agregar balance horas
    const balanceHoras = (horas) => dispatch(ajustarBalanceHoras(horas));

     //acceder al state
     const userInfo = useSelector(state=>state.userInfo);

     // agregar balance de horas
    const balanceHorasActual = userInfo.hours;
    const uid = userInfo.userInfo.uid;
    const supervisorEmail = userInfo.userInfo.supervisorEmail;
    const email = userInfo.userInfo.email;
    const company = userInfo.userInfo.company;

    // const dueDate = moment(pto.day_beginning);
    // const today = moment();

    const manager = useSelector(state=>state.userInfo.userInfo.manager);
    const supervisor = useSelector(state=>state.userInfo.userInfo.supervisor);
    const departmentUsers = useSelector(state=>state.manager.employees);
    //const departmentUsers = userInfo.userHour; 
    
    useEffect(()=> {
 
      const loadUser = ()=> dispatch(obtenerInformacionUsuario(null, null));
      loadUser();
      
      

  },[dispatch]);

    //console.log(pto);

    let createdAt = moment.utc(pto.date).format('MM-DD-YYYY');
    let absenceText;
      if(pto.absence_code ==='V') {
        absenceText = 'Vacation'
      }
      if(pto.absence_code ==='PT') {
        absenceText = 'Personal Time'
      }
      if(pto.absence_code ==='LA') {
        absenceText = 'Leave of Absence'
      }
      if(pto.absence_code ==='LTD') {
        absenceText = 'Long Term Disability'
      }
      if(pto.absence_code ==='STD') {
        absenceText = 'Short Term Disability'
      }
      if(pto.absence_code ==='S') {
        absenceText = 'Sick'
      }
      if(pto.absence_code ==='J') {
        absenceText = 'Jury Duty'
      }
      if(pto.absence_code ==='O') {
        absenceText = 'Other'
      } 
      if(pto.absence_code ==='J') {
        absenceText = 'Jury Duty'
    }
    if(pto.absence_code ==='B1') {
        absenceText = 'Bereavement 1'
    }
    if(pto.absence_code ==='B2') {
        absenceText = 'Bereavement 2'
    }
    if(pto.absence_code ==='B3') {
        absenceText = 'Bereavement 3'
    }
    if(pto.absence_code ==='NB') {
        absenceText = 'New Baby Born'
    }
    if(pto.absence_code ==='NBCSI') {
      absenceText = 'New Baby Born'
  }
    if(pto.absence_code ==='M') {
        absenceText = 'Marriage'
    }
    if(pto.absence_code ==='MA') {
        absenceText = 'Maternity'
    }
    if(pto.absence_code ==='FFCRA') {
      absenceText = 'FFCRA (Emergency Sick Time)'
    }
    if(pto.absence_code ==='VT') {
      absenceText = 'Vote'
    }
    if(pto.absence_code ==='MD') {
      absenceText = 'Medical Disability'
    }



    const confirmDeletePto = (id, totalHours, carryOverUsage, sickHoursUsage, carryOverSickHoursUsage) =>  {
        // ask user confirmation
        
        // Preguntar al usuario
        Swal.fire({
            title: '¿Are you sure?',
            text: "This action can't be undone",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Delete!',
            cancelButtonText: 'Cancel'
          }).then((result) => {
            if (result.value) {
              Swal.fire(
                'Deleted',
                'The Time off Request has been deleted successfully.',
                'success'
              )
              dispatch(deletePtoAction(id) );

              if(pto.absence_code === 'J' || pto.absence_code === 'VT' || pto.absence_code === 'M') {
                let data
                switch (pto.absence_code) {
                  case 'J':

                    balanceHoras({
                      available_hours: parseInt(balanceHorasActual.available_hours) + (parseInt(totalHours) - parseInt(40)),
                      requested_hours: parseInt(balanceHorasActual.requested_hours) - parseInt(totalHours),
                      total_requests: balanceHorasActual.total_requests - 1,
                      carry_over: parseInt(balanceHorasActual.carry_over),
                      sick_hours_limit: parseInt(balanceHorasActual.sick_days_limit),
                      carry_over_sick_time: parseInt(balanceHorasActual.carry_over_sick_time),
                      uid : uid
                  })

                  data = {
                    uid : userInfo.userInfo.uid,
                    hours_before_mov:parseInt(userInfo.hours.available_hours),
                    hours_after_mov:parseInt(balanceHorasActual.available_hours) + (parseInt(totalHours) - parseInt(40)),
                    hours_mov: parseInt(totalHours),
                    carry_over_before_mov:parseInt(userInfo.hours.carry_over),
                    carry_over_after_mov:parseInt(balanceHorasActual.carry_over),
                    carry_over_sick_time_before:parseInt(balanceHorasActual.carry_over_sick_time),
                    carry_over_sick_time_after: parseInt(balanceHorasActual.carry_over_sick_time),
                    observations: 'Delete PTO '+ absenceText,
                    type_mov: 'Delete PTO',
                    date_mov: moment().format('YYYY-MM-DD'),
                    mov_increase_date: moment(userInfo.hours.increase_date).format('YYYY-MM-DD'),
                    mov_yearly_increase_date:moment(userInfo.hours.year_increase_date).format('YYYY-MM-DD'),
                    increase_date_before_mov:moment(userInfo.hours.increase_date).format('YYYY-MM-DD'),
                    yearly_increase_date_before_mov:moment(userInfo.hours.year_increase_date).format('YYYY-MM-DD')
                  }
                  axiosHr.post('/hour-movement-log', data)
                  .then(respuesta => {
                      console.log(respuesta)
                  })
                  .catch(error => {
                  console.log(error)
                  });
                    
                  break;
                  case 'VT':

                      balanceHoras({
                        available_hours: parseInt(balanceHorasActual.available_hours) + (parseInt(totalHours) - parseInt(2)),
                        requested_hours: parseInt(balanceHorasActual.requested_hours) - parseInt(totalHours),
                        total_requests: balanceHorasActual.total_requests - 1,
                        carry_over: parseInt(balanceHorasActual.carry_over),
                        sick_hours_limit: parseInt(balanceHorasActual.sick_days_limit),
                        carry_over_sick_time: parseInt(balanceHorasActual.carry_over_sick_time),
                        uid : uid
                    })

                    data = {
                      uid : userInfo.userInfo.uid,
                      hours_before_mov:parseInt(userInfo.hours.available_hours),
                      hours_after_mov:parseInt(balanceHorasActual.available_hours) + (parseInt(totalHours) - parseInt(2)),
                      hours_mov: parseInt(totalHours),
                      carry_over_before_mov:parseInt(userInfo.hours.carry_over),
                      carry_over_after_mov:parseInt(balanceHorasActual.carry_over),
                      carry_over_sick_time_before:parseInt(balanceHorasActual.carry_over_sick_time),
                      carry_over_sick_time_after: parseInt(balanceHorasActual.carry_over_sick_time),
                      observations: 'Delete PTO '+ absenceText,
                      type_mov: 'Delete PTO',
                      date_mov: moment().format('YYYY-MM-DD'),
                      mov_increase_date: moment(userInfo.hours.increase_date).format('YYYY-MM-DD'),
                      mov_yearly_increase_date:moment(userInfo.hours.year_increase_date).format('YYYY-MM-DD'),
                      increase_date_before_mov:moment(userInfo.hours.increase_date).format('YYYY-MM-DD'),
                      yearly_increase_date_before_mov:moment(userInfo.hours.year_increase_date).format('YYYY-MM-DD')
                    }
                    axiosHr.post('/hour-movement-log', data)
                    .then(respuesta => {
                        console.log(respuesta)
                    })
                    .catch(error => {
                    console.log(error)
                    });
                    
                    break;
                    case 'M':

                      balanceHoras({
                        available_hours: parseInt(balanceHorasActual.available_hours) + (parseInt(totalHours) - parseInt(24)),
                        requested_hours: parseInt(balanceHorasActual.requested_hours) - parseInt(totalHours),
                        total_requests: balanceHorasActual.total_requests - 1,
                        carry_over: parseInt(balanceHorasActual.carry_over),
                        sick_hours_limit: parseInt(balanceHorasActual.sick_days_limit),
                        carry_over_sick_time: parseInt(balanceHorasActual.carry_over_sick_time),
                        uid : uid
                    })

                    data = {
                      uid : userInfo.userInfo.uid,
                      hours_before_mov:parseInt(userInfo.hours.available_hours),
                      hours_after_mov:parseInt(balanceHorasActual.available_hours) + (parseInt(totalHours) - parseInt(24)),
                      hours_mov: parseInt(totalHours),
                      carry_over_before_mov:parseInt(userInfo.hours.carry_over),
                      carry_over_after_mov:parseInt(balanceHorasActual.carry_over),
                      carry_over_sick_time_before:parseInt(balanceHorasActual.carry_over_sick_time),
                      carry_over_sick_time_after: parseInt(balanceHorasActual.carry_over_sick_time),
                      observations: 'Delete PTO '+ absenceText,
                      type_mov: 'Delete PTO',
                      date_mov: moment().format('YYYY-MM-DD'),
                      mov_increase_date: moment(userInfo.hours.increase_date).format('YYYY-MM-DD'),
                      mov_yearly_increase_date:moment(userInfo.hours.year_increase_date).format('YYYY-MM-DD'),
                      increase_date_before_mov:moment(userInfo.hours.increase_date).format('YYYY-MM-DD'),
                      yearly_increase_date_before_mov:moment(userInfo.hours.year_increase_date).format('YYYY-MM-DD')
                    }
                    axiosHr.post('/hour-movement-log', data)
                    .then(respuesta => {
                        console.log(respuesta)
                    })
                    .catch(error => {
                    console.log(error)
                    });
                    
                    break;
                
                  default:
                    break;
                }


              } else {


                
  
              if(pto.absence_code === 'S') {
                  balanceHoras({
                    available_hours: parseInt(balanceHorasActual.available_hours),
                    requested_hours: parseInt(balanceHorasActual.requested_hours) - parseInt(totalHours),
                    total_requests: balanceHorasActual.total_requests - 1,
                    carry_over: parseInt(balanceHorasActual.carry_over) + parseInt(carryOverUsage),
                    sick_hours_limit: parseInt(balanceHorasActual.sick_days_limit) + parseInt(sickHoursUsage),
                    carry_over_sick_time: parseInt(balanceHorasActual.carry_over_sick_time) + parseInt(carryOverSickHoursUsage),
                    uid : uid
                })
                    let data = {
                      uid : userInfo.userInfo.uid,
                      hours_before_mov:parseInt(balanceHorasActual.sick_days_limit),
                      hours_after_mov:parseInt(balanceHorasActual.sick_days_limit) + parseInt(sickHoursUsage),
                      hours_mov: parseInt(totalHours),
                      carry_over_before_mov:parseInt(userInfo.hours.carry_over),
                      carry_over_after_mov:parseInt(balanceHorasActual.carry_over) + parseInt(carryOverUsage),
                      carry_over_sick_time_before:parseInt(balanceHorasActual.carry_over_sick_time),
                      carry_over_sick_time_after: parseInt(balanceHorasActual.carry_over_sick_time) + parseInt(pto.carry_over_sick_hours_usage),
                      observations: 'Delete PTO ' + absenceText,
                      type_mov: 'Delete PTO',
                      date_mov: moment().format('YYYY-MM-DD'),
                      mov_increase_date: moment(balanceHorasActual.increase_date).format('YYYY-MM-DD'),
                      mov_yearly_increase_date:moment(balanceHorasActual.year_increase_date).format('YYYY-MM-DD'),
                      increase_date_before_mov:moment(balanceHorasActual.increase_date).format('YYYY-MM-DD'),
                      yearly_increase_date_before_mov:moment(balanceHorasActual.year_increase_date).format('YYYY-MM-DD')
                  }
                  axiosHr.post('/hour-movement-log', data)
                  .then(respuesta => {
                      console.log(respuesta)
                  })
                  .catch(error => {
                  console.log(error)
                  });
                }else {
                  balanceHoras({
                    available_hours: parseInt(balanceHorasActual.available_hours) + (parseInt(totalHours) - parseInt(carryOverUsage) - parseInt(sickHoursUsage) - parseInt(carryOverSickHoursUsage)),
                    requested_hours: parseInt(balanceHorasActual.requested_hours) - parseInt(totalHours),
                    total_requests: balanceHorasActual.total_requests - 1,
                    carry_over: parseInt(balanceHorasActual.carry_over) + parseInt(carryOverUsage),
                    sick_hours_limit: parseInt(balanceHorasActual.sick_days_limit) + parseInt(sickHoursUsage),
                    carry_over_sick_time: parseInt(balanceHorasActual.carry_over_sick_time) + parseInt(carryOverSickHoursUsage),
                    uid : uid
                })
                  let data = {
                    uid : userInfo.userInfo.uid,
                    hours_before_mov:parseInt(userInfo.hours.available_hours),
                    hours_after_mov:parseInt(balanceHorasActual.available_hours) + (parseInt(totalHours) - parseInt(carryOverUsage) - parseInt(sickHoursUsage) - parseInt(carryOverSickHoursUsage)),
                    hours_mov: parseInt(totalHours),
                    carry_over_before_mov:parseInt(userInfo.hours.carry_over),
                    carry_over_after_mov:parseInt(balanceHorasActual.carry_over) + parseInt(carryOverUsage),
                    carry_over_sick_time_before:parseInt(balanceHorasActual.carry_over_sick_time),
                    carry_over_sick_time_after: parseInt(balanceHorasActual.carry_over_sick_time) + parseInt(pto.carry_over_sick_hours_usage),
                    observations: 'Delete PTO '+ absenceText,
                    type_mov: 'Delete PTO',
                    date_mov: moment().format('YYYY-MM-DD'),
                    mov_increase_date: moment(userInfo.hours.increase_date).format('YYYY-MM-DD'),
                    mov_yearly_increase_date:moment(userInfo.hours.year_increase_date).format('YYYY-MM-DD'),
                    increase_date_before_mov:moment(userInfo.hours.increase_date).format('YYYY-MM-DD'),
                    yearly_increase_date_before_mov:moment(userInfo.hours.year_increase_date).format('YYYY-MM-DD')
                  }
                  axiosHr.post('/hour-movement-log', data)
                  .then(respuesta => {
                      console.log(respuesta)
                  })
                  .catch(error => {
                  console.log(error)
                  });
                }
              }

              

              
              
            }
          })
    }

    const sendEmail = (pto, supervisorEmail, email, company) => {
         // Preguntar al usuario
         const ptoInfo = {
           pto,
           supervisorEmail,
           email,
           company
         }

         console.log(company);

         Swal.fire({
            title: '¿Are you sure?',
            text: "Your Time Off Request is gonna be send to your supervisor and H.R. Department",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Send!',
            cancelButtonText: 'Cancel'
          }).then((result) => {
            //  console.log(pto);
            if (result.value) {
                clienteAxiosHR.post('/pto-email', ptoInfo)
                .then(respuesta => {
                    //console.log(respuesta);
                    Swal.fire(
                        'Sent',
                        'The Time off Request has been sent successfully.',
                        'success'
                      )

                      setTimeout(() => {
                        window.location.reload(false);
                      }, 3000);
                })
                .catch(error => {
                    console.log(error);
                    Swal.fire(
                        'Failed',
                        'An error was occurred by sending your request.',
                        'warning'
                      )
                })
            }
          })
    }

    const cancelRequest = (pto, totalHours,supervisorEmail, email, carryOverUsage, sickHoursUsage, carryOverSickHoursUsage, company) => {
      // Preguntar al usuario
      const ptoInfo = {
        pto,
        supervisorEmail,
        email,
        company
      }

      Swal.fire({
         title: '¿Are you sure?',
         text: "Your Time Off Request will be be canceled",
         type: 'warning',
         showCancelButton: true,
         confirmButtonColor: '#3085d6',
         cancelButtonColor: '#d33',
         confirmButtonText: 'Yes, Cancel Request!',
         cancelButtonText: 'Cancel'
       }).then((result) => {
           console.log(pto);
         if (result.value) {
             clienteAxiosHR.post('/pto-cancel', ptoInfo)
             .then(respuesta => {
                 //console.log(respuesta);
                 Swal.fire(
                     'Sent',
                     'The Time off Request has been canceled successfully.',
                     'success'
                   );

                   balanceHoras({
                    available_hours: parseInt(balanceHorasActual.available_hours) + (parseInt(totalHours) - parseInt(carryOverUsage) - parseInt(sickHoursUsage) - parseInt(carryOverSickHoursUsage)),
                    requested_hours: parseInt(balanceHorasActual.requested_hours) - parseInt(totalHours),
                    total_requests: balanceHorasActual.total_requests - 1,
                    carry_over: parseInt(balanceHorasActual.carry_over) + parseInt(carryOverUsage),
                    sick_hours_limit: parseInt(balanceHorasActual.sick_days_limit) + parseInt(sickHoursUsage),
                    carry_over_sick_time: parseInt(balanceHorasActual.carry_over_sick_time) + parseInt(carryOverSickHoursUsage),
                    uid : uid
                });

                if(pto.absence_code === 'S') {
                  let data = {
                    uid : userInfo.userInfo.uid,
                    hours_before_mov:parseInt(balanceHorasActual.sick_days_limit),
                    hours_after_mov:parseInt(balanceHorasActual.sick_days_limit) + parseInt(sickHoursUsage),
                    hours_mov: parseInt(totalHours),
                    carry_over_before_mov:parseInt(userInfo.hours.carry_over),
                    carry_over_after_mov:parseInt(balanceHorasActual.carry_over) + parseInt(carryOverUsage),
                    carry_over_sick_time_before:parseInt(balanceHorasActual.carry_over_sick_time),
                    carry_over_sick_time_after:parseInt(balanceHorasActual.carry_over_sick_time) + parseInt(pto.carry_over_sick_hours_usage),
                    observations: 'Cancel PTO '+ absenceText,
                    type_mov: 'Cancel PTO',
                    date_mov: moment().format('YYYY-MM-DD'),
                    mov_increase_date: moment(userInfo.hours.increase_date).format('YYYY-MM-DD'),
                    mov_yearly_increase_date:moment(userInfo.hours.year_increase_date).format('YYYY-MM-DD'),
                    increase_date_before_mov:moment(userInfo.hours.increase_date).format('YYYY-MM-DD'),
                    yearly_increase_date_before_mov:moment(userInfo.hours.year_increase_date).format('YYYY-MM-DD')
                  }
                  axiosHr.post('/hour-movement-log', data)
                  .then(respuesta => {
                      console.log(respuesta)
                  })
                  .catch(error => {
                  console.log(error)
                  });
                }else {
                  let data = {
                    uid : userInfo.userInfo.uid,
                    hours_before_mov:parseInt(userInfo.hours.available_hours),
                    hours_after_mov:parseInt(balanceHorasActual.available_hours) + (parseInt(totalHours) - parseInt(carryOverUsage) - parseInt(sickHoursUsage)),
                    hours_mov: parseInt(totalHours),
                    carry_over_before_mov:parseInt(userInfo.hours.carry_over),
                    carry_over_after_mov:parseInt(balanceHorasActual.carry_over) + parseInt(carryOverUsage),
                    carry_over_sick_time_before:parseInt(balanceHorasActual.carry_over_sick_time),
                    carry_over_sick_time_after:parseInt(balanceHorasActual.carry_over_sick_time) + parseInt(pto.carry_over_sick_hours_usage),
                    observations: 'Cancel PTO '+ absenceText,
                    type_mov: 'Cancel PTO',
                    date_mov: moment().format('YYYY-MM-DD'),
                    mov_increase_date: moment(userInfo.hours.increase_date).format('YYYY-MM-DD'),
                    mov_yearly_increase_date:moment(userInfo.hours.year_increase_date).format('YYYY-MM-DD'),
                    increase_date_before_mov:moment(userInfo.hours.increase_date).format('YYYY-MM-DD'),
                    yearly_increase_date_before_mov:moment(userInfo.hours.year_increase_date).format('YYYY-MM-DD')
                  }
                  axiosHr.post('/hour-movement-log', data)
                  .then(respuesta => {
                      console.log(respuesta)
                  })
                  .catch(error => {
                  console.log(error)
                  });
                }

                

                setTimeout(() => {
                  window.location.reload(false);
                }, 3000);
             })
             .catch(error => {
                 console.log(error);
                 Swal.fire(
                     'Failed',
                     'An error was occurred by sending your request.',
                     'warning'
                   )
             })

           
         }
       })
    }

    const reOpenPto = (pto, totalHours,supervisorEmail, email, carryOverUsage, sickHoursUsage, company) => {
      // Preguntar al usuario
      const ptoInfo = {
        pto,
        supervisorEmail,
        email,
        company
      }
      

      Swal.fire({
        title: '¿Are you sure?',
        text: "You are about to reopen this Time Off Request",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Reopen!',
        cancelButtonText: 'Cancel'
      }).then((result) => {
        //  console.log(pto);
        if (result.value) {
            clienteAxiosHR.put('/reopen-pto', ptoInfo)
            .then(respuesta => {
              //  console.log(respuesta);
                Swal.fire(
                    'Sent',
                    'The Time off Request has been Reopen successfully.',
                    'success'
                  );

                  const balanceHorasActual = departmentUsers.find(uids => uids.uid === pto.user_id)
 // TODO agregar balance de horas con carry over sick time y agregar log de movimientos para reopen
                  balanceHoras({
                    available_hours: parseInt(balanceHorasActual.available_hours) + (parseInt(pto.total_hours) - parseInt(pto.carry_over_usage) - parseInt(pto.sick_hours_usage) - parseInt(pto.carry_over_sick_hours_usage)),
                    requested_hours: parseInt(balanceHorasActual.requested_hours) - parseInt(pto.total_hours),
                    total_requests: balanceHorasActual.total_requests - 1,
                    carry_over: parseInt(balanceHorasActual.carry_over + parseInt(pto.carry_over_usage)),
                    sick_hours_limit: parseInt(balanceHorasActual.sick_days_limit) + parseInt(pto.sick_hours_usage),
                    carry_over_sick_time: parseInt(balanceHorasActual.carry_over_sick_time) + parseInt(pto.carry_over_sick_hours_usage),
                    uid : pto.user_id
                  });

                  if(pto.absence_code === 'S') {
                    let data = {
                      uid : userInfo.userInfo.uid,
                      hours_before_mov:parseInt(balanceHorasActual.sick_days_limit),
                      hours_after_mov:parseInt(balanceHorasActual.sick_days_limit) + parseInt(sickHoursUsage),
                      hours_mov: parseInt(totalHours),
                      carry_over_before_mov:parseInt(balanceHorasActual.carry_over),
                      carry_over_after_mov: parseInt(balanceHorasActual.carry_over + parseInt(pto.carry_over_usage)),
                      carry_over_sick_time_before:parseInt(balanceHorasActual.carry_over_sick_time),
                      carry_over_sick_time_after:parseInt(balanceHorasActual.carry_over_sick_time) + parseInt(pto.carry_over_sick_hours_usage),
                      observations: 'Reopen PTO '+ absenceText,
                      type_mov: 'Reopen PTO',
                      date_mov: moment().format('YYYY-MM-DD'),
                      mov_increase_date: moment(userInfo.hours.increase_date).format('YYYY-MM-DD'),
                      mov_yearly_increase_date:moment(userInfo.hours.year_increase_date).format('YYYY-MM-DD'),
                      increase_date_before_mov:moment(userInfo.hours.increase_date).format('YYYY-MM-DD'),
                      yearly_increase_date_before_mov:moment(userInfo.hours.year_increase_date).format('YYYY-MM-DD')
                    }
                    axiosHr.post('/hour-movement-log', data)
                    .then(respuesta => {
                        console.log(respuesta)
                    })
                    .catch(error => {
                    console.log(error)
                    });
                  }else {

                  let data = {
                    uid : pto.user_id,
                    hours_before_mov:parseInt(balanceHorasActual.available_hours),
                    hours_after_mov:parseInt(balanceHorasActual.available_hours) + (parseInt(pto.total_hours) - parseInt(pto.carry_over_usage) - parseInt(pto.sick_hours_usage)),
                    hours_mov: parseInt(pto.total_hours),
                    carry_over_before_mov:parseInt(balanceHorasActual.carry_over),
                    carry_over_after_mov: parseInt(balanceHorasActual.carry_over + parseInt(pto.carry_over_usage)),
                    carry_over_sick_time_before:parseInt(balanceHorasActual.carry_over_sick_time),
                    carry_over_sick_time_after:parseInt(balanceHorasActual.carry_over_sick_time) + parseInt(pto.carry_over_sick_hours_usage),
                    observations: 'Reopen PTO '+ absenceText,
                    type_mov: 'Reopen PTO',
                    date_mov: moment().format('YYYY-MM-DD'),
                    mov_increase_date: moment(balanceHorasActual.increase_date).format('YYYY-MM-DD'),
                    mov_yearly_increase_date:moment(balanceHorasActual.year_increase_date).format('YYYY-MM-DD'),
                    increase_date_before_mov:moment(balanceHorasActual.increase_date).format('YYYY-MM-DD'),
                    yearly_increase_date_before_mov:moment(balanceHorasActual.year_increase_date).format('YYYY-MM-DD')
                  }
                  axiosHr.post('/hour-movement-log', data)
                  .then(respuesta => {
                      console.log(respuesta)
                  })
                  .catch(error => {
                  console.log(error)
                  });
                }

               setTimeout(() => {
                 window.location.reload(false);
               }, 3000);
            })
            .catch(error => {
                console.log(error);
                Swal.fire(
                    'Failed',
                    'An error was occurred by reopen this PTO.',
                    'warning'
                  )
            })

          
        }
      })
    }

    

    return (
        <tr>
            <td>{pto.employee_name}</td>
            <td>{pto.department}</td>
            <td>{pto.status}</td>
            <td>{
                createdAt
                }
            </td>
            <td>{ moment.utc(pto.day_beginning).format('MM-DD-YYYY')}</td>
            <td>{moment.utc(pto.day_end).format('MM-DD-YYYY')}</td>
            <td>{pto.send ? 'Sent' : 'Not Sent'}</td>
            <td>{absenceText}</td>
            <td>{pto.total_hours}</td>
            
            { manager || supervisor
              ?
               pto.send === 1
               ?
                <td className="acciones">
                    <Link
                        to={`/pto/view/${pto.id}`}
                        className="btn btn-primary m-1"
                    >
                        Details
                    </Link>

                    { (manager && pto.reopen === 0 && managerView === true && pto.status !== 'Denied' && pto.status !== 'Canceled')
                      ? 
                      <button
                      className="btn btn-success m-1"
                      onClick = {() => reOpenPto(pto, pto.total_hours, supervisorEmail, email, pto.carry_over_usage, pto.sick_hours_usage)}
                    >
                      Reopen
                    </button>
                      :
                          null
                    }
                  </td>
               :
                  <td className="acciones">
                    <Link
                        to={`/pto/view/${pto.id}`}
                        className="btn btn-primary m-1"
                    >
                        Details
                    </Link>

                    {(pto.reopen === 1 && managerView !== true)
                    ?
                    <Link
                        to={`/pto/edit/${pto.id}`}
                        className="btn btn-warning m-1"
                    >
                        Confirm
                    </Link>
                    :
                    null
                    }
                  </td>                  
              :
              pto.send 
                ? 
                <td className="acciones">
                    <Link
                        to={`/pto/view/${pto.id}`}
                        className="btn btn-primary m-1"
                    >
                        Details
                    </Link>
                    {//dueDate.diff(today) > 0  && pto.status !== 'Canceled' && pto.status !== 'Denied'
                    pto.status !== 'Canceled' && pto.status !== 'Denied' && pto.status !== 'Approved' && pto.status !== 'Opened' && pto.reopen !== 1
                    ?
                      <button
                          className="btn btn-danger m-1"
                          onClick = {() => cancelRequest(pto, pto.total_hours, supervisorEmail, email, pto.carry_over_usage, pto.sick_hours_usage, pto.carry_over_sick_hours_usage)}
                      >
                          Cancel
                      </button>
                    :
                      null
                    }
                   
                </td>
                : <td className="acciones">
                    <Link
                        to={`/pto/view/${pto.id}`}
                        className="btn btn-primary m-1"
                    >
                        Details
                    </Link>
                    {(pto.reopen === 1 && managerView !== true)
                    ?
                    <Link
                        to={`/pto/edit/${pto.id}`}
                        className="btn btn-warning m-1"
                    >
                        Confirm
                    </Link>
                    :
                    null
                    }
                    {(pto.status !== 'Opened' && managerView !== true && pto.reopen !== 1 && pto.status !== 'Approved')
                      ?
                        <button
                            className="btn btn-success m-1"
                            onClick = {() => sendEmail(pto, supervisorEmail, email, pto.company)}
                        >
                            Send
                        </button>
                        :
                        null
                    }

                    {(pto.status !== 'Opened' && managerView !== true && pto.reopen !== 1 && pto.status !== 'Approved' && pto.hr !==0)
                      ?
                          <button
                          className="btn btn-danger"
                          onClick = {() => confirmDeletePto(pto.id, pto.total_hours, pto.carry_over_usage, pto.sick_hours_usage, pto.carry_over_sick_hours_usage)}        
                      >
                          Delete
                      </button>
                        :
                        null
                    }
    
                   
                </td>   
            }
             </tr>  


            
    );
};

export default Pto;