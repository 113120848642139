import {
    OBTENER_INFORMACION_USUARIO,
    OBTENER_INFORMACION_USUARIO_EXITO,
    OBTENER_INFORMACION_USUARIO_ERROR,
    OBTENER_BALANCE_HORAS,
    OBTENER_BALANCE_HORAS_EXITO,
    OBTENER_BALANCE_HORAS_ERROR,
    COMENZAR_AJUSTE_HORAS,
    COMENZAR_AJUSTE_HORAS_EXITO,
    COMENZAR_AJUSTE_HORAS_ERROR,
    OBTENER_USUARIO_AJUSTE,
    OBTENER_USUARIO_AJUSTE_EXITO,
    OBTENER_USUARIO_AJUSTE_ERROR,
    COMENZAR_AJUSTE_MENSUAL_HORAS,
    COMENZAR_AJUSTE_MENSUAL_HORAS_EXITO,
    COMENZAR_AJUSTE_MENSUAL_HORAS_ERROR,
    CREAR_USUARIO,
    CREAR_USUARIO_EXITO,
    CREAR_USUARIO_ERROR,
    EDITAR_USUARIO,
    EDITAR_USUARIO_EXITO,
    EDITAR_USUARIO_ERROR,
    EDITAR_PROFILE,
    EDITAR_PROFILE_EXITO,
    EDITAR_PROFILE_ERROR
} from '../types';

import clienteAxios from '../config/axios';
import axiosHr from '../config/axiosHR';

// Obtener info inicial usuarios
export function obtenerInformacionUsuario(username, password) {
    return async (dispatch) => {
        dispatch(descargaInformacionUsuario());

       const data = {
            username: username,
            password: password
            //token: token
        } 

        let token, supervisor, manager, avatarImage = '';

        if(localStorage.getItem('token') !== null) {
            console.log('está logueado');
            token = localStorage.getItem('token'); 
                // localStorage.removeItem('supervisor');
                // localStorage.removeItem('name');
                // localStorage.removeItem('manager'); 
                // localStorage.removeItem('department');
                // localStorage.removeItem('uid');
                // localStorage.removeItem('hire_date');
                // localStorage.removeItem('supervisorEmail');
                // localStorage.removeItem('avatar');

            // axiosHr.post('/login', data).then(respuesta => {
            //     localStorage.setItem('token', respuesta.data.data[0].token);
            //     token = respuesta.data.data[0].token;   
            //     localStorage.setItem('supervisor', respuesta.data.data[0].supervisor);
            //     localStorage.setItem('name', respuesta.data.data[0].employee_name);
            //     localStorage.setItem('lastname', respuesta.data.data[0].employee_lastname);
            //     localStorage.setItem('manager', respuesta.data.data[0].manager);
            //     localStorage.setItem('company', respuesta.data.data[0].company); 
            //     localStorage.setItem('department', respuesta.data.data[0].department);
            //     localStorage.setItem('uid', respuesta.data.data[0].uid);
            //     localStorage.setItem('hire_date', respuesta.data.data[0].hire_date);
                
            //     avatarImage = respuesta.data.data[0].avatar;
            //     localStorage.setItem('supervisorEmail', respuesta.data.data[0].supervisor_mail);
            //     if(avatarImage === '') {
            //         localStorage.setItem('avatar' ,  './avatar-placeholder.png');
            //     }else {
            //         localStorage.setItem('avatar' ,  respuesta.data.data[0].avatar);
            //     }

            //     const userInfo = {
            //         username: respuesta.data.data[0].username,
            //         name : respuesta.data.data[0].employee_name,
            //         lastname : respuesta.data.data[0].employee_lastname,
            //         department: respuesta.data.data[0].department,
            //         email: respuesta.data.data[0].email,
            //         supervisor: respuesta.data.data[0].supervisor,
            //         manager: respuesta.data.data[0].manager,
            //         company: respuesta.data.data[0].company,
            //         uid: respuesta.data.data[0].uid,
            //         avatarUrl: respuesta.data.data[0].avatar,
            //         supervisorEmail: respuesta.data.data[0].supervisor_mail,
            //         hireDate: respuesta.data.data[0].hire_date,
            //         sickDays: respuesta.data.data[0].sick_days_limit
            //     }

            //     dispatch(descargaInformacionUsuarioExitosa(userInfo));
            // }).catch(error => {
            //     console.log(error);
            // })

        }else {
            console.log('no está logueado');

            let formData = new FormData();

            formData.append('username', username);
            formData.append('password', password);

            // await clienteAxios({
            //     method: 'post',
            //     url: '/index.php?option=com_api&app=users&resource=login&format=raw',
            //     headers: {
            //         'Content-Type': 'application/x-www-form-urlencoded',
            //     },
            //     data: formData
            // }).then(response => {
                await clienteAxios.post('/wp-json/jwt-auth/v1/token', data
                ).then(response => {
                    console.log(response)

               // console.log(response.data.status);

                if(response.status !== 200) {
                    dispatch(descargaInformacionUsuarioError());
                    return
                }

                // let dataLogin = {
                //     uid: response.data.data.id,
                //     username: response.data.data.auth
                // }

                let dataLogin = {
                    uid: response.data.data.email,
                    username: response.data.data.email,
                    email: response.data.data.email,
                    token: response.data.data.token
                }


                console.log(dataLogin.token)

               // return
                

                axiosHr.post('/login', dataLogin).then(respuesta => {
                localStorage.setItem('token', dataLogin.token);
                token = dataLogin.token;
                localStorage.setItem('name', respuesta.data.data[0].employee_name);
                localStorage.setItem('lastname', respuesta.data.data[0].employee_lastname);   
                localStorage.setItem('supervisor', respuesta.data.data[0].supervisor);
                localStorage.setItem('manager', respuesta.data.data[0].manager); 
                localStorage.setItem('company', respuesta.data.data[0].company);
                localStorage.setItem('department', respuesta.data.data[0].department);
                localStorage.setItem('uid', respuesta.data.data[0].uid);
                localStorage.setItem('hire_date', respuesta.data.data[0].hire_date);
                localStorage.setItem('territory', respuesta.data.data[0].territory);
                localStorage.setItem('job_title', respuesta.data.data[0].job_title);
               
                avatarImage = respuesta.data.data[0].avatar;
                localStorage.setItem('email', respuesta.data.data[0].email);
                localStorage.setItem('supervisorEmail', respuesta.data.data[0].supervisor_mail);
                if(avatarImage === '') {
                    localStorage.setItem('avatar' ,  './avatar-placeholder.png');
                }else {
                    localStorage.setItem('avatar' ,  respuesta.data.data[0].avatar);
                }

                const userInfo = {
                    username: username,
                    name : respuesta.data.data[0].employee_name,
                    lastname: respuesta.data.data[0].employee_lastname,
                    department: respuesta.data.data[0].department,
                    company: respuesta.data.data[0].company,
                    email: respuesta.data.data[0].email,
                    supervisor: respuesta.data.data[0].supervisor,
                    manager: respuesta.data.data[0].manager,
                    uid: respuesta.data.data[0].uid,
                    avatarUrl: localStorage.getItem('avatar'),
                    supervisorEmail: respuesta.data.data[0].supervisor_mail,
                    hireDate: respuesta.data.data[0].hire_date,
                    territory: respuesta.data.data[0].territory,
                    job_title: respuesta.data.data[0].job_title,

                }

                dispatch(descargaInformacionUsuarioExitosa(userInfo));

            })
            .catch(error => {
                console.log(error);
                dispatch(descargaInformacionUsuarioError());
            })

            })
           
        }

        if(token !== undefined) {
            //obtener datos del portal
            // await clienteAxios.get('/index.php?option=com_api&app=users&resource=user&format=raw', {
            //     headers: {
            //         'Content-Type': 'application/x-www-form-urlencoded',
            //         'Authorization': `Bearer ${token}`
            //     }
            // })
            // .then(respuesta => {
                
                if(localStorage.getItem('supervisor') === '1') {
                supervisor =  true;
                } else {
                    supervisor = false;
                }

                if(localStorage.getItem('manager') === '1') {
                    manager =  true;
                } else {
                    manager = false;
                }

                let department = localStorage.getItem('department');
                let uid = localStorage.getItem('uid');
                let avatarUrl = localStorage.getItem('avatar');
                let supervisorEmail = localStorage.getItem('supervisorEmail');
                let email = localStorage.getItem('email');
                let hireDate =  localStorage.getItem('hire_date');
                let name = localStorage.getItem('name');
                let company = localStorage.getItem('company');
                let lastname = localStorage.getItem('lastname');
                let territory = localStorage.getItem('territory');
                let job_title = localStorage.getItem('job_title');
             

                const userInfo = {
                   // username: respuesta.data.data.username,
                    username: username,
                    name : name,
                    lastname: lastname,
                    department: department,
                    company: company,
                    email: email,
                    supervisor: supervisor,
                    manager: manager,
                    uid: uid,
                    avatarUrl: avatarUrl,
                    supervisorEmail: supervisorEmail,
                    hireDate: hireDate,
                    territory: territory,
                    job_title: job_title
                }

                dispatch(descargaInformacionUsuarioExitosa(userInfo));
            // })
            // .catch(error=> {
            //     console.log(error);
            //     dispatch(descargaInformacionUsuarioError());
            // });


            // PRUEBAS LOCAL
            // const userInfo = {
            //              name : 'Julio Castro',
            //              department: 'Graphic Design & Digital Communication',
            //              email: 'julio.castro@mexilink.com',
            //              supervisor: 0,
            //              uid: 881
            //          }
            //          dispatch(descargaInformacionUsuarioExitosa(userInfo));
        }else {
            dispatch(descargaInformacionUsuarioError());
        }
           
    }
}

export const descargaInformacionUsuario = () => ({
    type: OBTENER_INFORMACION_USUARIO
});

export const descargaInformacionUsuarioExitosa = (userInfo) =>({
    type: OBTENER_INFORMACION_USUARIO_EXITO,
    payload: userInfo
})

export const descargaInformacionUsuarioError = () => ({
    type: OBTENER_INFORMACION_USUARIO_ERROR
})

// Balance de horas

export function obtenerBalanceHoras(horas) {
    return async(dispatch) => {
        dispatch(obtenerInicioBalanceHoras());

        if(localStorage.getItem('token') !== null) {
            let token = localStorage.getItem('email');
            await axiosHr.get(`/hours/${token}`).then(respuesta => {
               dispatch(obtenerBalanceHorasExito(respuesta.data.data));
            })
            .catch(error => {
                console.log(error);
                dispatch(obtenerBalanceHorasError());
            })
        }
            
        
    }
}

export const obtenerInicioBalanceHoras = () => ({
    type: OBTENER_BALANCE_HORAS
})

export const obtenerBalanceHorasExito = (horas) => ({
    type: OBTENER_BALANCE_HORAS_EXITO,
    payload: horas
})

export const obtenerBalanceHorasError = () => ({
    type: OBTENER_BALANCE_HORAS_ERROR
})

// Ajustar balance de horas

export function ajustarBalanceHoras(horas) {
    return async(dispatch) => {
        dispatch(comenzarAjusteHoras());
        console.log(horas);

        axiosHr.put('/hours-adjustment', horas)
        .then(respuesta => {
            console.log('Balance Realizado');
        })
        .catch(error => {
            console.log(error);
        })

    }
}

export const comenzarAjusteHoras = () => ({
    type: COMENZAR_AJUSTE_HORAS
})

export const comenzarAjusteHorasExito = (horas) => ({
    type: COMENZAR_AJUSTE_HORAS_EXITO,
    payload: horas
})

export const comenzarAjusteHorasError = () => ({
    type: COMENZAR_AJUSTE_HORAS_ERROR
})

// obtener usuario para balance de horas
export function obtenerUsuarioBalanceHoras (department) {
    return async(dispatch) => {
        dispatch(comenzarObtenerUsuarioBalanceHoras());

        axiosHr.get(`/users/${department}`)
        .then(respuesta=> {
           // console.log(respuesta);
            dispatch(obtenerUsuarioBalanceHorasExito(respuesta.data.data));
        })
        .catch(error => {
            console.log(error)
            dispatch(obtenerUsuarioBalanceHorasError());
        })
    }
}

export const comenzarObtenerUsuarioBalanceHoras = () => ({
    type: OBTENER_USUARIO_AJUSTE
});

export const obtenerUsuarioBalanceHorasExito = (users) => ({
    type: OBTENER_USUARIO_AJUSTE_EXITO,
    payload: users
});

export const obtenerUsuarioBalanceHorasError = () => ({
    type: OBTENER_USUARIO_AJUSTE_ERROR
})

// obtener usuario para balance de horas
export function obtenerUsuarioBalanceHorasDirector () {
    return async(dispatch) => {
        dispatch(comenzarObtenerUsuarioBalanceHorasDirector());

        axiosHr.get(`/directors`)
        .then(respuesta=> {
           // console.log(respuesta);
            dispatch(obtenerUsuarioBalanceHorasExitoDirector(respuesta.data.data));
        })
        .catch(error => {
            console.log(error)
            dispatch(obtenerUsuarioBalanceHorasErrorDirector());
        })
    }
}

export const comenzarObtenerUsuarioBalanceHorasDirector = () => ({
    type: OBTENER_USUARIO_AJUSTE
});

export const obtenerUsuarioBalanceHorasExitoDirector = (users) => ({
    type: OBTENER_USUARIO_AJUSTE_EXITO,
    payload: users
});

export const obtenerUsuarioBalanceHorasErrorDirector = () => ({
    type: OBTENER_USUARIO_AJUSTE_ERROR
})

// Ajustar balance de horas

export function ajustarHorasHireDate(horas) {
    return async(dispatch) => {
        dispatch(comenzarAjusteHireDate());
        axiosHr.put('/hours-hiredate', horas)
        .then(respuesta => {
            console.log('Balance Realizado');
        })
        .catch(error => {
            console.log(error);
        })

    }
}

export const comenzarAjusteHireDate = () => ({
    type: COMENZAR_AJUSTE_MENSUAL_HORAS
})

export const comenzarAjusteHireDateExito = (horas) => ({
    type: COMENZAR_AJUSTE_MENSUAL_HORAS_EXITO,
    payload: horas
})

export const comenzarAjusteHireDateError = () => ({
    type: COMENZAR_AJUSTE_MENSUAL_HORAS_ERROR
})

// Crear Usuario

export function crearUsuario(user) {
    return async(dispatch) => {
        dispatch(comenzarCrearUsuario());
        axiosHr.post('/create-user', user)
        .then(respuesta => {
            //crear usuario
            dispatch(crearUsuarioExito(user));
        })
        .catch(error => {
            console.log(error);
            dispatch(crearUsuarioError());
        })

    }
}

export const comenzarCrearUsuario = () => ({
    type: CREAR_USUARIO
})

export const crearUsuarioExito = (user) => ({
    type: CREAR_USUARIO_EXITO,
    payload: user
})

export const crearUsuarioError = () => ({
    type: CREAR_USUARIO_ERROR
})

// Editar Usuario

export function editarUsuario(user) {
    return async(dispatch) => {
        dispatch(comenzarEditarUsuario());
        axiosHr.post('/edit-user', user)
        .then(respuesta => {
            //crear usuario
            dispatch(editarUsuarioExito(user));
        })
        .catch(error => {
            console.log(error);
            dispatch(editarUsuarioError());
        })

    }
}

export const comenzarEditarUsuario = () => ({
    type: EDITAR_USUARIO
})

export const editarUsuarioExito = (user) => ({
    type: EDITAR_USUARIO_EXITO,
    payload: user
})

export const editarUsuarioError = () => ({
    type: EDITAR_USUARIO_ERROR
})


// Editar Profile

export function editarProfile(user) {
    return async(dispatch) => {
        dispatch(comenzarEditarProfile());
        axiosHr.post('/edit-profile', user)
        .then(respuesta => {
            //crear usuario
            dispatch(editarProfileExito(user));
        })
        .catch(error => {
            console.log(error);
            dispatch(editarProfileError());
        })

    }
}

export const comenzarEditarProfile = () => ({
    type: EDITAR_PROFILE
})

export const editarProfileExito = (user) => ({
    type: EDITAR_PROFILE_EXITO,
    payload: user
})

export const editarProfileError = () => ({
    type: EDITAR_PROFILE_ERROR
})