import React, {useState, useEffect} from 'react';
import {obtenerInformacionUsuario} from '../actions/authenticateActions';
import {useDispatch, useSelector} from 'react-redux';

const Login = ({history}) => {

    //console.log(localStorage.getItem('token'));

    if(localStorage.getItem('token')) {
        history.push('/home');
    }

    const dispatch = useDispatch();

    const [username, saveUserName] = useState('');
    const [password, savePassword] = useState('');
    const [ error, saveError] = useState('');

     //acceder al state
     const userInfo = useSelector(state=>state.userInfo);

    const getCredentials = e => {
        e.preventDefault();
        //obtener información de portal
        const loadUser = ()=> dispatch(obtenerInformacionUsuario(username, password));
        loadUser();

        if(!userInfo.error) {
            saveError(false);
            history.push('/home');
        }else {
            saveError(true);
        }

        
        
    }

    useEffect(() => {
        if(!userInfo.error) {
            saveError(false);
        }else {
            saveError(true);
        }
    }, [userInfo.error])


    return (
        <div className="row justify-content-center">
            <form 
                onSubmit = {getCredentials}
            >
                <div className="card mt-5">
                    <div className="card-body p-3">
                        <h2 className="card-title login-title">MXL Human Resources</h2>
                        <p className="text-muted">Please, Use Your MXL Portal Credentials.</p>
                        <div className="form-group">
                                    <label>Username</label>
                                    <input 
                                        type="text" 
                                        className="form-control" 
                                        placeholder="username" 
                                        onChange={ e => saveUserName(e.target.value) }
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Password</label>
                                    <input 
                                        type="password" 
                                        className="form-control" 
                                        placeholder="password" 
                                        onChange={ e => savePassword(e.target.value) }
                                        required
                                    />
                            </div>
                        
                        <button className="btn btn-login d-block">Sign In</button>
                        
                    </div>
                    
                    {/* <div className="card-body p-3">
                        <h2 className="card-title login-title">Under maintenance.</h2>
                        <p>Our PTO Platform will be updated and available on January 2nd, 2024.</p>
                        <p>If you have any question or need further information, please let us know. </p>
                        <p>Thank you all for your support. </p>
                        
                    </div> */}
                </div>
                { error ? <div className="font-weight-bold alert alert-danger text-center mt-4">ERROR: Please, provide valid credentials </div> : null }
            </form>
        </div>
    );
};

export default Login;