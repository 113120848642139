import {
    OBTENER_DEPARTAMENTOS,
    OBTENER_DEPARTAMENTOS_EXITO,
    OBTENER_DEPARTAMENTOS_ERROR,
    OBTENER_SICKDAYS,
    OBTENER_SICKDAYS_EXITO,
    OBTENER_SICKDAYS_ERROR,
    OBTENER_COMPANIES,
    OBTENER_COMPANIES_EXITO,
    OBTENER_COMPANIES_ERROR,
    OBTENER_TERRITORY,
    OBTENER_TERRITORY_EXITO,
    OBTENER_TERRITORY_ERROR,
    OBTENER_EMPLOYEES_MANAGER,
    OBTENER_EMPLOYEES_MANAGER_EXITO,
    OBTENER_EMPLOYEES_MANAGER_ERROR,
    OBTENER_HISTORICO,
    OBTENER_HISTORICO_EXITO,
    OBTENER_HISTORICO_ERROR,
    COMENZAR_EDITAR_DEPARTMENT,
    COMENZAR_EDITAR_DEPARTMENT_EXITO,
    COMENZAR_EDITAR_DEPARTMENT_ERROR,
    COMENZAR_CREAR_DEPARTMENT,
    COMENZAR_CREAR_DEPARTMENT_EXITO,
    COMENZAR_CREAR_DEPARTMENT_ERROR,
    OBTENER_ESTADOS_ACTIVOS,
    OBTENER_ESTADOS_ACTIVOS_EXITO,
    OBTENER_ESTADOS_ACTIVOS_ERROR,
} from '../types';

const initialState = {
    departments: [],
    employees : [],
    companies: [],
    territories: [],
    error: false,
    loading: false,
    history: [],
    states: []
}

export default function(state = initialState, action ) {
    switch(action.type) {
        case OBTENER_DEPARTAMENTOS:
            return {
                ...state,
                error: false
            }
        case OBTENER_DEPARTAMENTOS_EXITO:
            return {
                ...state,
                departments: action.payload,
                error: false
            }
        case OBTENER_DEPARTAMENTOS_ERROR:
            return {
                ...state,
                error: true
            }
        case OBTENER_SICKDAYS:
            return {
                ...state,
                error: false
            }
        case OBTENER_SICKDAYS_EXITO:
            return {
                ...state,
                employees: action.payload,
                error: false
            }
        case OBTENER_SICKDAYS_ERROR:
            return {
                ...state,
                error: true
            }
            case OBTENER_COMPANIES:
            return {
                ...state,
                error: false
            }
        case OBTENER_COMPANIES_EXITO:
            return {
                ...state,
                companies: action.payload,
                error: false
            }
        case OBTENER_COMPANIES_ERROR:
            return {
                ...state,
                error: true
            }
        case OBTENER_TERRITORY:
            return {
                ...state,
                error: false
            }
        case OBTENER_TERRITORY_EXITO:
            return {
                ...state,
                territories: action.payload,
                error: false
            }
        case OBTENER_TERRITORY_ERROR:
            return {
                ...state,
                error: true
            }
        case OBTENER_EMPLOYEES_MANAGER:
            return {
                ...state,
                error: false
            }
        case OBTENER_EMPLOYEES_MANAGER_EXITO:
            return {
                ...state,
                employees: action.payload,
                error: false
            }
        case OBTENER_EMPLOYEES_MANAGER_ERROR:
            return {
                ...state,
                error: true
            }
        case OBTENER_HISTORICO:
            return {
                ...state,
                error: false
            }
        case OBTENER_HISTORICO_EXITO:
            return {
                ...state,
                history: action.payload,
                error: false
            }
        case OBTENER_HISTORICO_ERROR:
            return {
                ...state,
                error: true
            }
        case COMENZAR_EDITAR_DEPARTMENT:
            return {
                ...state,
                error: false,
            }
        case COMENZAR_EDITAR_DEPARTMENT_EXITO:
            return {
                ...state,
                error: false,
                history: action.payload,
                //   userInfo: {}    
            }
        case COMENZAR_EDITAR_DEPARTMENT_ERROR:
            return {
                ...state,
                error: true
            }
        case COMENZAR_CREAR_DEPARTMENT:
            return {
                ...state,
                error: false,
            }
        case COMENZAR_CREAR_DEPARTMENT_EXITO:
            return {
                ...state,
                error: false,
                history: action.payload,
                //   userInfo: {}    
            }
        case COMENZAR_CREAR_DEPARTMENT_ERROR:
            return {
                ...state,
                error: true
            }
        case OBTENER_ESTADOS_ACTIVOS:
            return {
                ...state,
                error: false
            }
        case OBTENER_ESTADOS_ACTIVOS_EXITO:
            return {
                ...state,
                states: action.payload,
                error: false
            }
        case OBTENER_ESTADOS_ACTIVOS_ERROR:
            return {
                ...state,
                error: true
            }
        default:
            return state;
    }
}