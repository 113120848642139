import React, {useEffect, useState} from 'react';
// Redux
import {useDispatch, useSelector} from 'react-redux';
import { getFixedAssetRequestDetail } from '../actions/fixedassetrequestActions';
import moment from 'moment/moment';



const ViewFixedAssetRequest = ({match}) => {

    // Dispatch para ejecutar la acción principal
    const dispatch = useDispatch();

     // obtener el ID a editar
     const { id } = match.params;

     const assetRequest = useSelector(state => state.fixedAsset.fixedAsset);

     const [equipment, setEquipment] = useState([])

     const [purchaseNeeded, savePurchaseNeeded] = useState(false);
     const [labelCreated, saveLabelCreated] = useState(false);
     const [paymentMethod, savePaymentMethod] = useState('');
     const [paymentSuport, savePaymentSupport] = useState('');
 
     const [invoice, saveInvoice] = useState('');

     const [oldAssetInformation, setOldAssetInformation] = useState([]);

     const [status, setStatus] = useState(assetRequest.status);

     useEffect(()=> {

        const fixedAssetRequestDetail =  (request) => dispatch(getFixedAssetRequestDetail(id));
        fixedAssetRequestDetail();

       
        

        

       
    }, [dispatch, id])

    useEffect(() => {
        if(Object.keys(assetRequest).length !== 0) {
            let formattedEquipment = JSON.parse(assetRequest.equipment_requested);
           setEquipment(formattedEquipment);


        }

        if(assetRequest.old_asset_information !== null && assetRequest.old_asset_information !== undefined && assetRequest.old_asset_information.length > 0) {
            let formattedOldAssetInformation = JSON.parse(assetRequest.old_asset_information);
              console.log( assetRequest.old_asset_information === undefined, 'old asset en view fixed asset');
              setOldAssetInformation(formattedOldAssetInformation);
          // if(assetRequest.old_asset_information.length !== 0 ) {
          //     let formattedOldAssetInformation = JSON.parse(assetRequest.old_asset_information);
          //     setOldAssetInformation(formattedOldAssetInformation);
          //    }else {
          //     setOldAssetInformation([])
          //    }
         }else {
          setOldAssetInformation([])
         }
  
         if (assetRequest.purchase_needed) {
          savePurchaseNeeded(true);
         }
         if(assetRequest.label_created) {
          saveLabelCreated(true);
         }
  
         if(assetRequest.payment_method) {
          savePaymentMethod(assetRequest.payment_method);
         }
  
         if(assetRequest.payment_support) {
          savePaymentSupport(assetRequest.payment_support);
         }
  
         if(assetRequest.invoice) {
          saveInvoice(assetRequest.invoice);
         }
    }, [assetRequest])

   

    return (
        <div className="row justify-content-center mt-5">
            
                <div className="col-md-8">
                <div className="card">
                    <div className="card-body">
                        <h2 className="text-center mb-4 font-weight-bold ">Add New Fixed Asset Request</h2>
                        <form>
                            <div className="form-group">
                                <label>Employee Name</label>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    placeholder="Employee Name" 

                                    defaultValue={assetRequest.employee_name}
                                    
                                    required
                                    disabled
                                />
                            </div>
                            <div className="row justify-content-between">
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>Position</label>
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            placeholder="Position" 

                                            defaultValue=''
                                            
                                            required
                                            disabled
                                        />
                                    </div>
                                </div>

                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>State</label>
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            placeholder="State" 

                                            defaultValue={assetRequest.territory}
                                            
                                            required
                                            disabled
                                        />
                                    </div>
                                </div>

                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>Department</label>
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            placeholder="Department" 
                                            defaultValue={assetRequest.department}
                                           
                                            required
                                            disabled
                                        />
                                    </div>
                                </div>

                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>Cost Center</label>
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            placeholder="Cost Center" 

                                            defaultValue=''
                                            
                                            required
                                            disabled
                                        />
                                    </div>
                                </div>

                                

                                <div className="col-md-12 col-sm-12">
                                    <div className="form-group">
                                        <label>Reason</label>
                                            <textarea 
                                                className="form-control"
                                                value={assetRequest.reason}
                                                //onChange={e=>saveReason(e.target.value)}
                                               
                                                required
                                                disabled
                                                >
                                            </textarea>
                                    </div>
                                </div>
                            </div>
                        
                            

                            <div className="row container justify-content-between">
                                
                                
                                <div className="col-md-8 col-sm-12">
                                 <label>Employee Signature:</label>
                                    <div className="form-group signatureContainer">
                                        
                                    <img width={100} height={100} src={assetRequest.employee_signature} alt="signature"/>
                                        {/* <button className="btn btn-secondary mt-2" onClick={saveCanvasEmployee}>Save Signature</button> */}
                                        
                                    </div>
                                    
                                </div>

                                <div className="col-md-4 col-sm-12">
                                    <div className="form-group">
                                        <label>Date:</label>
                                        {/* <input 
                                        type="date" 
                                        className="form-control"
                                        onChange={e=>saveDateSignEmployee(e.target.value)}
                                        /> */}
                                        <input 
                                            type="text" 
                                            className="form-control"
                                            defaultValue={moment(assetRequest.date_employee_signature).format('yyyy-mm-d')}
                                            disabled
                                            />
                                        
                                    </div>
                                </div>
                                
                            </div>

                            <div className="col-md-12 col-sm-12">
                                    <div className="form-group">
                                    <label>Equipment Requested:</label>
                                        <table className=' table table-responsive table-stripped'>
                                            <thead>
                                                <tr>
                                                <th>
                                                    Asset Type
                                                </th>
                                                <th>
                                                    Brand
                                                </th>
                                                <th>
                                                    Model
                                                </th>
                                                <th>
                                                    Serial
                                                </th>
                                                <th>IMEI</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {
                                                   equipment.map((equipo, key) => (
                                                    <tr key={key}>
                                                        <td>{equipo.tipo}</td>
                                                        <td>{equipo.cantidad}</td>
                                                        <td>{equipo.model || 'Not updated yet'}</td>
                                                        <td>{equipo.serial || 'Not updated yet'}</td>
                                                        <td>{equipo.imei || 'Not Updated yet'}</td>
                                                    </tr>
                                                   ))
                                                  
                                                }
                                                
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                {
                                    assetRequest.status !== 'New Request'
                                    ?
                                    <>
                                    <hr/>
                                    <div className='row justify-content-center'>
                                        <div className='card p-5 m-3 d-block w-100'><h2 className='d-block w-100 text-center'>Order Status:<span className='text-primary'> {assetRequest.status}</span></h2></div>
                                        <h2 className='text-center'>Purchase Needed ?</h2>
                                        <div className='col-md-12'>
                                            <div className="form-group text-center">
                                                <div className="custom-control custom-switch custom-control-inline">
                                                    <input type="checkbox" className="custom-control-input" id="customSwitch5"  
                                                    checked= {purchaseNeeded} disabled
                                                    />
                                                    <label className="custom-control-label" htmlFor="customSwitch5">No / Yes</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
        
                                    {
                                        // Purchase Needed
                                        purchaseNeeded
                                        ?
                                        <>
                                        <div className="col-md-12 col-sm-12">
                                        <div className="form-group">
                                        <label>Purchase Information:</label>
                                            <table className=' table table-responsive table-stripped'>
                                                <thead>
                                                    <tr>
                                                    <th>
                                                        Asset Type
                                                    </th>
                                                    <th>
                                                       Quantity
                                                    </th>
                                                    <th>
                                                        Brand
                                                    </th>
                                                    <th>Cost</th>
                                                    <th>Vendor</th>
                                                    <th>Link</th>
                                                            
                                                        
                                                    
                                                    </tr>
                                                </thead>
                                                <tbody>
        
                                                    {
                                                       equipment.map((equipo, key) => (
                                                        <tr key={key}>
                                                            <td>{equipo.tipo}</td>
                                                            <td>{equipo.cantidad}</td>
                                                            <td> {equipo.brand || 'Not updated yet'}
                                                                    
                                                                   
                                                            </td>
                                                                <td> {equipo.cost || 'Not updated yet'}
                                                                
                                                                </td>
                                                                <td> {equipo.orderedFrom || 'Not updated yet'}
                                                                    
                                                                </td>
                                                                <td> {equipo.link || 'Not updated yet'}
                                                                    
                                                                </td>
                                                        </tr>
                                                       ))
                                                      
                                                    }
                                                    
                                                </tbody>
                                            </table>
        
                                            
                                        </div>
                                    </div>
                                    
                                    {
                                        // Treasury is working
                                        assetRequest.status === 'Under Review By Treasury' || assetRequest.status ===  'I.T. Department are completing information' || assetRequest.status ===  'Under Review By Accounting'
                                        //assetRequest
                                    ?
                                    <>
                                    <div className="col-md-12 col-sm-12">
                                        <div className="form-group">
                                        <label>Order Information:</label>
                                            <table className=' table table-responsive table-stripped'>
                                                <thead>
                                                    <tr>
                                                    <th>
                                                        Payment Method
                                                    </th>
                                                    <th>
                                                       Upload Payment Method Support
                                                    </th>
                                               
                                                    <th>
                                                        Upload Invoice / Quote
                                                    </th>
                                                   
                                                                              
                                                    
                                                    </tr>
                                                </thead>
                                                <tbody>
        
                                                    {
                                                      
                                                        <tr>
                                                            
                                                            <td> 
                                                                {paymentMethod}
                                                            </td>
                                                            <td> 
                                                                <a href={paymentSuport}>Payment Support Link</a>
                                                            
                                                            </td>
                                                            <td> 
                                                                <a href={invoice}>Quote / Invoice Link</a> 
                                                            </td>
                                                        </tr>
                                                      
                                                      
                                                    }
                                                    
                                                </tbody>
                                            </table>
        
                                            
                                        </div>
                                    </div>
                                      
                                            
                                    {/* <div className="col-md-6 col-sm-6">
                                        <div className="form-group">
                                        <label>Invoice Information:</label>
                                            <table className=' table table-responsive table-stripped'>
                                                <thead>
                                                    <tr>
                                                    <th>
                                                        Upload Invoice / Quote
                                                    </th>                                              
                                                    
                                                    </tr>
                                                </thead>
                                                <tbody>
        
                                                    {
                                                      
                                                        <tr>
                                                            
                                                            
                                                            
        
                                                        </tr>
                                                      
                                                      
                                                    }
                                                    
                                                </tbody>
                                            </table>
        
                                            
                                        </div>
                                    </div> */}
                                    </>
                                    :
                                    null
        
                                    
                                    }
                                    </>
                                    :
                                    null
        
                                    }
        
                                    <hr/>
                                    <div className='row justify-content-center'>
                                        <h2 className='text-center'>Label Created ?</h2>
                                        <div className='col-md-12'>
                                            <div className="form-group text-center">
                                                <div className="custom-control custom-switch custom-control-inline">
                                                    <input type="checkbox" className="custom-control-input" id="customSwitch7"  
                                                    checked= {labelCreated} disabled
                                                    />
                                                    <label className="custom-control-label" htmlFor="customSwitch7">No / Yes</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
        
                                    <div className="col-md-12 col-sm-12">
                                            <div className="form-group">
                                            <label>Asset Information:</label>
                                                <table className=' table table-responsive table-stripped'>
                                                    <thead>
                                                        <tr>
                                                        <th>
                                                            Asset Type
                                                        </th>
                                                        <th>
                                                           Quantity
                                                        </th>
                                                        <th>
                                                        Brand
                                                        </th>
                                                        <th>
                                                            Model
                                                        </th>
                                                        <th>
                                                            Serial
                                                        </th>
                                                        <th>IMEI</th>
                                                        {
                                                            assetRequest.status === 'I.T. Department are completing information' && labelCreated
                                                        ?   
                                                            <>
                                                            <th>Asset Number</th>
                                                            </>
                                                        :
                                                            null
                                                        }
        
                                                        {
                                                            assetRequest.status === 'Under Review By Accounting'
                                                        ?   
                                                            <>
                                                            
                                                            <th>Date Updated On The System</th>
                                                            </>
                                                        :
                                                            null
                                                        }
                                                        
                                                        </tr>
                                                    </thead>
                                                    <tbody>
        
                                                        {
                                                           equipment.map((equipo, key) => (
                                                            <tr key={key}>
                                                                <td>{equipo.tipo}</td>
                                                                <td>{equipo.cantidad}</td>
                                                                <td> {equipo.brand || 'Not updated yet'}
                                                                        
                                                                    </td>
                                                                <td> {equipo.model || 'Not updated yet'}
                                                                    
                                                                </td>
                                                                <td> {equipo.serial || 'Not updated yet'}
                                                                        
                                                                    </td>
                                                                <td> {equipo.imei || 'Not updated yet'}
                                                                    
                                                                </td>
        
                                                                {
                                                                    assetRequest.status === 'I.T. Department are completing information' && labelCreated
                                                                    ?
                                                                    <>
        
                                                                        <td> {equipo.assetNumber || 'Not updated yet'}
                                                                           
                                                                        </td>
        
                                                                        
                                                                    
                                                                    </>
                                                                    :
                                                                    null
                                                                }
        
                                                                {
                                                                    assetRequest.status === 'Under Review By Accounting' 
                                                                    ?
                                                                    <>
        
                                                                        <td> {equipo.dateUpdated || 'Not updated yet'}
                                                                            
                                                                        </td>
                                                                    
                                                                    </>
                                                                    :
                                                                    null
                                                                }
                                                        
                                                                </tr>
                                                           ))
                                                          
                                                        }
                                                        
                                                    </tbody>
                                                </table>
        
                                                
                                            </div>
                                        </div>
                                     
        
                                        <div className="col-md-12 col-sm-12">
                                            
                                            <div className="form-group">
                                            <label>Old Asset Information:</label>
                                                <table className=' table table-responsive table-stripped'>
                                                    <thead>
                                                        <tr>
                                                        <th>
                                                            Asset Type
                                                        </th>
                                                        <th>
                                                           Quantity
                                                        </th>
                                                        <th>
                                                        Brand
                                                        </th>
                                                        <th>
                                                            Model
                                                        </th>
                                                        <th>
                                                            Serial
                                                        </th>
                                                        <th>IMEI</th>
                                                        <th>Status</th>
                                                      
                                                        
                                                        </tr>
                                                    </thead>
                                                    <tbody>
        
                                                        {
                                                            oldAssetInformation !== null 
                                                            ?
                                                           oldAssetInformation.map((oldAsset, key) => (
                                                            <tr key={key}>
                                                                <td> {oldAsset.tipo || 'Not updated yet'}
                                                                       
                                                                    </td>
                                                                <td> 
                                                                      
                                                                       1
                                                                    </td>
                                                                <td> {oldAsset.brand || 'Not updated yet'}
                                                                        
                                                                </td>
                                                                <td> {oldAsset.model || 'Not updated yet'}
                                                                   
                                                                </td>
                                                                <td> {oldAsset.serial || 'Not updated yet'}
                                                                       
                                                                    </td>
                                                                <td> {oldAsset.imei || 'Not updated yet'}
                                                                   
                                                                </td>
        
                                                                <td> {oldAsset.status || 'Not updated yet'}
                                                                    
                                                                </td>
        
                                                               
                                                            </tr>
                                                           ))
                                                           :null
                                                          
                                                        }
                                                        
                                                    </tbody>
                                                </table>
        
                                                
                                            </div>
                                        </div>
                                    
        
                                        <hr />
                                        </>
                                    :
                                    null
                                }
                                

                        </form>

                        
        
                        </div>
                    </div>
                </div>
            
        </div>
    );
};

export default ViewFixedAssetRequest;