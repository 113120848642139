import {
    AGREGAR_FIXED_ASSET_REQUEST,
    AGREGAR_FIXED_ASSET_REQUEST_EXITO,
    AGREGAR_FIXED_ASSET_REQUEST_ERROR,
    COMENZAR_DESCARGA_FIXED_ASSET_REQUEST,
    COMENZAR_DESCARGA_FIXED_ASSET_REQUEST_EXITO,
    COMENZAR_DESCARGA_FIXED_ASSET_REQUEST_ERROR,
    OBTENER_DETALLE_FIXED_ASSET_REQUEST,
    OBTENER_DETALLE_FIXED_ASSET_REQUEST_EXITO,
    OBTENER_DETALLE_FIXED_ASSET_REQUEST_ERROR,
    COMENZAR_EDITAR_FIXED_REQUEST,
    COMENZAR_EDITAR_FIXED_REQUEST_EXITO,
    COMENZAR_EDITAR_FIXED_REQUEST_ERROR,
    COMENZAR_DESCARGA_TOTAL_FIXED_ASSET_REQUEST,
    COMENZAR_DESCARGA_TOTAL_FIXED_ASSET_REQUEST_EXITO,
    COMENZAR_DESCARGA_TOTAL_FIXED_ASSET_REQUEST_ERROR
}
from '../types';

import clienteAxiosHR from '../config/axiosHR';

// Create new Fixed Asset Request
export function createNewFixedAssetRequestAction(request) {
    return async (dispatch) => {
        dispatch( newFixedAssetRequest());
        // Insertar en la API
        await clienteAxiosHR.post('/create-fixed-asset', request)
        .then(respuesta => {
            //console.log(respuesta.data.data.insertId)
            request["id"] = respuesta.data.data.insertId;
            //console.log(pto);
            //Si se inserta correctamente
            dispatch(newFixedAssetRequestSuccess(request));

            //dispatch(getPTOListAction());
        })
        .catch(error => {
            // console.log(error);

            // Si hay un error
            dispatch(newFixedAssetRequestError());
        })
    
    }
}


export const newFixedAssetRequest = () => ({
    type: AGREGAR_FIXED_ASSET_REQUEST
});

export const newFixedAssetRequestSuccess = (request) => ({
    type: AGREGAR_FIXED_ASSET_REQUEST_EXITO,
    payload: request
});

export const newFixedAssetRequestError = () => ({
    type: AGREGAR_FIXED_ASSET_REQUEST_ERROR
});


// Retrieve Fixed Asset Request

export function getAllFixedAssetsRequestList () {
    return async (dispatch) => {
        dispatch(startGetAllFixedAssetRequestList());

        await clienteAxiosHR.get(`/fixed-asset-request-list-all`)
        .then((respuesta) =>{
            // const department = respuesta.data.data; 
           // console.log(respuesta)
             dispatch(getAllFixedAssetRequestListSuccess(respuesta.data.data));
        })
        .catch((error)=> {
            //console.log(error)
            dispatch(getAllFixedAssetRequestListError());
        })
    }
}

export const startGetAllFixedAssetRequestList = () => ({
    type: COMENZAR_DESCARGA_TOTAL_FIXED_ASSET_REQUEST
});

export const getAllFixedAssetRequestListSuccess = (fixedAssetRequests) => ({
    type: COMENZAR_DESCARGA_TOTAL_FIXED_ASSET_REQUEST_EXITO,
    payload: fixedAssetRequests
});

export const getAllFixedAssetRequestListError = () => ({
    type: COMENZAR_DESCARGA_TOTAL_FIXED_ASSET_REQUEST_ERROR
});

export function getFixedAssetsRequestList (department, territory) {
    return async (dispatch) => {
        dispatch(startGetFixedAssetRequestList());

        //Consultar la API
        let data = {
            department: department,
            territory:territory
        } 

        console.log(data);

        await clienteAxiosHR.post(`/fixed-asset-request-list`, data)
        .then((respuesta) =>{
            // const department = respuesta.data.data; 
           // console.log(respuesta)
             dispatch(getFixedAssetRequestListSuccess(respuesta.data.data));
        })
        .catch((error)=> {
            //console.log(error)
            dispatch(getFixedAssetRequestListError());
        })
    }
}

export const startGetFixedAssetRequestList = () => ({
    type: COMENZAR_DESCARGA_FIXED_ASSET_REQUEST
});

export const getFixedAssetRequestListSuccess = (ptoListDepartment) => ({
    type: COMENZAR_DESCARGA_FIXED_ASSET_REQUEST_EXITO,
    payload: ptoListDepartment
});

export const getFixedAssetRequestListError = () => ({
    type: COMENZAR_DESCARGA_FIXED_ASSET_REQUEST_ERROR
});


// Mostrar detalle PTO

export function getFixedAssetRequestDetail (id) {
    return async (dispatch) => {
        dispatch(startFixedAssetRequestDetail());

        //Consultar la API
        await clienteAxiosHR.get(`/fixed-asset-request/view/${id}`)
        .then((respuesta) =>{
            const request = respuesta.data.data; 
            console.log(request);
            dispatch(getFixedAssetRequestSuccess(request));
        })
        .catch((error)=> {
            //console.log(error)
            dispatch(getFixedAssetRequestError());
        })
    }
}

export const startFixedAssetRequestDetail = () =>({
    type: OBTENER_DETALLE_FIXED_ASSET_REQUEST
})

export const getFixedAssetRequestSuccess = (request) =>({
    type: OBTENER_DETALLE_FIXED_ASSET_REQUEST_EXITO,
    payload: request
})

export const getFixedAssetRequestError = () =>({
    type: OBTENER_DETALLE_FIXED_ASSET_REQUEST_ERROR
})


// Edit Fixed Asset request Action
export function editFixedRequestAction(request) {
    return async (dispatch) => {
        dispatch( editFixedRequest());
        // Insertar en la API
        await clienteAxiosHR.put('/update-fixed-asset-request', request)
        .then(respuesta => {

            console.log(request);
            //Si se actualiza correctamente
            dispatch(editFixedRequestSuccess(request));

            //dispatch(getPTOListAction());
        })
        .catch(error => {
            // console.log(error);

            // Si hay un error
            dispatch(editFixedRequestError());
        })
    
    }
}


export const editFixedRequest = () => ({
    type: COMENZAR_EDITAR_FIXED_REQUEST
});

export const editFixedRequestSuccess = (request) => ({
    type: COMENZAR_EDITAR_FIXED_REQUEST_EXITO,
    payload: request
});

export const editFixedRequestError = () => ({
    type: COMENZAR_EDITAR_FIXED_REQUEST_ERROR
});