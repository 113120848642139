import React from 'react';
import {Link} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import Swal from 'sweetalert2';
import clienteAxiosHR from '../config/axiosHR';
import moment from 'moment';

const FixedAssetRequests = ({request, history}) => {

    const supervisor = useSelector(state=>state.userInfo.userInfo.supervisor);
    const userInfo = useSelector(state=> state.userInfo.userInfo);


    const approveRequest = (request) => {
        // Preguntar al usuario

        Swal.fire({
           title: '¿Are you sure?',
           text: "Fixed Asset Request is going to be send to I.T. Department",
           type: 'warning',
           showCancelButton: true,
           confirmButtonColor: '#3085d6',
           cancelButtonColor: '#d33',
           confirmButtonText: 'Yes, Send!',
           cancelButtonText: 'Cancel'
         }).then((result) => {
           //  console.log(pto);
           if (result.value) {
            request.status = 'Under Review By I.T.';
               clienteAxiosHR.post('/request-email', request)
               .then(respuesta => {
                   //console.log(respuesta);
                   Swal.fire(
                       'Sent',
                       'Fixed Asset Request has been Approved and email notification was sent successfully.',
                       'success'
                     )

                     setTimeout(() => {
                        window.location.reload(false);
                    //history.push('/home');
                     }, 3000);
               })
               .catch(error => {
                   console.log(error);
                   Swal.fire(
                       'Failed',
                       'An error was occurred by approving your request.',
                       'warning'
                     )
               })
           }
         })
   }

   const deniedRequest = (request) => {
    // Preguntar al usuario

    Swal.fire({
       title: '¿Are you sure?',
       text: "",
       type: 'warning',
       showCancelButton: true,
       confirmButtonColor: '#3085d6',
       cancelButtonColor: '#d33',
       confirmButtonText: 'Yes, Send!',
       cancelButtonText: 'Cancel'
     }).then((result) => {
       //  console.log(pto);
       if (result.value) {
        //    clienteAxiosHR.post('/pto-email', ptoInfo)
        //    .then(respuesta => {
        //        //console.log(respuesta);
        //        Swal.fire(
        //            'Sent',
        //            'The Time off Request has been sent successfully.',
        //            'success'
        //          )

        //          setTimeout(() => {
        //            window.location.reload(false);
        //          }, 3000);
        //    })
        //    .catch(error => {
        //        console.log(error);
        //        Swal.fire(
        //            'Failed',
        //            'An error was occurred by sending your request.',
        //            'warning'
        //          )
        //    })
       }
     })
}

    return (
        <tr>
            <td>{request.employee_name}{' '}{request.employee_lastname}</td>
            <td>{request.department}</td>
            <td>
                <ul style={{padding:0}}>{
                    JSON.parse(request.equipment_requested).map((element, key) =>  (<li key={key} style={{textTransform:'uppercase'}}>{element.cantidad} - {element.tipo} </li>
                    ))
                    }
                </ul>
            </td>
            <td>{moment(request.request_date).format('MM-DD-YYYY')}</td>
            <td>{request.status}</td>
            <td className='acciones'>
                <Link
                        to={`/fixed-asset-request/view/${request.id}`}
                        className="btn btn-primary m-1"
                    >
                        Details
                </Link>
            {
                supervisor && request.status === 'New Request' && userInfo.department == request.department
                ?
                    <>
                        <button
                                className="btn btn-success m-1"
                                onClick={() => approveRequest(request)}
                        >
                                Approve
                        </button>

                        <button
                            className="btn btn-danger m-1"
                            onClick={() => deniedRequest(request)}
                        >
                                Denied
                        </button>
                    </>
                :
                    null

            }

            {
                userInfo.department === 'I.T.' && request.status === 'Under Review By I.T.'
                ?
                <Link
                    to={`/fixed-asset-request/edit/${request.id}`}
                    className="btn btn-success m-1"
                        >
                            Complete Asset Information
                    </Link>
                :
                null
            }

            {
                userInfo.department === 'Treasury' && request.status === 'Under Review By Treasury'
                ?
                <Link
                    to={`/fixed-asset-request/edit/${request.id}`}
                    className="btn btn-warning m-1"
                        >
                            Complete Purchase Information
                    </Link>
                :
                null
            }

            {
                userInfo.department === 'I.T.' && request.status === 'I.T. Department are completing information'
                ?
                <Link
                    to={`/fixed-asset-request/edit/${request.id}`}
                    className="btn btn-success m-1"
                        >
                            Complete Invoice Information
                    </Link>
                :
                null
            }

            {
                userInfo.department === 'Accounting' && request.status === 'Under Review By Accounting'
                ?
                <Link
                    to={`/fixed-asset-request/edit/${request.id}`}
                    className="btn btn-success m-1"
                        >
                            Complete Invoice Information
                    </Link>
                :
                null
            }

                
            </td>
        </tr>
    );
};

export default FixedAssetRequests;