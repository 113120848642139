import React from 'react';

import { useSelector } from 'react-redux';
import moment from 'moment';

const ViewUser = ({match, history}) => {

    // obtener el ID a editar
    const { uid } = match.params;

    const usersHour = useSelector(state=>state.manager.employees);

    // get user general data
    const employeeData = usersHour.filter(user => user.uid == uid);

    if(employeeData === undefined) {
        history.push('/home');
    }

    return (
        <div className="row justify-content-center mt-5">
            <div className="col-md-8">
                <div className="card">
                    <div className="card-body">
                        <h2 className="text-center mb-4 font-weight-bold ">Employee Profile</h2>
                        <form>
                            <div className="form-group">
                                <div className="col-sm-12 col-md-4 card-avatar-container mb-3">
                                    {/* Change avatar dinamically */}
                                    <img src={employeeData[0].avatar} className="card-img" alt="avatar" />
                                </div>
                            </div>
                            <div className="form-group">
                                <label>Employee Name</label>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    placeholder="Employee Name"
                                    value={employeeData[0].employee_name}
                                   // onChange={handleChangeInput}
                                    required
                                    readOnly
                                />
                            </div>

                            <div className="form-group">
                                <label>User Name</label>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    placeholder="Employee Name"
                                    value={employeeData[0].username}
                                   // onChange={e=>saveUserName(e.target.value)}
                                    required
                                    readOnly
                                />
                            </div>

                            <div className="form-group">
                                <label>Company</label>
                                <select className="custom-select"
                                // onChange={e=>saveCompany(e.target.value)}
                                 required
                                 readOnly
                                >
                                <option value={employeeData[0].company}>{employeeData[0].company}</option>
                                    
                                    
                                </select>
                            </div>

                            <div className="form-group">
                                <label>Department</label>
                                <select className="custom-select"
                                // onChange={e=>handleDepartmentSelect(e.target.value)}
                                 required
                                 readOnly
                                >
                                <option value={employeeData[0].department}>{employeeData[0].department}</option>
                                </select>
                            </div>

                            <div className="form-group">
                                <p><label>Supervisor</label></p>
                                <div className="custom-control custom-switch custom-control-inline">
                                    <input type="checkbox" className="custom-control-input" id="customSwitch2" 
                                    //onChange={e => handleCheckSupervisor(e.target.value) } 
                                    checked= {employeeData[0].supervisor ? true : false}
                                    readOnly
                                    />
                                    <label className="custom-control-label" htmlFor="customSwitch2">No / Yes</label>
                                </div>
                            </div>

                            <div className="form-group">
                                <label>Email</label>
                                <input 
                                    type="email" 
                                    className="form-control" 
                                    placeholder="Email"
                                   // onChange={ e => saveEmail(e.target.value) }
                                    required
                                    value={employeeData[0].email}
                                    readOnly
                                />
                            </div>

                            <div className="form-group ">
                                <label>Email Supervisor</label>
                                <input 
                                    type="email" 
                                    className="form-control" 
                                    placeholder="Email Superviosr"
                                  //  onChange={ e => saveEmailSupervisor(e.target.value) }
                                    value= {employeeData[0].supervisor_mail}
                                    required
                                    readOnly
                                />
                            </div>

                            <div className="form-group">
                                <label>MXL Portal User ID</label>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    placeholder="UID"
                                  //  onChange={ e => saveUid(e.target.value) }
                                    required
                                    value= {employeeData[0].uid}
                                    readOnly
                                />
                            </div>

                            <div className="form-group">
                                <label>MXL Portal Token</label>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    placeholder="Token"
                                  //  onChange={ e => saveToken(e.target.value) }
                                    value= {employeeData[0].token}
                                    required
                                    readOnly
                                />
                            </div>

                            <div className="row container justify-content-between">
                                
                                
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>Hire Date:</label>
                                        <input 
                                        type="text" 
                                        className="form-control"
                                       // onChange={e=>saveDayBeginning(e.target.value)}
                                        required
                                      //  ref = {dayBeginingRef}
                                        value = {moment(employeeData[0].hire_date).format('YYYY-MM-DD')}
                                        readOnly
                                        />
                                        {/* <DatePicker
                                        className="datePicker userdatepicker"
                                        todayButton="Today"
                                        selected={hireDate}
                                        onChange={date => saveHireDate(date)}
                                        filterDate={isWeekday}
                                        placeholderText="Select a weekday"
                                       // minDate={new Date()}
                                        excludeDates={excludedDatesArray} 
                                        /> */}

                                    </div>
                                </div>
                                
                            </div>

                            <div className="form-group">
                                <label>Territory</label>
                                <select className="custom-select"
                                // onChange={e=>saveTerritory(e.target.value)}
                                 required
                                 readOnly
                                >
                                <option value={employeeData[0].territory}>{employeeData[0].territory}</option>
                                </select>
                            </div>                  

                            <div className="form-group">
                                <p><label>Travel Benefits</label></p>
                                <div className="custom-control custom-switch custom-control-inline">
                                    <input type="checkbox" className="custom-control-input" id="customSwitch1" 
                                    //onChange={e => handleCheckTravelBenefits(e.target.value) } 
                                    checked= {employeeData[0].travel_benefits ? true : false}
                                    readOnly
                                    />
                                    <label className="custom-control-label" htmlFor="customSwitch1">No / Yes</label>
                                </div>
                            </div>
                        </form>
        
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ViewUser;