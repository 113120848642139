import React, {useEffect} from 'react';
import Pto from './Pto';

//Redux
import { useDispatch, useSelector } from 'react-redux';

import {getPTOListAction, getPtoListDepartmentSupervisor} from '../actions/timeoffrequestActions';
import {obtenerInformacionUsuario, obtenerBalanceHoras, ajustarHorasHireDate} from '../actions/authenticateActions';
import {obtenerEmpleadosManager, obtenerDepartamentos} from '../actions/manageActions';
import {getAllFixedAssetsRequestList} from '../actions/fixedassetrequestActions';
import Spinner from './helpers/Spinner';
import moment from 'moment';
import axiosHr from '../config/axiosHR';
import PtoGeneric from './PtoGeneric';
import { Link } from 'react-router-dom';


const Home = () => {

    // Mandar llamar la accion principal para retornar los PTO

    const dispatch = useDispatch();

    //acceder al state
    const userInfo = useSelector(state=>state.userInfo);
    const ptoList = useSelector(state => state.ptoList.ptoRequests);
    let year = moment().format('YYYY');

    console.log(year)
    useEffect(()=> {  
        
        // if(localStorage.getItem('company') !== 'CSI' || localStorage.getItem('email') === 'ventas.internal18@mexilink.com') {
        //     console.log('acceso permitido')
        // }else {
        //     console.log(userInfo.userInfo.username)
        //     localStorage.removeItem('token');
        
        //     setTimeout(() => {
            
        //    window.location.reload()
        //  }, 500);
        // } 
        console.log(moment(year+'-03-31').format('YYYY-MM-DD') >= moment().format('YYYY-MM-DD'),moment(year+'-03-31').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD'))
        const loadUser = ()=> dispatch(obtenerInformacionUsuario(userInfo.userInfo.username, null));
        loadUser();

        const loadFixedAsset = () => dispatch(getAllFixedAssetsRequestList());
        loadFixedAsset();

        if(userInfo.userInfo.supervisor || userInfo.userInfo.mangaer) {
            const loadPtoListSupervisor = ()=> dispatch(getPtoListDepartmentSupervisor(userInfo.userInfo.department, userInfo.userInfo.uid, userInfo.userInfo.email));
            loadPtoListSupervisor();
        }
        
        if(moment(year+'-03-31').format('YYYY-MM-DD') >= moment().format('YYYY-MM-DD') && userInfo.hours.carry_over > 0) {
             console.log('ajuste Carry OVer')
    
            if(userInfo.userInfo.uid !== undefined) {
                //  let data = {
                //      uid : userInfo.userInfo.uid,
                //      hours_before_mov:parseInt(userInfo.hours.available_hours),
                //      hours_after_mov:parseInt(userInfo.hours.available_hours),
                //      hours_mov: userInfo.hours.carry_over,
                //      carry_over_before_mov:parseInt(userInfo.hours.carry_over),
                //      carry_over_after_mov:0,
                //      observations: 'Carry Over Expiring Adjustment',
                //      type_mov: 'Expiring Carry Over',
                //      date_mov: moment().format('YYYY-MM-DD'),
                //      mov_increase_date: moment(userInfo.hours.increase_date).format('YYYY-MM-DD'),
                //      mov_yearly_increase_date:moment(userInfo.hours.year_increase_date).format('YYYY-MM-DD'),
                //      increase_date_before_mov:moment(userInfo.hours.increase_date).format('YYYY-MM-DD'),
                //      yearly_increase_date_before_mov:moment(userInfo.hours.year_increase_date).format('YYYY-MM-DD')
                //  }
    
                //  axiosHr.put('/adjust-carryover', data)
                //  .then(respuesta => {
                //      console.log(respuesta)
                //  })
                //  .catch(error => {
                //    console.log(error)
                //  });

                //  axiosHr.post('/hour-movement-log', data)
                //  .then(respuesta => {
                //      console.log(respuesta)
                //  })
                //  .catch(error => {
                //    console.log(error)
                //  });
            }
             
         }

        let data = {
            reportType:1,
            manager: userInfo.userInfo.manager,
            companyManager: userInfo.userInfo.company
        }
        const loadEmployeesManager = ()=>dispatch(obtenerEmpleadosManager(data));
        loadEmployeesManager();

        const loadDepartments = ()=> dispatch(obtenerDepartamentos());
        loadDepartments();

    },[userInfo.userInfo.hireDate, userInfo.hours.available_hours, userInfo.hours.increase_date])

    useEffect(()=> {

        const loadPtoList = ()=> dispatch(getPTOListAction(userInfo.userInfo.uid));
        loadPtoList();
        
        const loadHours = ()=> dispatch(obtenerBalanceHoras());
        loadHours();

        if(userInfo.userInfo.uid !== undefined) {
            // const loadSickDays = () => dispatch(obtenerSickDays(userInfo.userInfo.uid));
            // loadSickDays();
        }

        

        

        //if(moment(userInfo.userInfo.hireDate).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD') && userInfo.userInfo.hireDate !== undefined) {
            
       // }

      // console.log( moment('2020-03-31').diff(moment()).format('YYYY-MM-DD'))
    // console.log(moment('2020-03-31').format('YYYY-MM-DD') <= moment().format('YYYY-MM-DD'))

    

    },[dispatch, userInfo.userInfo.uid, ptoList.length]);

    // useEffect(()=> {
        
    //     const loadHours = ()=> dispatch(obtenerBalanceHoras());

    //     if(userInfo.userInfo.hireDate !== undefined && userInfo.userInfo.hireDate !== '' && userInfo.hours.travel_benefits !== undefined && userInfo.hours.travel_benefits === 0) {
                 
    //         let today = moment();
    //         let diffHireDate = moment(userInfo.userInfo.hireDate).diff(today, 'month') * -1;
    //         let diffToday = today.diff(moment(userInfo.hours.increase_date), 'month');
    //         let monthlyRaise 
    //         if(userInfo.userInfo.territory === 'Arizona' || userInfo.userInfo.territory === 'California') {
    //              monthlyRaise = 8 * diffToday;
    //         }else if(userInfo.userInfo.company === 'CSI') {
    //             monthlyRaise = 10 * diffToday;
    //         }else {
    //             monthlyRaise = 8 * diffToday;
    //         }

    //      //   console.log(monthlyRaise, 'este es monthly reaise'); 

    //         let diffHireDateYear = moment([userInfo.userInfo.hireDate, 0]).diff(today, 'year') * -1;
    //         let diffTodayYear = moment(today, 'YYYY').diff(moment(userInfo.hours.year_increase_date, 'YYYY'), 'year');

    //         if(monthlyRaise < 0) {
    //             monthlyRaise = 0;
    //         }

    //         let allIncrease = (8 * Math.floor((diffHireDateYear / 3))) + (monthlyRaise);

    //         console.log(allIncrease,diffTodayYear, diffHireDateYear, monthlyRaise );

    //         if(diffToday > 0 && diffTodayYear >= 1) {
    //             if(diffHireDate !== 0 && diffHireDate > 0 && diffTodayYear <= 15 && diffHireDateYear <=15) {
    //                 if(diffToday !==0 && diffToday > 0)  {
    //                     // const monthlyRaiseHireDate = ()=> dispatch(ajustarHorasHireDate({monthlyRaise: Math.floor(allIncrease) + parseInt(userInfo.hours.available_hours), uid: userInfo.userInfo.uid, increase_date: today.format('YYYY-MM-DD'), increase_type: 1 }))
    //                     // monthlyRaiseHireDate();
    //                     // let data = {
    //                     //     uid : userInfo.userInfo.uid,
    //                     //     hours_before_mov:userInfo.hours.available_hours,
    //                     //     hours_after_mov:Math.floor(allIncrease) + parseInt(userInfo.hours.available_hours),
    //                     //     hours_mov: Math.floor(allIncrease),
    //                     //     carry_over_before_mov:parseInt(userInfo.hours.carry_over),
    //                     //     carry_over_after_mov:parseInt(userInfo.hours.carry_over),
    //                     //     observations: 'Monthly Increase',
    //                     //     type_mov: 'Monthly Increase',
    //                     //     date_mov: moment().format('YYYY-MM-DD'),
    //                     //     mov_increase_date: moment(userInfo.hours.increase_date).format('YYYY-MM-DD'),
    //                     //     mov_yearly_increase_date:moment(userInfo.hours.year_increase_date).format('YYYY-MM-DD'),
    //                     //     increase_date_before_mov:today.format('YYYY-MM-DD'),
    //                     //     yearly_increase_date_before_mov:moment(userInfo.hours.year_increase_date).format('YYYY-MM-DD')
    //                     // }
    //                     // axiosHr.post('/hour-movement-log', data)
    //                     // .then(respuesta => {
    //                     //     console.log(respuesta)
    //                     // })
    //                     // .catch(error => {
    //                     // console.log(error)
    //                     // });
    //                     // loadHours();
    //                     // console.log("mensual")
    //                 }
    //             }

                

    //           //  console.log(diffHireDateYear, diffTodayYear, (diffTodayYear / 3));

    //      //     console.log(diffHireDate, diffToday);

    //             if(diffHireDateYear !== 0 && diffHireDateYear >= 3 && diffTodayYear <= 15 && diffHireDateYear <=15) {
    //                 if(diffTodayYear !== 0 && diffTodayYear >= 1) {
    //                     const yearlyRaiseHireDate = ()=> dispatch(ajustarHorasHireDate({monthlyRaise: Math.floor(allIncrease) + parseInt(userInfo.hours.available_hours), uid: userInfo.userInfo.uid, increase_date: today.format('YYYY-MM-DD'), increase_type: 2 }))
    //                     yearlyRaiseHireDate();
    //                     let data = {
    //                         uid : userInfo.userInfo.uid,
    //                         hours_before_mov:userInfo.hours.available_hours,
    //                         hours_after_mov:Math.floor(allIncrease) + parseInt(userInfo.hours.available_hours),
    //                         hours_mov: Math.floor(allIncrease),
    //                         carry_over_before_mov:parseInt(userInfo.hours.carry_over),
    //                         carry_over_after_mov:parseInt(userInfo.hours.carry_over),
    //                         carry_over_sick_time_before:parseInt(userInfo.hours.carry_over_sick_time),
    //                         carry_over_sick_time_after:parseInt(userInfo.hours.carry_over_sick_time),
    //                         observations: 'Seniority in company increment',
    //                         type_mov: 'Seniority in company increment',
    //                         date_mov: moment().format('YYYY-MM-DD'),
    //                         mov_increase_date: moment(userInfo.hours.increase_date).format('YYYY-MM-DD'),
    //                         mov_yearly_increase_date:moment(userInfo.hours.year_increase_date).format('YYYY-MM-DD'),
    //                         increase_date_before_mov:moment(userInfo.hours.increase_date).format('YYYY-MM-DD'),
    //                         yearly_increase_date_before_mov:today.format('YYYY-MM-DD')
    //                     }
    //                     axiosHr.post('/hour-movement-log', data)
    //                     .then(respuesta => {
    //                         console.log(respuesta)
    //                     })
    //                     .catch(error => {
    //                     console.log(error)
    //                     });
    //                     loadHours();
    //                     console.log("anual");
    //                 }
    //             }
    //         } else {

    //             if(diffHireDate !== 0 && diffHireDate > 0 && diffTodayYear <= 15 && diffHireDateYear <=15) {
    //                 if(diffToday !==0 && diffToday > 0)  {
    //                 // const monthlyRaiseHireDate = ()=> dispatch(ajustarHorasHireDate({monthlyRaise: Math.floor(monthlyRaise) + parseInt(userInfo.hours.available_hours), uid: userInfo.userInfo.uid, increase_date: today.format('YYYY-MM-DD'), increase_type: 1 }))
    //                 // monthlyRaiseHireDate();
    //                 // let data = {
    //                 //     uid : userInfo.userInfo.uid,
    //                 //     hours_before_mov:userInfo.hours.available_hours,
    //                 //     hours_after_mov:Math.floor(allIncrease) + parseInt(userInfo.hours.available_hours),
    //                 //     hours_mov: Math.floor(allIncrease),
    //                 //     carry_over_before_mov:parseInt(userInfo.hours.carry_over),
    //                 //     carry_over_after_mov:parseInt(userInfo.hours.carry_over),
    //                 //     observations: 'Monthly Increase',
    //                 //     type_mov: 'Monthly Increase',
    //                 //     date_mov: moment().format('YYYY-MM-DD'),
    //                 //     mov_increase_date: moment(userInfo.hours.increase_date).format('YYYY-MM-DD'),
    //                 //     mov_yearly_increase_date:moment(userInfo.hours.year_increase_date).format('YYYY-MM-DD'),
    //                 //     increase_date_before_mov:today.format('YYYY-MM-DD'),
    //                 //     yearly_increase_date_before_mov:moment(userInfo.hours.year_increase_date).format('YYYY-MM-DD')
    //                 // }
    //                 // axiosHr.post('/hour-movement-log', data)
    //                 // .then(respuesta => {
    //                 //     console.log(respuesta)
    //                 // })
    //                 // .catch(error => {
    //                 // console.log(error)
    //                 // });
    //                 // loadHours();
    //                 // console.log("mensual")
    //                 }
    //             }

                

    //          //   console.log(diffHireDateYear, diffTodayYear, (diffTodayYear / 3));
    //         //  console.log(diffHireDate, diffToday);

    //             if(diffHireDateYear !== 0 && diffHireDateYear >= 3 && diffTodayYear <= 15 && diffHireDateYear <=15) {
    //                 if(diffTodayYear !== 0 && diffTodayYear >= 1) {
    //                     const yearlyRaiseHireDate = ()=> dispatch(ajustarHorasHireDate({monthlyRaise: 8 *  Math.floor((diffHireDateYear / 3)) + parseInt(userInfo.hours.available_hours), uid: userInfo.userInfo.uid, increase_date: today.format('YYYY-MM-DD'), increase_type: 2 }))
    //                     yearlyRaiseHireDate();
    //                     let data = {
    //                         uid : userInfo.userInfo.uid,
    //                         hours_before_mov:userInfo.hours.available_hours,
    //                         hours_after_mov:Math.floor(allIncrease) + parseInt(userInfo.hours.available_hours),
    //                         hours_mov: Math.floor(allIncrease),
    //                         carry_over_before_mov:parseInt(userInfo.hours.carry_over),
    //                         carry_over_after_mov:parseInt(userInfo.hours.carry_over),
    //                         carry_over_sick_time_before:parseInt(userInfo.hours.carry_over_sick_time),
    //                         carry_over_sick_time_after:parseInt(userInfo.hours.carry_over_sick_time),
    //                         observations: 'Seniority in company increment',
    //                         type_mov: 'Seniority in company increment',
    //                         date_mov: moment().format('YYYY-MM-DD'),
    //                         mov_increase_date: moment(userInfo.hours.increase_date).format('YYYY-MM-DD'),
    //                         mov_yearly_increase_date:moment(userInfo.hours.year_increase_date).format('YYYY-MM-DD'),
    //                         increase_date_before_mov:moment(userInfo.hours.increase_date).format('YYYY-MM-DD'),
    //                         yearly_increase_date_before_mov:today.format('YYYY-MM-DD')
    //                     }
    //                     axiosHr.post('/hour-movement-log', data)
    //                     .then(respuesta => {
    //                         console.log(respuesta)
    //                     })
    //                     .catch(error => {
    //                     console.log(error)
    //                     });
    //                     loadHours();
    //                     console.log("anual");
    //                 }
    //             }
    //         }

            
    //         //console.log(diffHireDate, diffToday, today, monthlyRaise);          
    //     }
    // },[userInfo.userInfo.hireDate])
    return (

        
        
        userInfo.userInfo.name !== undefined || userInfo.userInfo.uid !== undefined
        ? 
            <React.Fragment>
                
                <h2 className="text-center my-5">TIME OFF REQUEST</h2>

                <div className="card mb-3 avatar" >
                    <div className="row no-gutters">
                        <div className="col-sm-12 col-md-4 card-avatar-container">
                            {/* Change avatar dinamically */}
                            <img src={userInfo.userInfo.avatarUrl} className="card-img" alt="avatar" />
                        </div>
                        <div className="col-md-8">
                            <div className="card-body ">
    <h4 className="card-title">{userInfo.userInfo.name}{' '}{userInfo.userInfo.lastname}</h4>
                                <p className="home-paragraph">{userInfo.userInfo.department}</p>
                                {
                                // userInfo.hours.available_hours > 0
                               //    ?

                                    // <p className="general-summary text-muted home-paragraph since-paragraph">
                                    //   <span className="font-weight-bold">Available PTO Hours:</span> {userInfo.hours.available_hours} {" "} ({

                                    //  userInfo.hours.available_hours - (Math.floor((moment(userInfo.userInfo.hireDate).diff(moment(), 'year') * -1)/(3)) * 8)
                                    // //userInfo.hours.available_hours
                                    
                                    // } hours + {
                                    
                                    // (Math.floor((moment(userInfo.userInfo.hireDate).diff(moment(), 'year') * -1)/(3)) * 8)
                                    
                                    // } hours earned for loyalty)
                                    
                                    // </p>
                                //   : 
                                    
                                    <p className="general-summary text-muted home-paragraph">
                                        <span className="font-weight-bold">Available PTO Hours:</span> {userInfo.hours.available_hours} hours
                                    </p>
                                }
                                <p className="general-summary text-muted home-paragraph"><span className="font-weight-bold">Available Sick Hours:</span> {userInfo.hours.sick_days_limit} hours</p>
                                <p className="general-summary text-muted home-paragraph"><span className="font-weight-bold">Available Carry Over Hours:</span> {userInfo.hours.carry_over} hours</p>
                                {
                                userInfo.userInfo.territory === 'California'
                                 ?
                                 <p className="general-summary text-muted home-paragraph"><span className="font-weight-bold">Available Carry Over Sick Hours:</span> {userInfo.hours.carry_over_sick_time} hours</p>
                                 :
                                  null
                                }
                                                                <p className="general-summary text-muted home-paragraph"><span className="font-weight-bold">Total Annual Days:</span> {(userInfo.hours.available_hours + userInfo.hours.carry_over + userInfo.hours.sick_days_limit + userInfo.hours.carry_over_sick_time) / 8} days</p>
                               
                                {/* <p className="general-summary text-muted">Joined the company since: {moment().to(moment(userInfo.userInfo.hireDate))}</p> */}
                                <p className="general-summary text-muted home-paragraph since-paragraph">Joined the company since: {moment(userInfo.userInfo.hireDate).format('MMM-YYYY')}</p>
                                {
                                    (Math.floor((moment([userInfo.userInfo.hireDate, 0]).diff(moment(), 'year') * -1)/(3)) * 8) >= 41
                                    ?
                                        <p className="general-summary text-muted home-paragraph ">(40 hours for seniority in company already included)</p>
                                    :
                                        <p className="general-summary text-muted home-paragraph ">({(Math.floor((moment([userInfo.userInfo.hireDate, 0]).diff(moment(), 'year') * -1)/(3)) * 8)} hours for seniority in company already included)</p>
                                }
                                
                                {userInfo.hours.carry_over > 0
                                ?
                                // <div className="alert alert-success carryOverAlert" role="alert">
                                //     Your Carry Over expires on: 03-31-2020
                                // </div> null provisional mientras se vuelve a activar carry over
                                null
                                :
                                null
                                }


                                <Link className="btn btn-primary mt-1" to={`/users/profile/${userInfo.userInfo.uid}`}> View Profile</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <table className="table table-striped table-to-card">
                    <thead className="bg-primary table-dark">
                        <tr>
                            <th scope="col">Employee</th>
                            <th scope="col">Department</th>
                            <th scope="col">Status</th>
                            <th scope="col">Created at</th>
                            <th scope="col">Beginning</th>
                            <th scope="col">End</th>
                            <th scope="col">Delivery Status</th>
                            <th scope="col">Reason</th>
                            <th scope="col">Hours</th>
                            <th scope="col">Actions</th>
                        </tr>   
                    </thead>
                    <tbody>
                        {ptoList.map(pto =>(
                            <PtoGeneric 
                            key={pto.id}
                            pto = {pto}
                            />
                        ))}
                        
                    </tbody>
                </table>
                
                
    
            </React.Fragment>
            :
            <Spinner />
    );
};

export default Home;