import {
    OBTENER_INFORMACION_USUARIO,
    OBTENER_INFORMACION_USUARIO_EXITO,
    OBTENER_INFORMACION_USUARIO_ERROR,
    OBTENER_BALANCE_HORAS,
    OBTENER_BALANCE_HORAS_EXITO,
    OBTENER_BALANCE_HORAS_ERROR,
    COMENZAR_AJUSTE_HORAS,
    COMENZAR_AJUSTE_HORAS_EXITO,
    COMENZAR_AJUSTE_HORAS_ERROR,
    OBTENER_USUARIO_AJUSTE,
    OBTENER_USUARIO_AJUSTE_EXITO,
    OBTENER_USUARIO_AJUSTE_ERROR,
    COMENZAR_AJUSTE_MENSUAL_HORAS,
    COMENZAR_AJUSTE_MENSUAL_HORAS_EXITO,
    COMENZAR_AJUSTE_MENSUAL_HORAS_ERROR,
    CREAR_USUARIO,
    CREAR_USUARIO_EXITO,
    CREAR_USUARIO_ERROR,
    EDITAR_USUARIO,
    EDITAR_USUARIO_EXITO,
    EDITAR_USUARIO_ERROR,
    EDITAR_PROFILE,
    EDITAR_PROFILE_EXITO,
    EDITAR_PROFILE_ERROR
} from '../types';

const intialState = {
    userInfo: {},
    error:false,
    hours: {},
    userHour: []
}

export default function(state=intialState, action) {
    switch(action.type) {
        case OBTENER_INFORMACION_USUARIO:
            return {
                ...state,
                error: false,
            }
        case OBTENER_INFORMACION_USUARIO_EXITO:
            return {
                ...state,
                userInfo: action.payload,
                error: false
            }
        case OBTENER_INFORMACION_USUARIO_ERROR:
            return {
                ...state,
                error: true
            }
        case OBTENER_BALANCE_HORAS:
            return {
                ...state,
                error: false
            }
        case OBTENER_BALANCE_HORAS_EXITO:
            return {
                ...state,
                hours: action.payload
            }
        case OBTENER_BALANCE_HORAS_ERROR:
            return {
                ...state,
                error: true
            }
        case COMENZAR_AJUSTE_HORAS:
            return {
                ...state,
                error: false
            }
        case COMENZAR_AJUSTE_HORAS_EXITO:
            return {
                ...state,
                error: false,
                hours: action.payload
            }
        case COMENZAR_AJUSTE_HORAS_ERROR:
            return {
                ...state,
                error: true
            }
        case OBTENER_USUARIO_AJUSTE:
            return {
                ...state,
                error: false
            }
        case OBTENER_USUARIO_AJUSTE_EXITO:
            return {
                ...state,
                userHour: action.payload,
                error: false
            }
        case OBTENER_USUARIO_AJUSTE_ERROR:
            return {
                ...state,
                error: true
            }
            case COMENZAR_AJUSTE_MENSUAL_HORAS:
            return {
                ...state,
                error: false
            }
        case COMENZAR_AJUSTE_MENSUAL_HORAS_EXITO:
            return {
                ...state,
                userHour: action.payload,
                error: false
            }
        case COMENZAR_AJUSTE_MENSUAL_HORAS_ERROR:
            return {
                ...state,
                error: true
            }
        case CREAR_USUARIO:
            return {
                ...state,
                error: false
            }
        case CREAR_USUARIO_EXITO:
            return {
                ...state,
               // userInfo: action.payload,
                error: false
            }
        case CREAR_USUARIO_ERROR:
            return {
                ...state,
                error: true
            }
        case EDITAR_USUARIO:
            return {
                ...state,
                error: false,
            }
        case EDITAR_USUARIO_EXITO:
            return {
                ...state,
                error: false,
                userHour: state.userHour.map(user => user.id === action.payload.id ? user = action.payload : user),
             //   userInfo: {}    
            }
        case EDITAR_USUARIO_ERROR:
            return {
                ...state,
                error: true
            }
            case EDITAR_PROFILE:
                return {
                    ...state,
                    error: false,
                }
            case EDITAR_PROFILE_EXITO:
                return {
                    ...state,
                    error: false,
                    userHour: state.userHour.map(user => user.id === action.payload.id ? user = action.payload : user),
                 //   userInfo: {}    
                }
            case EDITAR_PROFILE_ERROR:
                return {
                    ...state,
                    error: true
                }
        default:
            return state;
    }
}