import {createStore, applyMiddleware, compose} from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers';

const initialState = {};

// const middleware = [thunk];

// const store = createStore(
//     rootReducer,
//     initialState,
//     compose(applyMiddleware(...middleware),
//         window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
//     )
// );

// const createStoreWithMiddleware = createStore(
//     rootReducer,
//     compose(
//       applyMiddleware(thunk),
//       window.navigator.userAgent.includes('Chrome') ?
//         window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__() : compose,
//     ),
//   );

//export default store;
//export default createStoreWithMiddleware;

export default createStore(  
    rootReducer,
    initialState,
    compose(
      applyMiddleware(thunk),
      typeof window.__REDUX_DEVTOOLS_EXTENSION__ === "undefined"
        ? a => a
        : window.__REDUX_DEVTOOLS_EXTENSION__ &&
            window.__REDUX_DEVTOOLS_EXTENSION__()
    )
  );