import React, {useState, useEffect, useRef} from 'react';
//import ReactDom from 'react-dom';

// Redux
import { validarFormularioAction, validacionExito, validacionError } from '../actions/validacionActions';
import {createNewFixedAssetRequestAction} from '../actions/fixedassetrequestActions';
import { useDispatch, useSelector } from 'react-redux';
import {obtenerDepartamentos} from '../actions/manageActions';

// library
import SignatureCanvas from 'react-signature-canvas';
import moment from 'moment-business-days';
import Swal from 'sweetalert2';
import DatePicker from "react-datepicker";
import axiosHr from '../config/axiosHR';
import clienteAxiosHR from '../config/axiosHR';
 
import "react-datepicker/dist/react-datepicker.css";

const NewFixedAsset = ({history}) => {

     var holidaysArray = ['04-02-2021', '05-31-2021', '07-05-2021', '09-06-2021', '11-25-2021', '11-26-2021', '12-23-2021', '12-24-2020', '01-01-2021', '01-01-2022', '04-15-2022', '05-30-2022', '07-04-2022', '09-05-2022', '11-24-2022', '11-25-2022', '12-23-2022', '12-26-2022', '01-02-2023', '04-07-2023', '07-04-2023', '05-29-2023', '09-04-2023', '11-23-2023', '11-24-2023', '12-22-2023', '12-25-2023', '01-01-2024', '03-29-2024', '05-27-2024', '04-07-2024', '09-02-2024', '11-28-2024', '11-29-2024', '12-24-2024', '12-25-2024'];

    var excludedDatesArray = [new Date("2021-02-04T12:00:00-00:00"), new Date("2021-05-31T12:00:00-00:00"), new Date("2021-07-05T12:00:00-00:00"), new Date("2021-09-06T12:00:00-00:00"), new Date("2021-11-25T12:00:00-00:00"), new Date("2021-11-26T12:00:00-00:00"), new Date("2021-12-23T12:00:00-00:00"), new Date("2021-12-24T12:00:00-00:00"),new Date("2022-01-01T12:00:00-00:00"), new Date("2021-01-01T12:00:00-00:00"), new Date("2022-04-15T12:00:00-00:00"), new Date("2022-05-30T12:00:00-00:00"), new Date("2022-07-04T12:00:00-00:00"), new Date("2022-09-05T12:00:00-00:00"), new Date("2022-11-24T12:00:00-00:00"), new Date("2022-11-25T12:00:00-00:00"), new Date("2022-12-23T12:00:00-00:00"), new Date("2022-12-26T12:00:00-00:00"), new Date("2023-01-02T12:00:00-00:00"), new Date("2023-04-07T12:00:00-00:00"), new Date("2023-05-29T12:00:00-00:00"), new Date("2023-07-04T12:00:00-00:00"), new Date("2023-09-04T12:00:00-00:00"), new Date("2023-11-23T12:00:00-00:00"), new Date("2023-11-24T12:00:00-00:00"), new Date("2023-12-22T12:00:00-00:00"), new Date("2023-12-25T12:00:00-00:00"), new Date("2024-01-01T12:00:00-00:00"), new Date("2024-03-29T12:00:00-00:00"), new Date("2024-05-27T12:00:00-00:00"), new Date("2024-07-04T12:00:00-00:00"), new Date("2024-09-02T12:00:00-00:00"), new Date("2024-11-28T12:00:00-00:00"), new Date("2024-11-29T12:00:00-00:00"), new Date("2024-12-24T12:00:00-00:00"), new Date("2024-12-25T12:00:00-00:00"), new Date("2025-01-01T12:00:00-00:00")]
   

    // set working days
    moment.updateLocale('us', {
        workingWeekdays: [1, 2, 3, 4, 5],
        holidays: holidaysArray,
        holidayFormat: 'MM-DD-YYYY'

     });


    // get data from state
    const error = useSelector((state) => state.error.error);
    //acceder al state
    const userInfo = useSelector(state=>state.userInfo);

    // acceder al state de la lista de empleados
    const employees = useSelector(state=>state.manager.employees);

    // obtener departamentos
    const departments = useSelector(state=>state.manager.departments);

    const filterDepartment  = (array, query) => {
        return array.filter(function(el) {
            let departmentElement = el.department
            return departmentElement.toLowerCase().indexOf(query.toLowerCase()) > -1;
        })
      }

    // agregar balance de horas

   // const supervisor = userInfo.userInfo.supervisor;

   // const email = userInfo.userInfo.email;

   // const supervisorEmail = userInfo.userInfo.supervisorEmail;
    
    //const company = userInfo.userInfo.company;

    // state
    const [employeeName, saveEmployeeName] = useState('');
    const [supervisorEmail, saveSupervisorEmail] = useState(userInfo.userInfo.supervisorEmail);
    const [email, saveEmail] = useState(userInfo.userInfo.email);
    const [company, saveCompany] = useState(userInfo.userInfo.company);
    const [supervisor, saveSupervisor] = useState(userInfo.userInfo.supervisor);
    const [department, saveDepartment] = useState('');
    const [jobDescription, saveJobDescription] = useState('');
    const [territory, saveTerritory] = useState('');
    // const [equipmenteRequested, saveEquipmenteRequested] = useState('');
    const [equipos, setEquipos] = useState([]);
    const [tipoEquipo, setTipoEquipo] = useState('');
    const [cantidad, setCantidad] = useState(1);

    const [reason, saveReason] = useState('');
    const [costCenter, saveCostCenter] = useState('');
    const [status, saveStatus] = useState('New Request');
   
    const [signatureEmployee, saveSignatureEmployee] = useState('');
    const [dateSignEmployee, saveDateSignEmployee] = useState(new Date());
    const [uid, saveUid] = useState('');
   
    const [departmentColor, saveDepartmentColor] = useState('');
    const [HrRequest, saveHrRequest] = useState(false);

  

    const isWeekday = date => {
        const day = moment(date).day();
        return day !== 0 && day !== 6;
      };


      const agregarEquipo = () => {
        if (tipoEquipo && cantidad > 0) {
          setEquipos([...equipos, { tipo: tipoEquipo, cantidad }]);
          setTipoEquipo('');
          setCantidad(1);
        }
      };
    
      const eliminarEquipo = (index) => {
        const nuevosEquipos = equipos.filter((_, i) => i !== index);
        setEquipos(nuevosEquipos);
      };
   

    // canvas
    
    const sendEmail = (pto, supervisorEmail, email, company) => {

        const ptoInfo = {
            pto,
            supervisorEmail,
            email,
            company
          }

        clienteAxiosHR.post('/pto-email', ptoInfo)
                .then(respuesta => {
                    //console.log(respuesta);
                    Swal.fire(
                        'Sent',
                        'The Time off Request has been sent successfully.',
                        'success'
                      )

                    //   setTimeout(() => {
                    //     window.location.reload(false);
                    //   }, 3000);
                })
                .catch(error => {
                    console.log(error);
                    Swal.fire(
                        'Failed',
                        'An error was occurred by sending your request.',
                        'warning'
                      )
                })
    }    


    let sigPadEmployee = {};
    const clearEmployee = (e) => {
        e.preventDefault();
        sigPadEmployee.clear();
      }
    const saveCanvasEmployee = (e)=> {
        e.preventDefault();
        let urlImage = sigPadEmployee.getTrimmedCanvas().toDataURL('image/png');
        saveSignatureEmployee(urlImage);
    }

    const handleChangeInput = (event) => {
        const {value} = event.target;
        let regex = new RegExp("^[a-zA-Z0-9áéíóúÁÉÍÓÚ,. ]+$");

        if(regex.test(value)) {
           // console.log(value);
            saveReason(value);
        } else {
            console.log("Es un caracter no válido")
        }
    }

    const handleEmployeeSelect = (event) => {
        const {value} = event.target;
        // extraer la información de empleado del array de empleados
        let employee = employees.filter(employee => employee.email === value);

        saveEmployeeName(employee[0].employee_name + ' ' + employee[0].employee_lastname);
        saveJobDescription(employee[0].job_title);
        saveTerritory(employee[0].territory);
        saveUid(employee[0].uid);
        saveDepartment(employee[0].department);

        saveSupervisor(employee[0].supervisor);
        saveSupervisorEmail(employee[0].supervisor_email);
        saveCompany(employee[0].company);
        saveEmail(employee[0].email);

        let departmentFilter = filterDepartment(departments, employee[0].department);
            
        saveDepartmentColor(departmentFilter[0].color);
        saveCostCenter(departmentFilter[0].cost_center);
        saveTerritory(employee[0].territory);
    }

    // dispatch actions
    const dispatch = useDispatch();
     const agregarFixedAsset = (request) => dispatch (createNewFixedAssetRequestAction(request));
    // const balanceHoras = (horas) => dispatch(ajustarBalanceHoras(horas));
    const validarFormulario = () => dispatch( validarFormularioAction());
    const exitoValidacion = () => dispatch( validacionExito() ) ;
    const errorValidacion = () => dispatch( validacionError() ) ;
    const loadDepartments = ()=> dispatch(obtenerDepartamentos());
       
    useEffect(() => {
        loadDepartments();
    }, [])


    useEffect( ()=>{


        if(userInfo.userInfo.name !== undefined){
            saveEmployeeName(userInfo.userInfo.name +' '+ userInfo.userInfo.lastname);
           saveJobDescription(userInfo.userInfo.job_title);
           saveTerritory(userInfo.userInfo.territory);
        } else {
            history.push('/home');
        }

        if(userInfo.userInfo.department !== undefined) {
            saveDepartment(userInfo.userInfo.department);
            let departmentFilter = filterDepartment(departments, userInfo.userInfo.department);
            
            saveDepartmentColor(departmentFilter[0].color);
            saveCostCenter(departmentFilter[0].cost_center);
            
        }

        if(userInfo.userInfo.uid !== undefined) {
            saveUid(userInfo.userInfo.uid);
        }

        if(userInfo.userInfo.department === 'Human Resources') {
            saveHrRequest(true);
        }

             

    }, [dispatch, userInfo, history])
    
    // Add new Request
    const submitNewFixedAssetRequest = e => {
      e.preventDefault();
    //  console.log(carryOver)
      validarFormulario();
      if(employeeName.trim() === ''|| department.trim() === '' || signatureEmployee.trim() === '' || dateSignEmployee === 'Invalid date' ) {
          errorValidacion();
          return
      }

      exitoValidacion();

      // Preguntar al usuario
      Swal.fire({
        title: 'Request Summary',
        text: "This is your summary request, are you sure to submit ?",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        // html: '<div>'+
        //             '<p> Name : ' + employeeName + '</p>' +
        //             '<p> Department : ' + department + '</p>' +
        //             //00'<p> Reason : ' + reason + ' </p>' +
        //            //'<p> Total Hours: ' + totalHours + '</p>' +
        //             // '<p> Absence Code: ' + absenceText + '</p>' +
        //          '</div>'
      }).then((result) => {
        if (result.value) {
          Swal.fire(
            'Saved',
            'Your request has been sent successfully',
            'success'
          )
          let dateSignature = moment(dateSignEmployee).format('YYYY-MM-DD');
         

          //const company = userInfo.userInfo.company
          let equiposFormatted = JSON.stringify(equipos);

          let fixedAssetRequest = {
            employeeName,
            department,
            signatureEmployee,
            dateSignature,
            uid,
            supervisor,
            departmentColor,
            email,
            company,
            supervisorEmail,
            equiposFormatted,
            jobDescription,
            territory,
            costCenter,
            reason,
            HrRequest,
            status
        }


        agregarFixedAsset(fixedAssetRequest);


        setEquipos([]);
            //redirect
            history.push('/home');
        }
    })
      
      
    }

    return (
        <div className="row justify-content-center mt-5">
            
                <div className="col-md-8">
                <div className="card">
                    <div className="card-body">
                        <h2 className="text-center mb-4 font-weight-bold ">Add New Fixed Asset Request</h2>
                        <form onSubmit={submitNewFixedAssetRequest}>
                            <div className="form-group">
                                <label>Employee Name</label>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    placeholder="Employee Name" 

                                    defaultValue={userInfo.userInfo.name +' '+userInfo.userInfo.lastname}
                                    onChange={ e => saveEmployeeName(e.target.value) }
                                    required
                                    disabled
                                />
                            </div>
                            <div className="row justify-content-between">
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>Position</label>
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            placeholder="Position" 

                                            defaultValue={userInfo.userInfo.job_title}
                                            onChange={ e => saveJobDescription(e.target.value) }
                                            required
                                            disabled
                                        />
                                    </div>
                                </div>

                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>State</label>
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            placeholder="State" 

                                            defaultValue={userInfo.userInfo.territory}
                                            onChange={ e => saveJobDescription(e.target.value) }
                                            required
                                            disabled
                                        />
                                    </div>
                                </div>

                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>Department</label>
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            placeholder="Department" 
                                            defaultValue={userInfo.userInfo.department}
                                            onChange={ e => saveDepartment(e.target.value) }
                                            required
                                            disabled
                                        />
                                    </div>
                                </div>

                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>Cost Center</label>
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            placeholder="Cost Center" 

                                            defaultValue=''
                                            onChange={ e => saveCostCenter(e.target.value) }
                                            required
                                            disabled
                                        />
                                    </div>
                                </div>

                                {userInfo.userInfo.department === 'Human Resources'
                                   ?
                                <div className='col-md-6 col-sm-12'>
                                    <div className='form-group'>
                                        <label htmlFor="employeeSelect">Select Employee:</label>
                                        <select id="employeeSelect" name="employeeSelect" className='form-control' onChange={(event) => handleEmployeeSelect(event)}>
                                            <option value="">Select...</option>
                                            {employees.map(employee => (
                                                <option key={employee.id} value={employee.email}>
                                                    {employee.email}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                :null
                                    }

                                <div className='row justify-content-center align-items-center container'>
                                    <div className="col-md-8 col-sm-12">
                                        <div className="form-group">
                                            <label>Equipment Requested</label>
                                            <select className='form-control' value={tipoEquipo} onChange={(e) => setTipoEquipo(e.target.value)}>
                                                <option value="">Select ...</option>
                                                <option value="laptop">Laptop</option>
                                                <option value="cellphone">Cellphone</option>
                                                <option value="tablet">Tablet</option>
                                                <option value="keyboard">Keyboard</option>
                                                <option value="mouse">Mouse</option>
                                                <option value="monitor">Monitor</option>
                                                
                                            </select>
                                        </div>
                                    </div>

                                    {/* <div className='col-md-2 col-sm-12'>
                                        <div className='form-group'>
                                            <label>
                                                Quantity:
                                                <input
                                                    className='form-control' 
                                                    type="number" 
                                                    value={cantidad} 
                                                    onChange={(e) => setCantidad(Number(e.target.value))} 
                                                    min="1"
                                                />
                                            </label>
                                        </div>
                                    </div> */}

                                    <div className='col-md-4 col-sm-12'>
                                        <button className='btn btn-warning bt-lg p-4' onClick={()=> agregarEquipo()}>Add</button>
                                    </div>
                                </div>

                                <div className='container'>
                                <h5>Asset List:</h5>
                               
                                    
                                    <div className='col-md-12 p-3' style={{background:'white', borderRadius:'5px', border:'1px solid lightgray'}}>
                                    
                                        <ul>
                                            {equipos.map((equipo, index) => (
                                            <li className='mt-2' key={index}>
                                                <span style={{textTransform: 'uppercase'}} className='mr-2'>{equipo.tipo}</span> - <span style={{textTransform:'uppercase'}} className='mr-2'>QUANTITY: {equipo.cantidad}</span> 
                                                <button className='btn btn-danger p-2' onClick={() => eliminarEquipo(index)}>X</button>
                                            </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>

                                <div className="col-md-12 col-sm-12">
                                    <div className="form-group">
                                        <label>Reason</label>
                                            <textarea 
                                                className="form-control"
                                                value={reason}
                                                //onChange={e=>saveReason(e.target.value)}
                                                onChange = {handleChangeInput}
                                                required
                                                >
                                            </textarea>
                                    </div>
                                </div>
                            </div>
                        
                            

                            <div className="row container justify-content-between">
                                
                                
                                <div className="col-md-8 col-sm-12">
                                 <label>Employee Signature:</label>
                                    <div className="form-group signatureContainer">
                                        
                                        <SignatureCanvas penColor = 'black' canvasProps={{
                                            //width:'100%',
                                            //height: '100%',
                                            className: 'signatureCanvas'
                                        }} ref={ref => {sigPadEmployee = ref}} 
                                        onEnd = {saveCanvasEmployee}
                                        />
                                        {/* <button className="btn btn-secondary mt-2" onClick={saveCanvasEmployee}>Save Signature</button> */}
                                        
                                    </div>
                                    <button className="btn btn-secondary mt-2" onClick={clearEmployee}>Clear X</button>
                                </div>

                                <div className="col-md-4 col-sm-12">
                                    <div className="form-group">
                                        <label>Date:</label>
                                        {/* <input 
                                        type="date" 
                                        className="form-control"
                                        onChange={e=>saveDateSignEmployee(e.target.value)}
                                        /> */}
                                        <DatePicker
                                        className="datePicker"
                                        todayButton="Today"
                                        selected={dateSignEmployee}
                                        onChange={date => saveDateSignEmployee(date)}
                                        filterDate={isWeekday}
                                        placeholderText="Select a weekday"
                                        minDate={new Date()}
                                        excludeDates={excludedDatesArray}
                                        required
                                        disabled
                                        />
                                    </div>
                                </div>
                                
                            </div>


                            <button type="submit" className="btn btn-primary font-weight-bold text-uppercase d-block w-100 mb-4 mt-4">Submit</button>
                        </form>

                        { error ? <div className="font-weight-bold alert alert-danger text-center mt-4">ERROR: Please fill out all the required fields. </div> : null }
        
                        </div>
                    </div>
                </div>
            
        </div>
    );

    
};

export default NewFixedAsset;