import {
    OBTENER_DEPARTAMENTOS,
    OBTENER_DEPARTAMENTOS_EXITO,
    OBTENER_DEPARTAMENTOS_ERROR,
    OBTENER_SICKDAYS,
    OBTENER_SICKDAYS_EXITO,
    OBTENER_SICKDAYS_ERROR,
    OBTENER_COMPANIES,
    OBTENER_COMPANIES_EXITO,
    OBTENER_COMPANIES_ERROR,
    OBTENER_TERRITORY,
    OBTENER_TERRITORY_EXITO,
    OBTENER_TERRITORY_ERROR,
    OBTENER_EMPLOYEES_MANAGER,
    OBTENER_EMPLOYEES_MANAGER_EXITO,
    OBTENER_EMPLOYEES_MANAGER_ERROR,
    OBTENER_HISTORICO,
    OBTENER_HISTORICO_EXITO,
    OBTENER_HISTORICO_ERROR,
    COMENZAR_EDITAR_DEPARTMENT,
    COMENZAR_EDITAR_DEPARTMENT_EXITO,
    COMENZAR_EDITAR_DEPARTMENT_ERROR,
    COMENZAR_CREAR_DEPARTMENT,
    COMENZAR_CREAR_DEPARTMENT_EXITO,
    COMENZAR_CREAR_DEPARTMENT_ERROR,
    OBTENER_ESTADOS_ACTIVOS,
    OBTENER_ESTADOS_ACTIVOS_EXITO,
    OBTENER_ESTADOS_ACTIVOS_ERROR,
} from '../types';

import axiosHr from '../config/axiosHR';

export function obtenerDepartamentos() {
    return (dispatch) => {
        dispatch(obtenerDepartamentosInicio());

        axiosHr.get('/departments')
        .then(respuesta => {
            //console.log(respuesta.data.data);
            dispatch(obtenerDepartamentosExito(respuesta.data.data));
        })
        .catch(error => {
            console.log(error);
            dispatch(obtenerDepartamentosError());
        })

    }
}

export const obtenerDepartamentosInicio = () => ({
    type: OBTENER_DEPARTAMENTOS
});

export const obtenerDepartamentosExito = (departments) => ({
    type: OBTENER_DEPARTAMENTOS_EXITO,
    payload: departments
});

export const obtenerDepartamentosError = () => ({
    type: OBTENER_DEPARTAMENTOS_ERROR
})

export function obtenerSickDays (uid) {
    return (dispatch) => {
        dispatch(obtenerSickDaysInicio());

        axiosHr.get(`/sickDays/${uid}`)
        .then(respuesta => {
           // console.log(respuesta);
            dispatch(obtenerSickDaysExito(respuesta));
           //dispatch(respuesta)
        })
        .catch(error => {
            console.log(error);
            dispatch(obtenerSickDaysError());
        })
    }
}

export const obtenerSickDaysInicio = () => ({
    type: OBTENER_SICKDAYS
})

export const obtenerSickDaysExito = (sickDays) => ({
    type: OBTENER_SICKDAYS_EXITO,
    payload: sickDays
})

export const obtenerSickDaysError = () => ({
    type: OBTENER_SICKDAYS_ERROR
})

export function obtenerCompanies() {
    return (dispatch) => {
        dispatch(obtenerCompaniesInicio());

        axiosHr.get('/companies')
        .then(respuesta => {
            //console.log(respuesta.data.data);
            dispatch(obtenerCompaniesExito(respuesta.data.data));
        })
        .catch(error => {
            console.log(error);
            dispatch(obtenerCompaniesError());
        })

    }
}

export const obtenerCompaniesInicio = () => ({
    type: OBTENER_COMPANIES
});

export const obtenerCompaniesExito = (companies) => ({
    type: OBTENER_COMPANIES_EXITO,
    payload: companies
});

export const obtenerCompaniesError = () => ({
    type: OBTENER_COMPANIES_ERROR
})

export function obtenerTerritories() {
    return (dispatch) => {
        dispatch(obtenerTerritoriesInicio());

        axiosHr.get('/territories')
        .then(respuesta => {
            //console.log(respuesta.data.data);
            dispatch(obtenerTerritoriesExito(respuesta.data.data));
        })
        .catch(error => {
            console.log(error);
            dispatch(obtenerTerritoriesError());
        })

    }
}

export const obtenerTerritoriesInicio = () => ({
    type: OBTENER_TERRITORY
});

export const obtenerTerritoriesExito = (territories) => ({
    type: OBTENER_TERRITORY_EXITO,
    payload: territories
});

export const obtenerTerritoriesError = () => ({
    type: OBTENER_TERRITORY_ERROR
})

export function obtenerEmpleadosManager(data) {
    return (dispatch) => {
        dispatch(obtenerEmpleadosManagerInicio());

        console.log(data)
        axiosHr.post('/managerReport', data)
        .then(respuesta => {
            //console.log(respuesta.data.data);
            dispatch(obtenerEmpleadosManagerExito(respuesta.data.data));
        })
        .catch(error => {
            console.log(error);
            dispatch(obtenerEmpleadosManagerError());
        })

    }
}

export const obtenerEmpleadosManagerInicio = () => ({
    type: OBTENER_EMPLOYEES_MANAGER
});

export const obtenerEmpleadosManagerExito = (employees) => ({
    type: OBTENER_EMPLOYEES_MANAGER_EXITO,
    payload: employees
});

export const obtenerEmpleadosManagerError = () => ({
    type: OBTENER_EMPLOYEES_MANAGER_ERROR
})

export function obtenerHistorico(uid) {
    return (dispatch) => {
        dispatch(obtenerHistoricoInicio());

        axiosHr.get(`/history/${uid}`)
        .then(respuesta => {
            //console.log(respuesta.data.data);
            dispatch(obtenerHistoricoExito(respuesta.data.data));
        })
        .catch(error => {
            console.log(error);
            dispatch(obtenerHistoricoError());
        })

    }
}

export const obtenerHistoricoInicio = () => ({
    type: OBTENER_HISTORICO
});

export const obtenerHistoricoExito = (historic) => ({
    type: OBTENER_HISTORICO_EXITO,
    payload: historic
});

export const obtenerHistoricoError = () => ({
    type: OBTENER_HISTORICO_ERROR
})


export function editDepartmentAction(department) {
    return async (dispatch) => {
        dispatch( editDepartment());
        // Insertar en la API
        await axiosHr.post('/update-department', department)
        .then(respuesta => {

            console.log(department);
            //Si se actualiza correctamente
            dispatch(editDepartmentSuccess(department));

            //dispatch(getPTOListAction());
        })
        .catch(error => {
            // console.log(error);

            // Si hay un error
            dispatch(editDepartmentError());
        })
    
    }
}


export const editDepartment = () => ({
    type: COMENZAR_CREAR_DEPARTMENT
});

export const editDepartmentSuccess = (pto) => ({
    type: COMENZAR_CREAR_DEPARTMENT_EXITO,
    payload: pto
});

export const editDepartmentError = () => ({
    type: COMENZAR_CREAR_DEPARTMENT_ERROR
});

// new department
export function newDepartmentAction(department) {
    return async (dispatch) => {
        dispatch( newDepartment());
        // Insertar en la API
        await axiosHr.post('/new-department', department)
        .then(respuesta => {

            console.log(department);
            //Si se actualiza correctamente
            dispatch(newDepartmentSuccess(department));

            //dispatch(getPTOListAction());
        })
        .catch(error => {
            // console.log(error);

            // Si hay un error
            dispatch(newDepartmentError());
        })
    
    }
}


export const newDepartment = () => ({
    type: COMENZAR_EDITAR_DEPARTMENT
});

export const newDepartmentSuccess = (pto) => ({
    type: COMENZAR_EDITAR_DEPARTMENT_EXITO,
    payload: pto
});

export const newDepartmentError = () => ({
    type: COMENZAR_EDITAR_DEPARTMENT_ERROR
});

export function obtenerEstadosActivos(company) {
    return (dispatch) => {
        dispatch(obtenerEstadosActivosInicio());

        axiosHr.get('/active-states/'+company)
        .then(respuesta => {
            //console.log(respuesta.data.data);
            dispatch(obtenerEstadosActivosExito(respuesta.data.data));
        })
        .catch(error => {
            console.log(error);
            dispatch(obtenerEstadosActivosError());
        })

    }
}

export const obtenerEstadosActivosInicio = () => ({
    type: OBTENER_ESTADOS_ACTIVOS
});

export const obtenerEstadosActivosExito = (estados) => ({
    type: OBTENER_ESTADOS_ACTIVOS_EXITO,
    payload: estados
});

export const obtenerEstadosActivosError = () => ({
    type: OBTENER_ESTADOS_ACTIVOS_ERROR
})