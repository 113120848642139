import React, {useEffect, useState} from 'react';
//Redux
import {useSelector } from 'react-redux';

import Spinner from './helpers/Spinner';

import ReactExport from "react-export-excel";
//import moment from 'moment';
import moment from 'moment-timezone';
import axiosHR from '../config/axiosHR';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const UserReports = () => {

    const [ptos, setPTOS] = useState([]);

    //acceder al state
    const employees = useSelector(state=>state.manager.employees);
    const userEmail = useSelector(state=> state.userInfo.userInfo.email);

    let PtoEventsArray = [];

    let usaEmployees = [];
   

    

    useEffect(() => { 

        axiosHR.get('/pto-list-all/')
            .then(respuesta => {
             //   console.log(respuesta.data.data[0].day_beginning)
                let find = '';
                respuesta.data.data.map((pto, index) => {

                find = employees.find(employee => 
                    {
                        
                      if (`${employee.employee_name} ${employee.employee_lastname}` === pto.employee_name) {
                        pto.job_title = employee.job_title
                        return pto
                      }
                    }
                )

                console.log(find);
                

                return pto.status !== 'Denied' && pto.company !== 'CSI'
                ?
                    
                   PtoEventsArray.push(pto)


                :
                    null
                
                })
                setPTOS(PtoEventsArray);
                console.log(ptos, PtoEventsArray);
            })
            .catch(error => {
                console.log(error);
            })
    }, [])
    
   
    const filterElementsByCompany  = (array, query) => {
        return array.filter(function(el) {
            // console.log(name)
            return el.company.toLowerCase().indexOf(query.toLowerCase()) > -1;
        })
    }
    const filterbyMexilink = filterElementsByCompany(employees, 'Mexilink');

    const filterbyHitSales = filterElementsByCompany(employees, 'Hit Sales & Marketing');

    usaEmployees = [...filterbyMexilink, ...filterbyHitSales, ...usaEmployees];
    
    return (
        <React.Fragment>
          <div className="d-flex justify-content-center align-items-center manager-title">
                    <h2 className="text-center my-5 title-custom">Special Users Report</h2>
                    <div className='card-deck w-100'>
                        <div className="card ">
                            <div className="card-body ">
                                <h5 className="card-title mt-3 text-center">Users Reports</h5>
                                <hr className=''/>
                                <div className="card-text">
                                    <ul className='list-group mb-3'>
                                        {
                                            (userEmail == 'astrid.miranda@mexilink.com')
                                        ?
                                        <>
                                            <ExcelFile element={<button className="list-group-item list-group-item-action">MExilink Sick Days Report</button>}>
                                                    <ExcelSheet  data={filterbyMexilink} name="Employees">
                                                        <ExcelColumn label="LastName" value="employee_lastname"/>
                                                        <ExcelColumn label="FirstName" value="employee_name"/>
                                                        <ExcelColumn label="SocialSecurityNumber" value="0"/>
                                                        <ExcelColumn label="InsperityEmployeeId" value="0"/>
                                                        <ExcelColumn label="AccruedTimeOff" value="accrued_sick_days"/>
                                                        <ExcelColumn label="Taken" value={(col) => parseInt(col.accrued_sick_days) - parseInt(col.sick_days_limit)}/>
                                                        {/* <ExcelColumn label="Time Off Plan Name" value={(col) => col.travel_benefits === 0 ? 48 : 24}/> */}
                                                        {/* <ExcelColumn label="Accrued Time Off" value={(col) => col.travel_benefits === 0 ? 48 : 24}/>
                                                        <ExcelColumn label="Taken" value="sick_days_limit"/> */}
                                                        <ExcelColumn label="Balance" value="sick_days_limit"/>
                                                        <ExcelColumn label="Carry Over Sick Days" value="carry_over_sick_time"/>
                                                        <ExcelColumn label="InsperityClientId" value="0"/>
                                                        <ExcelColumn label="PayPeriodEndDate" value={(col) => moment().format('DD/MM/YYYY')}/>
                                                        <ExcelColumn label="Company" value="company"/>
                                                    </ExcelSheet>
                                        </ExcelFile>
                                        
                                        <ExcelFile element={<button className="list-group-item list-group-item-action">Hit Sales Sick Days Report</button>}>
                                                    <ExcelSheet  data={filterbyHitSales} name="Employees">
                                                        <ExcelColumn label="LastName" value="employee_lastname"/>
                                                        <ExcelColumn label="FirstName" value="employee_name"/>
                                                        <ExcelColumn label="SocialSecurityNumber" value="0"/>
                                                        <ExcelColumn label="InsperityEmployeeId" value="0"/>
                                                        <ExcelColumn label="AccruedTimeOff" value="accrued_sick_days"/>
                                                        <ExcelColumn label="Taken" value={(col) => parseInt(col.accrued_sick_days) - parseInt(col.sick_days_limit)}/>
                                                        {/* <ExcelColumn label="Time Off Plan Name" value={(col) => col.travel_benefits === 0 ? 48 : 24}/> */}
                                                        {/* <ExcelColumn label="Accrued Time Off" value={(col) => col.travel_benefits === 0 ? 48 : 24}/>
                                                        <ExcelColumn label="Taken" value="sick_days_limit"/> */}
                                                        <ExcelColumn label="Balance" value="sick_days_limit"/>
                                                        <ExcelColumn label="Carry Over Sick Days" value="carry_over_sick_time"/>
                                                        <ExcelColumn label="InsperityClientId" value="0"/>
                                                        <ExcelColumn label="PayPeriodEndDate" value={(col) => moment().format('DD/MM/YYYY')}/>
                                                        <ExcelColumn label="Company" value="company"/>
                                                    </ExcelSheet>
                                        </ExcelFile>
                                        </>
                                        :
                                        null
                                        }
                                        {
                                            (userEmail == 'jonathan.lareo@mexilink.com' || userEmail == 'julio.castro@mexilink.com')
                                        ?
                                        <ExcelFile name={`User-General-Report-`+moment().format('YYYY-MM-DD')} element={<button className="list-group-item list-group-item-action">General Info Only</button>}>
                                                    <ExcelSheet  data={employees} name="Employees">
                                                        <ExcelColumn label="Lastname" value="employee_lastname"/>
                                                        <ExcelColumn label="Firstname" value="employee_name"/>
                                                        <ExcelColumn label="Department" value="department"/>
                                                        <ExcelColumn label="Job Title" value="job_title"/>
                                                        <ExcelColumn label="Company" value="company"/>
                                                        <ExcelColumn label="Email" value="email"/>
                                                        <ExcelColumn label="Territory" value="territory"/>
                                                        <ExcelColumn label="Supervisor Email" value="supervisor_mail"/>
                                                        <ExcelColumn label="Workplace" value="work_place" />
                                                        <ExcelColumn label="Birthplace" value="birth_place"/>
                                                        <ExcelColumn label="Hire Date" value={(col) => moment.tz(col.hire_date, "America/Chicago").format('YYYY-MM-DD')}/>
                                                        <ExcelColumn label="Birthdate" value={(col) => moment.tz(col.birth_date, "America/Chicago").format('YYYY-MM-DD')}/>
                                                        <ExcelColumn label="Birth Year" value={(col) => moment.tz(col.birth_date, "America/Chicago").format('YYYY')}/>
                                                        <ExcelColumn label="Birth Month" value={(col) => moment.tz(col.birth_date, "America/Chicago").format('MM')}/>
                                                        <ExcelColumn label="Birthday" value={(col) => moment.tz(col.birth_date, "America/Chicago").format('DD')}/>
                                                       
                                                    </ExcelSheet>
                                        </ExcelFile>
                                        :
                                        null
                                        }
                                    </ul>
                                    

                                    
                                </div>
                            </div>
                            
                        </div>
                        {/* End Card */}

                        <div className="card ">
                            <div className="card-body ">
                                <h5 className="card-title mt-3 text-center">PTO Reports</h5>
                                <hr className=''/>
                                <div className="card-text">
                                    <ul className='list-group mb-3'>
                                        <ExcelFile element={<button className="list-group-item list-group-item-action">All PTO</button>}>
                                                    <ExcelSheet  data={ptos} name="PTOs">
                                                        <ExcelColumn label="Employee Name" value="employee_name"/>
                                                        <ExcelColumn label="Job Title" value="job_title"/>
                                                        <ExcelColumn label="Department" value="department"/>
                                                        <ExcelColumn label="Company" value="company"/>
                                                        <ExcelColumn label="Created At" value={(col) => moment(col.date).format('YYYY-MM-DD')}/>
                                                        <ExcelColumn label="Day Beginning" value={(col) => moment(col.day_beginning).format('YYYY-MM-DD')}/>
                                                        <ExcelColumn label="Day End" value={(col) => moment(col.day_end).format('YYYY-MM-DD')}/>
                                                        <ExcelColumn label="Time Beginning" value="time_beginning"/>
                                                        <ExcelColumn label="Time End" value="time_end"/>
                                                        <ExcelColumn label="Total Hours" value="total_hours"/>
                                                        <ExcelColumn label="Status" value="status"/>    
                                                    </ExcelSheet>
                                        </ExcelFile>

                                        
                                            
                                    </ul>
                                    

                                    
                                </div>
                            </div>
                            
                        </div>
                    </div>
            </div>
                        
        </React.Fragment>
    );
};

export default UserReports;