import {
    AGREGAR_TIME_OFF_REQUEST,
    AGREGAR_TIME_OFF_REQUEST_EXITO,
    AGREGAR_TIME_OFF_REQUEST_ERROR,
    COMENZAR_DESCARGA_PTOS,
    COMENZAR_DESCARGA_PTOS_EXITO,
    COMENZAR_DESCARGA_PTOS_ERROR,
    OBTENER_PTO_ELIMINAR,
    PTO_ELIMINADO_EXITO,
    PTO_ELIMINADO_ERROR,
    OBTENER_DETALLE_PTO,
    OBTENER_DETALLE_PTO_EXITO,
    OBTENER_DETALLE_PTO_ERROR,
    COMENZAR_DESCARGA_PTOS_DEPARTAMENTO,
    COMENZAR_DESCARGA_PTOS_DEPARTAMENTO_EXITO,
    COMENZAR_DESCARGA_PTOS_DEPARTAMENTO_ERROR,
    OBTENER_PTO_EDITAR,
    OBTENER_PTO_EDITAR_EXITO,
    OBTENER_PTO_EDITAR_ERROR,
    COMENZAR_APROBACION_PTO,
    COMENZAR_APROBACION_PTO_EXITO,
    COMENZAR_APROBACION_PTO_ERROR,
    COMENZAR_DENEGAR_PTO,
    COMENZAR_DENEGAR_PTO_EXITO,
    COMENZAR_DENEGAR_PTO_ERROR,
    COMENZAR_DESCARGA_PTOS_SUPERVISOR,
    COMENZAR_DESCARGA_PTOS_SUPERVISOR_EXITO,
    COMENZAR_DESCARGA_PTOS_SUPERVISOR_ERROR,
    COMENZAR_EDITAR_PTO,
    COMENZAR_EDITAR_PTO_EXITO,
    COMENZAR_EDITAR_PTO_ERROR,
    COMENZAR_DESCARGA_PTOS_SUPERVISOR_DIRECTOR,
    COMENZAR_DESCARGA_PTOS_SUPERVISOR_DIRECTOR_EXITO,
    COMENZAR_DESCARGA_PTOS_SUPERVISOR_DIRECTOR_ERROR,
    AJUSTAR_CARRY_OVER,
    AJUSTAR_CARRY_OVER_EXITO,
    AJUSTAR_CARRY_OVER_ERROR
} from '../types';

const intialState = {
    ptoRequests: [],
    ptoSupervisor: [],
    error:false,
    loading: false,
    pto: {}
}


export default function(state=intialState, action) {
    switch(action.type) {
        case AGREGAR_TIME_OFF_REQUEST:
            return {
                ...state,
                error: false
            }
        case AGREGAR_TIME_OFF_REQUEST_EXITO:
            return {
                ...state,
                ptoRequests: [...state.ptoRequests, action.payload],
                error: false
            }
        case AGREGAR_TIME_OFF_REQUEST_ERROR:
            return {
                ...state,
                error: true
            }
        case COMENZAR_DESCARGA_PTOS:
            return {
                ...state,
                error:false,
                loading: true,
                pto: {}
            }
        case COMENZAR_DESCARGA_PTOS_EXITO:
            return {
                ...state,
                error:false,
                ptoRequests: action.payload,
                loading:false,
                pto: {}
            }
        case COMENZAR_DESCARGA_PTOS_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            }
        case OBTENER_PTO_ELIMINAR:
            return {
                ...state,
                error:false
            }
        case PTO_ELIMINADO_EXITO:
            return {
                ...state,
                error: false,
                ptoRequests: state.ptoRequests.filter(pto => pto.id !== action.payload)
            }
        case PTO_ELIMINADO_ERROR:
            return {
                ...state,
                error: true
            }
        case OBTENER_DETALLE_PTO:
            return {
                ...state,
                error: false,
                pto: {}
            }
        case OBTENER_DETALLE_PTO_EXITO:
            return {
                ...state,
                error: false,
                pto: action.payload
            }
        case OBTENER_DETALLE_PTO_ERROR:
            return {
                ...state,
                error: true
            }
        case COMENZAR_DESCARGA_PTOS_DEPARTAMENTO:
            return {
                ...state,
                error:false,
                loading: true,
                pto: {}
            }
        case COMENZAR_DESCARGA_PTOS_DEPARTAMENTO_EXITO:
            return {
                ...state,
                error:false,
                ptoSupervisor: action.payload,
                loading:false,
                pto:{}
            }
        case COMENZAR_DESCARGA_PTOS_DEPARTAMENTO_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            }
        case OBTENER_PTO_EDITAR:
            return {
                ...state,
                error:false
            }
        case OBTENER_PTO_EDITAR_EXITO:
            return {
                ...state,
                pto: action.payload,
                error: false
            }
        case OBTENER_PTO_EDITAR_ERROR:
            return {
                ...state,
                error: true
            }
        case COMENZAR_APROBACION_PTO:
            return {
                ...state,
                error: false,
                pto:{}
            }
        case COMENZAR_APROBACION_PTO_EXITO:
            return {
                ...state,
                error: false,
                ptoSupervisor: state.ptoSupervisor.map(pto => pto.id === action.payload.id ? pto = action.payload : pto),
                pto:{}
            }
        case COMENZAR_APROBACION_PTO_ERROR:
            return {
                ...state,
                error: true
            }
            case COMENZAR_DENEGAR_PTO:
            return {
                ...state,
                error: false,
                pto:{}
            }
        case COMENZAR_DENEGAR_PTO_EXITO:
            return {
                ...state,
                error: false,
                ptoSupervisor: state.ptoSupervisor.map(pto => pto.id === action.payload.id ? pto = action.payload : pto),
                pto:{}
            }
        case COMENZAR_DENEGAR_PTO_ERROR:
            return {
                ...state,
                error: true
            }
        case COMENZAR_DESCARGA_PTOS_SUPERVISOR:
            return {
                ...state,
                error:false,
                loading: true,
                pto: {}
            }
        case COMENZAR_DESCARGA_PTOS_SUPERVISOR_EXITO:
            return {
                ...state,
                error:false,
                ptoSupervisor: action.payload,
                loading:false,
                pto:{},
                countPto: action.payload.length 
            }
        case COMENZAR_DESCARGA_PTOS_SUPERVISOR_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            }
            case COMENZAR_EDITAR_PTO:
            return {
                ...state,
                error: false,
                pto:{}
            }
        case COMENZAR_EDITAR_PTO_EXITO:
            return {
                ...state,
                error: false,
                ptoRequests: state.ptoRequests.map(pto => pto.id === action.payload.id ? pto = action.payload : pto),
                pto:{}
            }
        case COMENZAR_EDITAR_PTO_ERROR:
            return {
                ...state,
                error: true
            }

            case COMENZAR_DESCARGA_PTOS_SUPERVISOR_DIRECTOR:
            return {
                ...state,
                error:false,
                loading: true,
                pto: {}
            }
        case COMENZAR_DESCARGA_PTOS_SUPERVISOR_DIRECTOR_EXITO:
            return {
                ...state,
                error:false,
                ptoSupervisor: action.payload,
                loading:false,
                pto:{}
            }
        case COMENZAR_DESCARGA_PTOS_SUPERVISOR_DIRECTOR_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            }
        case AJUSTAR_CARRY_OVER:
            return {
                ...state,
                error: false,
                loading: true
            }
        case AJUSTAR_CARRY_OVER_EXITO:
            return {
                ...state,
                error: false,
                loading: false
            }
        case AJUSTAR_CARRY_OVER_ERROR:
            return {
                error: true,
                loading: true
                }
        default:
            return state;
    }
}