import React from 'react';
import {Link} from 'react-router-dom';

const ListDepartments = ({department}) => {
    let directorOf = JSON.parse(department.director_of);

    if (directorOf.length === 0) {
        directorOf[0] = {"id" : Math.floor(Math.random() * 100) + 200, "department" : "Not assigned"}
    }
    
    return (
        <tr>
            <td>{department.department}</td>
            <td>{department.email_director}</td>
            <td>{department.report_to}</td>
            <td><ul>
                {directorOf.length > 1 
                ? 
                directorOf.map(name => <li key={name.id}>{name.department}</li>) 
                :
                    <span key={directorOf[0].id}>{directorOf[0].department}
                    </span>
                }
                </ul></td>
            <td><div className="color-indicator" style={{backgroundColor: department.color}}></div></td>
            <td>{department.active === 1 ? "Active" : "Inactive"}</td>
            <td><Link
                    to={`/departments/edit/${department.id}`}
                    className="btn btn-warning mt-1 btn-sm"
                >
                    Edit Department
                </Link></td>
        </tr>
    );
};

export default ListDepartments;